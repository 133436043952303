import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    width: "533px",
    color: "#000000",
    borderRadius: "15px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    fontFamily: "Avenir Black, sans-serif",

    "&:focus": {
      outline: "none",
    },
  },
  paper: {
    padding: theme.spacing(0, 4, 0),
  },
  top: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0px -32px",
    padding: "0 20px",
    height: 68,
    background: "#ABADAD",
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
    marginBottom: 34,
  },

  content: {
    marginTop: "20px",
    fontSize: 15,
  },
  print: {
    fontSize: "30px",
    lineHeight: "26px",
    textTransform: "capitalize",
    fontWeight: 900,
    color: "#000000",
  },
  printmode: {
    display: "flex",
    alignItems: "center",

    "& > :first-child": {
      marginRight: "50px",
    },
  },
  icon: {
    color: "#fff",
    cursor: "pointer",
    fontSize: "30px",
    fontWeight: "bold",
    transition: "all .3s ease",
  },
  checkboxIcon: {
    padding: 0,
    fontSize: "30px",
  },
  checkbox: {
    "&.MuiCheckbox-colorPrimary.Mui-checked": {
      color: "#000 !important",
    },
    "&:hover": {
      background: "rgba(0,0,0,0.3) !important",
    },
  },
  line: {
    opacity: " 0.3",
    height: "1px",
    background: "#979797",
    marginTop: "20px",
  },

  bottom: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "74px",

    "& > div ": {
      display: "flex",
      justifyContent: "space-between",
      width: "95%",
    },
    "& > div > :last-child": {
      marginRight: "32px",
      marginLeft: "16px",
    },
  },

  printMode: {
    color: "#1D4E89 !important",
  },
}));
