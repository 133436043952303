import axios from "axios";
import { navigate } from "@reach/router";

export type InstagramSearchAgentArgs = {
  sessionCookie: string;
  campaignName: string;
  hashtags: string[];
  client: string;
  postsToExtract: string;
};

export const launchInstagramAgent = async ({
  sessionCookie,
  campaignName,
  hashtags,
  client,
  postsToExtract,
}: InstagramSearchAgentArgs) => {
  const response = await axios.post(
    `${process.env.REACT_APP_FUNC_BASE_URL}/instagram-createCampaign`,
    {
      sessionCookie,
      campaignName,
      hashtags,
      client,
      postsToExtract,
    }
  );
  navigate(`/campaigns/organic/instagram/${response.data.id}`);
  return response.data;
};
