import React, { FC } from "react";
import { Box, useMediaQuery } from "@material-ui/core";
import { navigate } from "@reach/router";

import { ReactComponent as OrganicCampaignsIllustration } from "statics/media/OrganicCampaignsIllustration.svg";
import { SendButton } from "components/styled/Common";

const EmptyCampaigns: FC<{}> = () => {
  const sm = useMediaQuery("(max-width:500px)");
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="start"
      height="100%"
      p={sm ? "40px 0 0 0" : "40px 0 20px 0"}
    >
      <Box
        display="flex"
        fontSize="30px"
        lineHeight="25px"
        fontStyle="normal"
        alignItems="center"
        justifyContent="space-between"
        fontFamily="'Avenir Black', sans-serif"
        p="40px 0 20px 0"
        height="auto"
        id="page-title"
      >
        <Box marginTop="32px" fontWeight="900">
          Organic Campaigns
        </Box>
      </Box>
      <Box
        border="2px #C4C4C4 dashed"
        borderRadius={10}
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        bgcolor="#F6F6F6"
        mt={sm ? "0px" : "30px"}
        height={sm ? "75vh" : "65vh"}
        width="100%"
      >
        <OrganicCampaignsIllustration
          style={{
            transform: sm ? "scale(0.4)" : "scale(0.5)",
            marginTop: sm ? "-300px" : "-150px",
          }}
        />
        <Box
          position="absolute"
          mt={sm ? "150px" : "180px"}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width={sm ? "300px" : "400px"}
          textAlign="center"
        >
          <h1
            style={{
              fontFamily: "'Avenir Black', sans-serif",
              fontSize: sm ? "20px" : "22px",
              fontWeight: 900,
              width: sm ? "340px" : "379px",
              color: "#000000",
            }}
          >
            Create your first campaign
          </h1>
          <p
            style={{
              fontSize: sm ? "16px" : "18px",
              fontFamily: "'Avenir Book', sans-serif",
              fontWeight: 400,
              width: sm ? "240px" : "470px",
              color: "#000000",
            }}
          >
            You are just one step away from a breakout success. Click the button
            below to create your marketing success!
          </p>
          <SendButton
            style={{
              width: "245px",
              height: "45px",
              marginTop: sm ? "50px" : "10px",
            }}
            onClick={() => navigate("/campaigns/organic/create")}
          >
            Create new campaign
          </SendButton>
        </Box>
      </Box>
    </Box>
  );
};

export default EmptyCampaigns;
