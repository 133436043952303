/**
 * @prettier
 */

import React, { FunctionComponent, useState } from "react";
import { CircularProgress, Button, Checkbox } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import firebase from "firebase/app";

import Email from "components/Common/Form/Email";
import Password from "components/Common/Form/Password";
import { auth, firestore } from "firebase-tools/firebase";
import { UserService } from "services/UserService";
import { AppConfig } from "AppConfig";
import { IUser } from "interfaces/IUser";
import { useLocation } from "@reach/router";
import { useStyles } from "./styles";
import { getAgent } from "utils/authHelpers";

const base64 = require("js-base64").Base64;

export const SignUp: FunctionComponent = (props) => {
  const location = useLocation();
  const urlQuery: Array<any> = location.search.split("&");
  const emailQuery: any = urlQuery[0].split("=")[1];
  let userQueryEmail: any = urlQuery[1]?.slice(2);
  userQueryEmail = base64.decode(userQueryEmail || "")?.toLowerCase();
  const classes = useStyles({});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const signup = async (
    email: string,
    password: string,
    agreesToPolicy: boolean
  ) => {
    setError("");
    setLoading(true);
    if (userQueryEmail) {
      localStorage.removeItem("clients");
      localStorage.removeItem("kit-authUser");

      const User = new UserService();
      await User.getUserByField("email", userQueryEmail).then(
        async (users: any) => {
          let userEmails =
            users && users.length === 1 && users[0].secondaryEmails;
          const isInvited =
            userEmails &&
            userEmails.some((el: any) => el.email === email && el.isInvited);

          const toUpdate = userEmails.filter(
            (user) => user.email === emailQuery
          )[0];

          if (isInvited) {
            auth
              .createUserWithEmailAndPassword(email, password)
              .then(async (createdUser: any) => {
                localStorage.setItem(
                  "account-chosen",
                  JSON.stringify("chosen")
                );

                // Update isSignedUp for an agent in users collection
                const UserToUpdate = new UserService(users[0].id);

                await UserToUpdate.updateUser({
                  secondaryEmails: firebase.firestore.FieldValue.arrayRemove(
                    toUpdate
                  ),
                });

                toUpdate.isSignedUp = true;
                await new Promise((resolve) => setTimeout(resolve, 1000));

                await UserToUpdate.updateUser({
                  secondaryEmails: firebase.firestore.FieldValue.arrayUnion(
                    toUpdate
                  ),
                });
                await new Promise((resolve) => setTimeout(resolve, 1000));

                // Update isSignedUp for an agent in client-agents collection
                const agent = await getAgent(email);

                const agentRef = await firestore
                  .collection("client-agents")
                  .doc(agent.id);
                await agentRef.update({
                  isSignedUp: true,
                });
              })
              .catch((e) => {
                setError(e.message);
              });
          } else {
            setError("You have not been invited by the admins!");
          }
        },
        () => {
          setError("An error occurred!");
        }
      );
    } else {
      const User = new UserService();
      await User.getUserByField("email", email).then(
        async (users: Array<IUser>) => {
          localStorage.removeItem("clients");
          localStorage.removeItem("kit-authUser");

          if (users && users.length === 1) {
            if (!users[0].isSignedUp && users[0].role === AppConfig.role) {
              User.doc = users[0].id;
              delete users[0].id;
              const isDeleted = await User.deleteUser().catch(setError);
              if (isDeleted) {
                auth
                  .createUserWithEmailAndPassword(email, password)
                  .then(async (createdUser: any) => {
                    User.doc = createdUser.user.uid;
                    users[0].isSignedUp = true;
                    users[0].agreesToPolicy = agreesToPolicy;
                    users[0].policyAcceptedAt = new Date();
                    await User.addUser(users[0] as IUser).catch(setError);
                  })
                  .catch((e) => {
                    setError(e.message);
                  });
              }
            } else {
              setError("Account already exists!");
            }
          } else {
            setError("You have not been invited by the admins!");
          }
        },
        () => {
          setError("An error occurred!");
        }
      );
    }
    setLoading(false);
  };

  return (
    <Formik
      initialValues={{
        email: emailQuery?.toLowerCase(),
        password: "",
        confirmPassword: "",
        agreesToPolicy: false,
      }}
      onSubmit={(values) => {
        signup(values.email, values.password, values.agreesToPolicy);
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email("Invalid email!").required("Required!"),
        password: Yup.string().min(6, "Too Short!").required("Required!"),
        confirmPassword: Yup.string()
          .oneOf([Yup.ref("password")], "Passwords must match!")
          .required("Required!"),
        agreesToPolicy: Yup.bool().oneOf(
          [true],
          "Please accept the Privacy Policy."
        ),
      })}
    >
      {(props) => {
        const { values, touched, errors, handleChange, handleSubmit } = props;
        return (
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <Email
              onChangeHandler={(e) => {
                handleChange(e);
                setError("");
              }}
              value={values.email}
              helperText={errors.email && touched.email && errors.email}
              error={errors.email && touched.email}
              color={errors.email ? "#f44336 !important" : "#fff !important"}
              backgroundColor="#BFBFBF"
              disabled={true}
              marginTop="24px"
            />
            <Password
              autoFocus
              onChangeHandler={(e) => {
                handleChange(e);
                setError("");
              }}
              value={values.password}
              helperText={
                errors.password && touched.password && errors.password
              }
              error={errors.password && touched.password}
              color={errors.password ? "#f44336 !important" : "#fff !important"}
              marginTop="24px"
            />
            <Password
              name="confirmPassword"
              label="Confirm Password"
              onChangeHandler={(e) => {
                handleChange(e);
                setError("");
              }}
              value={values.confirmPassword}
              helperText={
                errors.confirmPassword &&
                touched.confirmPassword &&
                errors.confirmPassword
              }
              error={errors.confirmPassword && touched.confirmPassword}
              color={errors.password ? "#f44336 !important" : "#fff !important"}
              marginTop="30px"
            />
            <div className={classes.checkboxContainer}>
              <Checkbox
                checked={values.agreesToPolicy}
                onChange={handleChange}
                name="agreesToPolicy"
                color="primary"
                className={classes.checkbox}
              />

              <label
                style={{
                  fontFamily: "Avenir Book, sans-serif",
                  display: "block",
                  marginTop: "10px",
                }}
              >
                {" "}
                I agree to the Be-Hookd{" "}
                <a
                  href={`${process.env.REACT_APP_WEBSITE_URL}privacy-policy`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: "white",
                  }}
                >
                  Privacy Policy and Terms & Conditions
                </a>
              </label>
            </div>
            <span className={classes.error} style={{ marginTop: "-4px" }}>
              {touched.agreesToPolicy && errors.agreesToPolicy}
            </span>
            <span className={classes.error}>{error}</span>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              classes={{ disabled: classes.disabledButton }}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={30} color="inherit" />
              ) : (
                "Sign Up"
              )}
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};
