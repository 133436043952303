import React, { FC } from "react";
import { navigate, RouteComponentProps } from "@reach/router";

import { useUser } from "UserContext";

const PrivateRoute: FC<RouteComponentProps> = ({ children }) => {
  const [user]: any = useUser();
  if (!Object.keys(user)?.length) {
    navigate("/sign-in");
    return null;
  } else {
    return <>{children}</>;
  }
};

export default PrivateRoute;
