import React, { FC } from "react";

import { firestore } from "firebase-tools/firebase";
import useDocumentSubscriber from "hooks/useDocumentSubscriber";
import AutoLikerPage from "components/BeAmplified/Campaigns/Organic/View/AutoLiker";
import { SocialMediaPlatform } from "../SelectCampaignPage";

type CampaignType = {
  platform?: string;
  uid?: string;
  path: string;
};

const AutoLikerCampaignPage: FC<CampaignType> = ({ platform, uid }) => {
  let campaignsRef: any;
  if (SocialMediaPlatform.Facebook.toUpperCase() === platform?.toUpperCase()) {
    campaignsRef = firestore.collection("facebook-auto-liker").doc(uid);
  }
  if (SocialMediaPlatform.Twitter.toUpperCase() === platform?.toUpperCase()) {
    campaignsRef = firestore.collection("twitter-auto-liker").doc(uid);
  }
  if (SocialMediaPlatform.Instagram.toUpperCase() === platform?.toUpperCase()) {
    campaignsRef = firestore.collection("instagram-auto-liker").doc(uid);
  }
  const campaign = useDocumentSubscriber({ ref: campaignsRef }).document;

  const socialMediaPlatform = platform
    ? (platform as SocialMediaPlatform)
    : SocialMediaPlatform.Twitter;

  return (
    <div>
      {campaign?.status && (
        <AutoLikerPage platform={socialMediaPlatform} campaign={campaign} />
      )}
    </div>
  );
};

export default AutoLikerCampaignPage;
