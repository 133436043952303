import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  datePickerContainer: {
    // width: "fit-content",
    // display: "flex",
    // flexDirection: "column",
    // alignItems: "center",
    // zIndex: 1000,
    // position: "relative",
  },
  dateLabel: {
    fontSize: "15px",
    lineHeight: "22px",
    fontFamily: "'Avenir Black', sans-serif",
    fontWeight: 900,
    color: "#000000",
    display: "flex",
    justifyContent: "center",
  },
  dateInput: {
    fontSize: "15px",
    fontFamily: "'Avenir Book', sans-serif",
    fontWeight: "normal",
    lineHeight: "20px",
    border: "1px solid #E8E8E8",
    borderRadius: "15px",
    padding: "11.5px 20.5px",
    marginTop: "8.5px",
    cursor: "pointer",
    background: "#FFF",
    display: "flex",
    alignItems: "center",
  },
  dateRangePicker: {
    right: "-22%",
    position: "absolute",
    overflow: "auto",
    boxShadow: "0px 5px 45px rgba(0, 0, 0, 0.07)",
    borderRadius: "5px",
    zIndex: 20,
  },

  datePicker: {
    boxShadow: "0px 5px 45px rgba(0, 0, 0, 0.07)",
    borderRadius: "5px",
    backgroundColor: "#fff",
    position: "absolute",
    right: "0%",
    zIndex: 1000,
    marginTop: 2,
    overflow: "auto",

    "& > :first-child": {
      minWidth: 110,
      width: "unset",
    },

    "& > :first-child > .rdrInputRanges": {
      display: "none",
    },

    "& > :last-child > .rdrDateDisplayWrapper": {
      display: "none",
    },

    "@media screen and (max-width: 1000px)": {
      minHeight: 550,
    },

    "@media screen and (max-width: 580px)": {
      width: 340,
      overflow: "auto",
      display: "flex",
      flexDirection: "row-reverse",
    },
  },

  datePicker2: {
    boxShadow: "0px 5px 45px rgba(0, 0, 0, 0.07)",
    borderRadius: "5px",
    backgroundColor: "#fff",
    position: "absolute",
    // right: "-10px",
    zIndex: 1000,
    marginTop: 2,
    overflow: "auto",

    "& > :first-child": {
      minWidth: 110,
      width: "unset",
    },

    "& > :first-child > .rdrInputRanges": {
      display: "none",
    },

    "& > :last-child > .rdrDateDisplayWrapper": {
      display: "none",
    },

    // "@media screen and (min-width: 1000px)": {
    //   right: "-100%",
    // },

    "@media screen and (max-width: 580px)": {
      width: 340,
      overflow: "auto",
      display: "flex",
      padding: "10px",
      left: "10px",
      // flexDirection: "row-reverse",
      justifyContent: "end",
    },
  },
});
