import { navigate } from "@reach/router";
import { FaceBookAutoMessageCampaign } from "./launchAutoMessager";
import axios from "axios";

export const editAutoMessageCampaign = async (
  id: string,
  data: Partial<FaceBookAutoMessageCampaign>
) => {
  const response = await axios.put(
    `${process.env.REACT_APP_FUNC_BASE_URL}facebook-editAutoMessageCampaign`,
    { id, data }
  );
  navigate(`/campaigns/organic/facebook/auto-dm/${id}`);
  return response.data;
};
