import React from "react";
import InstagramMediaVideo from "./Media/InstagramMediaVideo";
import InstagramMediaImage from "./Media/InstagramMediaImage.tsx";

const ToggleIgPost = ({
  media_type,
  posts,
  role,
  fbMetadata,
  igMetadata,
  avatar,
}: {
  media_type: string;
  posts: any[];
  role: string;
  fbMetadata: any;
  igMetadata: any;
  avatar: any;
}) => {
  return (
    <>
      {" "}
      {media_type === "VIDEO" && (
        <InstagramMediaVideo
          posts={posts}
          role={role}
          igMetadata={igMetadata}
          fbMetadata={fbMetadata}
          avatar={avatar}
        />
      )}
      {media_type === "IMAGE" && (
        <InstagramMediaImage
          posts={posts}
          role={role}
          igMetadata={igMetadata}
          fbMetadata={fbMetadata}
          avatar={avatar}
        />
      )}
    </>
  );
};

export default ToggleIgPost;
