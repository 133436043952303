import React, { useState, FC } from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { navigate } from "@reach/router";
import { CSVLink } from "react-csv";

import { useStyles } from "./styles";
import { ReactComponent as MoreOptionsIcon } from "statics/media/moreOptionsIcon.svg";
import { ReactComponent as ViewIcon } from "statics/media/viewIconBlack.svg";
import { ReactComponent as EditIcon } from "statics/media/editIcon.svg";
import { ReactComponent as PauseIcon } from "statics/media/pauseIconBlack.svg";
import { ReactComponent as ArchiveIcon } from "statics/media/saveIcon.svg";
import { ReactComponent as ExportIcon } from "statics/media/downloadIcon.svg";
import { firestore } from "firebase-tools/firebase";
import { customHeaders } from "../../../constants";
import { SocialMediaPlatform } from "containers/BeAmplified/Campaigns/Organic/SelectCampaignPage";
import { csvHeaders } from "../../View/GroupExtractor";

const MoreOptions: FC<{
  campaign: any;
  platform: SocialMediaPlatform;
}> = ({ campaign, platform }) => {
  const classes = useStyles({});
  const [open, setOpen] = useState(false);

  const typesMap = new Map();
  typesMap.set(`${platform}autofollow`, "twitter-campaigns");
  typesMap.set(`${platform}autoFollow`, "instagram-autofollow-campaigns");
  typesMap.set(`${platform}autoPoster`, "twitter-auto-poster");
  typesMap.set(`${platform}auto-dm`, "twitter-auto-dm");
  typesMap.set(`${platform}autoLiker`, "twitter-auto-liker");
  typesMap.set(`${platform}fbAutoLiker`, "facebook-auto-liker");
  typesMap.set(`${platform}igAutoLiker`, "instagram-auto-liker");
  typesMap.set(`${platform}auto-dm`, "facebook-auto-dm");
  typesMap.set(`${platform}groupScrapper`, "facebook-group-scrapper");
  typesMap.set(`${platform}igAutoCommenter`, "instagram-autocommenter");

  const toggleStatus = (status: string): string => {
    let newStatus = "";
    switch (status) {
      case "running":
        newStatus = "paused";
        break;
      case "paused":
        newStatus = "running";
        break;
      default:
        newStatus = "paused";
    }
    return newStatus;
  };

  const togglePause = async (type: string) => {
    const history = campaign?.history || [];
    history.push({
      status: campaign?.status,
      date: new Date(),
    });
    await firestore
      .collection(typesMap.get(`${platform}${type}`))
      .doc(campaign.id)
      .update({
        status: toggleStatus(campaign?.status),
        history,
      });
  };

  const archiveCampaign = async (type: string) => {
    const collectionName = typesMap.get(`${platform}${type}`);
    await firestore.collection(collectionName).doc(campaign.id).update({
      archived: true,
    });
  };
  return (
    <div style={{ position: "relative" }}>
      <MoreOptionsIcon
        style={{ cursor: "pointer" }}
        onClick={() => setOpen(!open)}
      />
      {open ? (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <div className={classes.container}>
            <div
              onClick={() => {
                if (campaign?.campaignType === "autoPoster") {
                  navigate(`/campaigns/organic/autoposter/${campaign?.id}`);
                  return;
                }
                if (campaign?.campaignType === "autoFollow") {
                  navigate(`/campaigns/organic/${platform}/${campaign?.id}`);
                  return;
                }
                if (campaign.campaignType === "auto-dm") {
                  if (
                    platform === SocialMediaPlatform.Facebook ||
                    platform === SocialMediaPlatform.Twitter
                  ) {
                    navigate(
                      `/campaigns/organic/${platform}/auto-dm/${campaign.id}`
                    );
                    return;
                  }
                  navigate(`/campaigns/organic/auto-dm/${campaign.id}`);
                  return;
                }
                if (campaign?.campaignType === "autoLiker") {
                  navigate(
                    `/campaigns/organic/auto-liker/${SocialMediaPlatform.Twitter.toLowerCase()}/${
                      campaign?.id
                    }`
                  );
                  return;
                }
                if (campaign?.campaignType === "fbAutoLiker") {
                  navigate(
                    `/campaigns/organic/auto-liker/${SocialMediaPlatform.Facebook.toLowerCase()}/${
                      campaign?.id
                    }`
                  );
                  return;
                } else if (campaign?.campaignType === "igAutoLiker") {
                  navigate(
                    `/campaigns/organic/auto-liker/${SocialMediaPlatform.Instagram.toLowerCase()}/${
                      campaign?.id
                    }`
                  );
                  return;
                } else if (campaign?.campaignType === "igAutoCommenter") {
                  navigate(
                    `/campaigns/organic/auto-commenter/${SocialMediaPlatform.Instagram.toLowerCase()}/${
                      campaign?.id
                    }`
                  );
                  return;
                } else if (campaign?.campaignType === "groupScrapper") {
                  navigate(
                    `/campaigns/organic/group-extractor/${SocialMediaPlatform.Facebook.toLowerCase()}/${
                      campaign.id
                    }`
                  );
                  return;
                }
                navigate(`/campaigns/organic/${campaign?.id}`);
              }}
              className={classes.item}
            >
              View
              <ViewIcon />
            </div>
            {["autofollow", "auto-dm", "autoFollow"].includes(
              campaign?.campaignType
            ) && (
              <div
                onClick={() =>
                  navigate(
                    `/campaigns/organic/${
                      campaign.campaignType === "auto-dm"
                        ? `create/${platform}/auto-dm/`
                        : `${platform}/`
                    }${campaign.id}/edit`
                  )
                }
                className={classes.item}
              >
                Edit
                <EditIcon />
              </div>
            )}
            {!["groupScrapper"].includes(campaign?.campaignType) && (
              <div
                onClick={() => togglePause(campaign?.campaignType)}
                className={classes.item}
              >
                {campaign?.status === "paused" ? "Resume" : "Pause"}
                <PauseIcon />
              </div>
            )}
            <div
              onClick={() => archiveCampaign(campaign?.campaignType)}
              className={classes.item}
            >
              Archive
              <ArchiveIcon />
            </div>
            {campaign?.campaignType !== "fbAutoLiker" &&
              campaign?.resultObject &&
              campaign?.resultObject !== "[]" && (
                <CSVLink
                  data={
                    campaign?.resultObject?.length
                      ? campaign?.resultObject || []
                      : JSON.parse(campaign?.resultObject || "[]")
                  }
                  headers={
                    campaign?.campaignType === "groupScrapper"
                      ? csvHeaders
                      : customHeaders
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  filename="results.csv"
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <div className={classes.item}>
                    Export
                    <ExportIcon />
                  </div>
                </CSVLink>
              )}
          </div>
        </ClickAwayListener>
      ) : null}
    </div>
  );
};

export default MoreOptions;
