import React, { FC, useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { navigate } from "@reach/router";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  Box,
  Switch,
  LinearProgress,
  FormControlLabel,
} from "@material-ui/core";
import { firestore } from "firebase-tools/firebase";
import { ReactComponent as DoubleArrow } from "statics/media/doublePlayArrow.svg";
import { useStyles } from "./styles";
import { ReactComponent as RunningIcon } from "statics/media/runningIcon.svg";
import { ReactComponent as RefreshIcon } from "statics/media/refreshIcon.svg";
import { ReactComponent as CircledCheck } from "statics/media/circledCheck.svg";
import PageTitle from "components/Common/PageTitle";
import getLogs from "utils/getLogs";
import { SocialMediaPlatform } from "containers/BeAmplified/Campaigns/Organic/SelectCampaignPage";
import { getPlatformImage } from "../../Components/SelectCampaignBox";
import { SendButton } from "components/styled/Common";

interface ICampaign {
  campaign: any;
  platform: SocialMediaPlatform;
}

const BorderLinearProgress = withStyles({
  root: {
    height: 6,
    borderRadius: 7,
    "&:hover": {
      opacity: 0.5,
    },
  },
  colorPrimary: {
    background: "#F2F2F2",
  },
  bar: {
    borderRadius: 7,
    background: "#598CE1",
    animationDuration: "8s",
  },
})(LinearProgress);

const History = (props) => {
  const { type = "info", message, logs } = props;

  if (logs) {
    return (
      <>
        {message.split("\n").map((msg, key) => (
          <div
            key={key}
            style={{
              marginLeft: 17,
              fontSize: 15,
              lineHeight: "22px",
              color: "#FFFFFF",
            }}
          >
            {msg}
          </div>
        ))}
      </>
    );
  }

  return (
    <div>
      {type === "success" ? (
        <CircledCheck />
      ) : (
        <RefreshIcon style={{ width: 15 }} />
      )}{" "}
      <span
        style={{
          marginLeft: 17,
          fontSize: 15,
          lineHeight: "22px",

          color: "#FFFFFF",
        }}
      >
        {message}
      </span>
    </div>
  );
};

const AutoLikerPage: FC<ICampaign> = ({ platform, campaign }) => {
  const classes = useStyles({});
  const [showConsole, setShowConsole] = useState(true);
  const [logs, setLogs] = useState("");

  const toggle = async () => {
    return setShowConsole(!showConsole);
  };

  useEffect(() => {
    (async () => {
      let logs = await getLogs(campaign.containerId);

      if (!logs.output) {
        await new Promise((resolve) => setTimeout(resolve, 10000));
        logs = await getLogs(campaign.containerId);
      }
      setLogs(logs.output);
    })();

    // eslint-disable-next-line
  }, [campaign.containerId]);

  const typesMap = new Map();
  typesMap.set("autoLiker", "twitter-auto-liker");
  typesMap.set("fbAutoLiker", "facebook-auto-liker");
  typesMap.set("igAutoLiker", "instagram-auto-liker");
  typesMap.set("igAutoCommenter", "instagram-autocommenter");

  const toggleCampaignStatus = async (type: string) => {
    await firestore
      .collection(typesMap.get(type))
      .doc(campaign.id)
      .update({
        status: campaign.status === "paused" ? "running" : "paused",
      });
  };

  const getCampaignType = () => {
    switch (campaign.campaignType) {
      case "fbAutoLiker" || "autoLiker" || "igAutoLiker":
        return "AutoLiker";
      case "igAutoCommenter":
        return "AutoCommenter";
      default:
        break;
    }
  };
  return (
    <div className="root">
      <div style={{ marginTop: 40 }}>
        <button
          onClick={() => navigate("/campaigns/organic")}
          style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            fontSize: "18px",
            fontWeight: 600,
            color: "#000000",
            fontFamily: "'Avenir Book', sans-serif",
            border: "none",
            cursor: "pointer",
          }}
        >
          <ArrowBackIcon />
          Back to all campaigns
        </button>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          height="150px"
        >
          <PageTitle
            title={
              <div className={classes.itemsCenter}>
                <span className={classes.itemsCenter}>
                  <img
                    className={classes.mainIcon}
                    src={getPlatformImage(platform)}
                    alt={platform}
                  />{" "}
                  <DoubleArrow />
                </span>{" "}
                {campaign.campaignName}
              </div>
            }
          />
          <SendButton
            style={{ height: "50px", marginTop: "10px" }}
            onClick={() => toggleCampaignStatus(campaign.campaignType)}
          >
            {campaign.status === "paused" ? "Resume" : "Pause"}
          </SendButton>
        </Box>
      </div>

      <div className={`${classes.progress}`}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <div style={{ width: "75%" }}>
            <div
              style={{ marginBottom: 10, textTransform: "capitalize" }}
              className={classes.label}
            >
              {platform} {getCampaignType()}
            </div>
            {campaign.status === "running" ? (
              <BorderLinearProgress variant="indeterminate" />
            ) : (
              <BorderLinearProgress variant="determinate" value={100} />
            )}

            {campaign.status === "running" && (
              <div className={classes.running}>
                <RunningIcon style={{ marginRight: 10 }} /> Running{" "}
              </div>
            )}
          </div>

          <div>
            <div className={classes.toggleLabel}>
              {showConsole ? "Hide console" : "Show Console"}
            </div>
            <FormControlLabel
              control={
                <Switch
                  checked={showConsole}
                  onChange={toggle}
                  name="Show console"
                  classes={{
                    switchBase: classes.switchBase,
                    thumb: classes.thumb,
                    track: classes.track,
                  }}
                />
              }
              label=""
            />
          </div>
        </Box>
        <div>
          {showConsole && (
            <div className={classes.console}>
              {!campaign.history && !logs && (
                <History type="info" message="No Logs available" />
              )}
              {logs && <History type="info" message={logs} logs />}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AutoLikerPage;
