import React, { FC, useState, useEffect } from "react";
import { PageType } from "types/PageType";
import moment from "moment";
import MultiSelect from "components/Common/MultiSelect";

import useCollectionSubscriber from "../../hooks/useCollectionSubscriber";
import { firestore } from "../../firebase-tools/firebase";

import { useAuthHook, AuthHookType } from "hooks/AuthHook";

import BePlannedMonthlyPage from "components/BePlannedMonthly";
import BePlannedMonthlyStyled from "components/styled/BePlannedMonthly";
import { postsFilter } from "utils/postsFilter";
import { Grid } from "@material-ui/core";
import { useStyles } from "containers/BePlanned/styles";
import UserProfile from "containers/BeMeasured/UserProfile";

const channelOptions = [
  "Instagram",
  "Facebook",
  "Twitter",
  "TikTok",
  "YouTube",
  "Youtube Short",
  "LinkedIn",
  "Others",
  "All",
];

export const BePlannedMonthly: FC<PageType> = ({ location }) => {
  const classes = useStyles({});

  const [selectedDateTo, handleDateToChange]: any = useState(
    moment().endOf("month").endOf("day").toDate()
  );
  const [selectedDateFrom, handleDateFromChange]: any = useState(
    moment().startOf("month").startOf("day").toDate()
  );
  const hook = useAuthHook({ location } as AuthHookType);
  const user = hook.user;
  const curUser = Array.isArray(user) ? user[0][0] : user;
  const [authUser] = useState(curUser);

  const [, setClient]: any = useState("");
  const [loading, setLoading] = useState(false);
  // const [locationValue, setLocationValue]: any = useState("");
  const [channels, setChannels]: any = useState([]);

  const from = new Date(selectedDateFrom);
  let to = new Date(selectedDateTo);
  to = moment(to).add(1, "days").toDate();

  const handleDateChange = (date) => {
    const toDt = moment(date.end ? date.end : date);
    const froDt = moment(date.start ? date.start : date);
    handleDateToChange(toDt);
    handleDateFromChange(froDt);
  };

  let postsQueries: any = [];
  let eventsQueries: any = [];
  let pastEventsQueries: any = [];

  postsQueries = [
    firestore
      .collection("multichannel-posts")
      .where("user_email", "==", authUser.email)
      .where("date", ">=", from)
      .where("date", "<=", to)
      .orderBy("date", "desc"),
  ];

  eventsQueries = [
    firestore
      .collection("events")
      .where("user_email", "==", authUser.email)
      .where("start_date", ">=", from)
      .where("start_date", "<=", to)
      .orderBy("start_date", "desc"),
  ];

  pastEventsQueries = [
    firestore
      .collection("events")
      .where("user_email", "==", authUser.email)
      .where("end_date", ">=", from)
      .orderBy("end_date", "desc"),
  ];

  let events;
  let posts;
  let pastEvents;
  posts = useCollectionSubscriber({
    queries: postsQueries,
    deps: [selectedDateFrom, selectedDateTo, authUser],
  });

  events = useCollectionSubscriber({
    queries: eventsQueries,
    deps: [selectedDateFrom, selectedDateTo, authUser],
  });
  pastEvents = useCollectionSubscriber({
    queries: pastEventsQueries,
    deps: [selectedDateFrom, selectedDateTo, authUser],
  });

  const allPosts = posts.documents;

  // const allPosts = posts.documents?.filter(
  //   (doc) => doc.internal_status_monthly === "Client Approved"
  // );
  useEffect(() => {
    if (!!authUser?.lastClientSelection) {
      setClient(authUser?.lastClientSelection);
    } else {
      setClient("");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (loading) {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [posts.documents]);

  useEffect(() => {
    if (!posts && authUser !== "") setLoading(true);
    if (!posts.documents.length && authUser !== "") setLoading(true);
    // eslint-disable-next-line
  }, [selectedDateFrom, selectedDateTo, authUser]);

  let postsToFilter = {
    documents: allPosts,
  };

  return (
    <BePlannedMonthlyStyled>
      <Grid
        container
        className={`be-planned-monthly ${classes.contentPlannerRightAlign}`}
      >
        {/* <Grid item xs={12} md={6}></Grid> */}
        {authUser && <UserProfile user={authUser} />}

        <Grid
          item
          xs={12}
          md={6}
          id="selector-calendar"
          className={`selector ${classes.contentPlannerRightAlign}`}
        >
          <MultiSelect
            items={channels}
            setItems={setChannels}
            options={channelOptions}
            label="Choose Channels"
          />
        </Grid>
      </Grid>
      {/* <div className="be-planned-monthly" id="page-title">
        <span style={{ marginTop: 32, fontWeight: 900 }}> Monthly Planner</span>
        <div className="selector" id="selector-calendar">
          <div style={{ marginRight: "-40.5px" }}>
            <MultiSelect
              items={channels}
              setItems={setChannels}
              options={channelOptions}
              label="Choose Channels"
              width="100%"
            />
          </div>
        </div>
      </div> */}
      <BePlannedMonthlyPage
        posts={() => postsFilter(channels, postsToFilter)}
        events={[...pastEvents.documents, ...events.documents]}
        user={authUser}
        handleDateChange={handleDateChange}
      />

      {/* <BePlannedWeeklyPage
          posts={() => postsFilter(channels, postsToFilter)}
          events={events}
          user={authUser}
          handleDateChange={handleDateChange}
        /> */}
    </BePlannedMonthlyStyled>
  );
};
