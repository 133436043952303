import { numberWithCommas } from "utils/numberFormat";

export const radialBar = {
  options: {
    chart: {
      height: 390,
      type: "radialBar",
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      radialBar: {
        offsetY: 0,
        startAngle: 0,
        endAngle: 270,
        hollow: {
          margin: 5,
          size: "55%",
          background: "transparent",
        },
        dataLabels: {
          name: {
            show: false,
          },
          value: {
            show: false,
          },
        },
        track: {
          show: true,
          background: "#D8D8D8",
          startAngle: 0,
          endAngle: 270,
          opacity: 1,
          margin: 5,
        },
      },
    },
    colors: ["#4F7FE7", "#F5576C", "#F76B1C"],
    fill: {
      colors: ["#4F7FE7", "#F5576C", "#F76B1C"],
    },
    labels: ["Men", "Women", "Non-binary"],
    stroke: {
      lineCap: "round",
    },
    legend: {
      show: true,
      floating: true,
      position: "left",
      fontFamily: "Avenir Book, sans-serif",
      offsetX: 0,
      offsetY: 10,
      labels: {
        fontSize: "14px",
        lineHeight: "16px",
        colors: ["#101010"],
      },
      markers: {
        size: 0,
      },
      formatter: function (seriesName: any, opts: any) {
        return opts.w.globals.series[opts.seriesIndex] + "% " + seriesName;
      },
      itemMargin: {
        vertical: 3,
      },
    },
  },
};

export const bar = {
  options: {
    colors: ["#4F7FE7", "#F5576C", "#F76B1C"],
    fill: {
      colors: ["#4F7FE7", "#F5576C", "#F76B1C"],
    },
    chart: {
      type: "bar",
      height: 430,
      fontSize: "12px",
      LineHeight: "14px",
      fontFamily: "Avenir Book, sans-serif",
      foreColor: "#71757a",
    },
    zoom: {
      enabled: true,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        dataLabels: {
          position: "top",
        },
        columnWidth: "50px",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
      offsetX: -6,
      style: {
        fontSize: "12px",
        lineHeight: "14px",
        fontFamily: "Avenir Book, sans-serif",
        colors: ["#8D9AA9"],
      },
    },
    stroke: {
      show: true,
      width: 4,
      colors: ["#fff"],
    },
    xaxis: {
      categories: ["13-17", "18-24", "25-34", "35-44", "45-54", "55-64", "65+"],
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return numberWithCommas(value);
        },
      },
    },
    labels: ["Men", "Women", "Non-binary"],
    legend: {
      position: "top",
      fontFamily: "Avenir Book, sans-serif",
      offsetX: 0,
      offsetY: 20,
      horizontalAlign: "right",
      itemMargin: {
        horizontal: 40,
        vertical: 0,
      },
    },
    responsive: [
      {
        breakpoint: 490,
        options: {
          chart: {
            width: "98%",
          },
          legend: {
            itemMargin: {
              horizontal: 10,
            },
          },
          plotOptions: {
            bar: {
              columnWidth: "100%",
            },
          },
        },
      },
    ],
  },
};

export const area = {
  options: {
    chart: {
      height: 250,
      type: "area",
      fontSize: "12px",
      LineHeight: "14px",
      fontFamily: "Avenir Book, sans-serif",
      foreColor: "#71757a",
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#5187E0", "#D9E2F2"],
    stroke: {
      curve: "smooth",
      width: 2,
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return numberWithCommas(value);
        },
      },
    },
    tooltip: {
      x: {
        format: "dd MMM yyyy",
      },
    },
  },
};
