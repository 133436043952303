import React, { FC, Fragment, useState, memo, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { Check, Close } from "@material-ui/icons";
import { DateTime } from "luxon";
import { auth } from "firebase-tools/firebase";

import { useStyles } from "./styles";
import { DB } from "firebase-tools/db";
import { StatusButton } from "components/styled/Common";
import Notes from "components/BePlanned/Notes";
import { firestore } from "../../firebase-tools/firebase";
import useCollectionSubscriber from "../../hooks/useCollectionSubscriber";
import { filterNotes } from "./helpers/notes";
import { cancelReloadTabClose } from "utils/navigate";
import { useUnsaved } from "LinkContext";
import { saveFeedback } from "./helpers/notification";
import { useUser } from "UserContext";

interface IDecisions {
  post: any;
  user?: any;
  showAuthPrompt?: () => void;
}

const Decisions: FC<IDecisions> = ({
  post,
  user: userData,
  showAuthPrompt,
}) => {
  // current logged in user
  let authUser;
  let firebaseUser: any = auth.currentUser;
  if (firebaseUser?.email !== userData.email) {
    firebaseUser = {
      email: firebaseUser?.email,
      name: firebaseUser?.displayName,
    };
    authUser = firebaseUser;
  } else {
    authUser = userData;
  }
  const authEmail = authUser.email;

  const classes = useStyles({});
  const db = new DB();
  const [note, setNote] = useState("");
  const [, setUnsaved] = useUnsaved() as any;

  const notesQueries = [
    firestore
      .collection("multichannel-posts")
      .doc(post.id)
      .collection("notes")
      .orderBy("created_at", "desc"),
  ];
  let notes: any = useCollectionSubscriber({
    queries: notesQueries,
    deps: [post.id],
  }).documents;
  notes = filterNotes(notes);

  const updatePostStatus = async (status: string) => {
    await db.update("multichannel-posts", post.id, {
      internal_status_weekly: status,
    });
  };

  const [user, setUser]: any = useUser();
  const data = localStorage.getItem("kit-authUser");

  if (!Object.keys(user)?.length && data) {
    setUser(JSON.parse(data));
  }

  useEffect(() => {
    if (note.length > 0) setUnsaved(true);
    else setUnsaved(false);
    cancelReloadTabClose(note.length > 0);
    // eslint-disable-next-line
  }, [note]);

  return (
    <div className={classes.decisionsTopMargin} id="decisions">
      <Fragment>
        <Typography paragraph className={classes.approvalTitle}>
          Approval
        </Typography>
        <Grid container spacing={1}>
          <Grid item xs={6} sm={6}>
            <StatusButton
              disableRipple
              disable={true}
              endIcon={<Check />}
              onClick={async () => {
                if (!data) {
                  showAuthPrompt && showAuthPrompt();
                } else {
                  // const status =
                  //   post.internal_status_weekly === "Client Approved"
                  //     ? "Pending Client Approval"
                  //     : "Client Approved";
                  const status = "Client Approved";

                  try {
                    await saveFeedback(
                      "approval",
                      status,
                      {
                        id: post.id,
                        clientName:
                          userData.firstName + " " + userData.lastName,
                        userEmail: authEmail,
                        date: DateTime.fromSeconds(post.date.seconds),
                        title: post.title,
                      },
                      userData.email
                    );
                    await updatePostStatus(status);
                  } catch (error) {
                    let err = error as any;
                    if (
                      err.message === "Missing or insufficient permissions."
                    ) {
                      showAuthPrompt && showAuthPrompt();
                    }
                  }
                }
              }}
              style={{
                background:
                  post.internal_status_weekly === "Client Approved"
                    ? "green"
                    : "#abadad",
              }}
            >
              {post.internal_status_weekly === "Client Approved"
                ? "Approved"
                : "Approve"}
            </StatusButton>
          </Grid>
          <Grid item xs={6} sm={6}>
            <StatusButton
              disableRipple
              endIcon={<Close />}
              onClick={async () => {
                // if (window.location.pathname.includes("/public")) {
                //   showAuthPrompt && showAuthPrompt();
                // }
                if (!data) {
                  showAuthPrompt && showAuthPrompt();
                } else {
                  const status =
                    post.internal_status_weekly === "Client Declined"
                      ? "Pending Client Approval"
                      : "Client Declined";

                  try {
                    await saveFeedback(
                      "approval",
                      status,
                      {
                        id: post.id,
                        clientName:
                          userData.firstName + " " + userData.lastName,
                        userEmail: authEmail,
                        date: DateTime.fromSeconds(post.date.seconds),
                        title: post.title,
                      },
                      userData.email
                    );
                    await updatePostStatus(status);
                  } catch (error) {
                    let err = error as any;
                    if (
                      err.message === "Missing or insufficient permissions."
                    ) {
                      showAuthPrompt && showAuthPrompt();
                    }
                  }
                }
              }}
              style={{
                background:
                  post.internal_status_weekly === "Client Declined" &&
                  "linear-gradient(131.4deg, #F94E3F -9.75%, #F94E3F 84.22%)",
              }}
            >
              {post.internal_status_weekly === "Client Declined"
                ? "Rejected"
                : "Reject"}
            </StatusButton>
          </Grid>
        </Grid>
      </Fragment>
      <div>
        <Notes
          user={userData}
          note={note}
          setNote={setNote}
          notes={notes}
          post={post}
          showAuthPrompt={showAuthPrompt}
        />
      </div>
    </div>
  );
};

export default memo(Decisions);
