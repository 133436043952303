export const customHeaders = [
  { label: "Handle", key: "handle" },
  { label: "Name", key: "name" },
  { label: "Profile Url", key: "profileUrl" },
  { label: "query", key: "query" },
  { label: "Text", key: "text" },
  { label: "Time Stamp", key: "timestamp" },
  { label: "Tweet Date", key: "tweetDate" },
  { label: "Tweet Link", key: "tweetLink" },
  { label: "Twitter ID", key: "twitterId" },
];
