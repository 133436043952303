import axios from "axios";
import cuid from "cuid";
import { firestore } from "firebase-tools/firebase";
import { navigate } from "@reach/router";

type SearchAgentArgs = {
  sessionCookie: string;
  spreadsheetUrl: string;
  campaignName: string;
  exclude: string[];
  exact: string[];
  hash: string[];
  uid: string | undefined;
  createdAt: any;
  username: string;
};
export const launchSearchAgent = async ({
  spreadsheetUrl,
  sessionCookie,
  campaignName,
  exclude,
  exact,
  hash,
  uid,
  createdAt,
  username,
}: SearchAgentArgs) => {
  const data = {
    id: "5746241125363283",
    argument: {
      output: "result-object",
      csvName: `${uid}-result-${new Date()}`,
      searchType: "news",
      matchExactly: "",
      excludedWords: "",
      matchAnyWords: "",
      matchAllOfWords: "",
      reprocessAll: false,
      sessionCookie,
      spreadsheetUrl,
      numberOfLinesPerLaunch: 10,
      numberOfResultsPerLines: 800,
    },
  };
  const config = {
    headers: {
      Accept: "application/json",
      "X-Phantombuster-Key": process.env.REACT_APP_PHANTOM_API_KEY,
    },
  };
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/agents/launch`,
      data,
      config
    );

    if (!!uid) {
      await firestore.collection("twitter-campaigns").doc(uid).update({
        containerId: response.data?.containerId,
        spreadsheetUrl,
        campaignName,
        username,
        exclude,
        exact,
        hash,
        sessionCookie,
        status: "running",
        followLaunched: false,
        followFetched: false,
        messagesSent: false,
        searchResultFetched: false,
        updatedAt: new Date(),
      });
      navigate(`/campaigns/organic/${uid}`);
    } else {
      const id = cuid();
      await firestore.collection("twitter-campaigns").doc(id).set({
        containerId: response.data?.containerId,
        spreadsheetUrl,
        campaignName,
        username,
        exclude,
        exact,
        hash,
        sessionCookie,
        tweetsCount: 0,
        usersCount: 0,
        followedProfilesCount: 0,
        postsLikes: 0,
        resultObject: "",
        status: "running",
        createdAt,
        campaignType: "autofollow",
        archived: false,
        followLaunched: false,
        followFetched: false,
        messagesSent: false,
        searchResultFetched: false,
        updatedAt: new Date(),
      });
      navigate(`/campaigns/organic/${id}`);
    }

    return response.data;
  } catch (error) {
    console.log(error, "RESPONSE ERROR");
  }
};
