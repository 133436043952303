import React, { FC, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";

import SocialPostV2 from "./PostV2";
import { NoPosts } from "./Post/NoPosts";
import Decision from "components/BePlanned/Decisions";
import AuthPrompt from "components/Common/AuthPrompt";
import moment from "moment";
import Event from "./EventV2/Event";
interface IBePlanned {
  posts: () => any[];
  events?: () => any[];
  classes: any;
  user: any;
  sortDirection?: string;
}

const useStyles2 = makeStyles((theme) => ({
  visibleGrid: {
    display: "flex",
  },
  hiddenGrid: {
    display: "none",
  },

  dayCheckbox: {
    fontFamily: "Avenir",
    fontSize: "20px",
    fontWeight: 900,
    color: "#000000",
    "@media screen and (min-width: 1000px)": {
      // paddingLeft: "23px",
    },
  },
}));

export const BePlanned: FC<IBePlanned> = ({
  posts,
  events,
  classes,
  user,
  sortDirection,
}) => {
  const [authOpen, setAuthOpen] = useState(false);
  const classes2 = useStyles2();

  // let allPosts = posts()
  //   .map((val) => {
  //     return {
  //       ...val,
  //       dataType: "post",
  //     };
  //   })
  //   .sort((a, b) => a.date - b.date);

  // const allEvents =
  //   events !== undefined
  //     ? events()?.map((val) => {
  //         return {
  //           ...val,
  //           dataType: "event",
  //         };
  //       })
  //     : [];

  // const groupPostsByDay = (items: any[]) => {
  //   const groupedPosts: { [key: string]: any[] } = {};

  //   items.forEach((post) => {
  //     let postDate;
  //     if (post?.dataType === "event") {
  //       postDate = moment.unix(post.start_date.seconds);
  //     } else {
  //       postDate = moment.unix(post.date.seconds);
  //     }
  //     const formattedDate = postDate.format("YYYY-MM-DD");

  //     if (!groupedPosts[formattedDate]) {
  //       groupedPosts[formattedDate] = [];
  //     }

  //     groupedPosts[formattedDate].push(post);
  //   });

  //   return groupedPosts;
  // };

  // const groupedPosts = groupPostsByDay([...allPosts, ...(allEvents ?? [])]);

  // const formatDate = (inputDate) => {
  //   const dateParts = inputDate.split("-");
  //   const year = dateParts[0];
  //   const month = dateParts[1];
  //   const day = dateParts[2];

  //   const formattedDate = new Date(`${month}/${day}/${year}`);

  //   // Formatting options for the date
  //   const options: any = {
  //     day: "numeric",
  //     month: "short",
  //     year: "numeric",
  //   };

  //   return formattedDate.toLocaleDateString("en-GB", options);
  // };

  const allPosts = posts().map((val) => {
    return {
      ...val,
      dataType: "post",
    };
  });
  const allEvents =
    events !== undefined
      ? events()?.map((val) => {
          return {
            ...val,
            dataType: "event",
          };
        })
      : [];

  const groupPostsByDay = (items: any[]) => {
    const groupedPosts: { [key: string]: any[] } = {};
    if (items?.length > 0) {
      items.forEach((post) => {
        let postDate;
        if (post?.dataType === "event") {
          console.log("post.new_start_date", post.new_start_date);
          postDate = moment.unix(post.new_start_date.seconds);
          // postDate = moment.unix(post.start_date.seconds);
        } else {
          postDate = moment.unix(post.date.seconds);
        }
        const formattedDate = postDate.format("YYYY-MM-DD");

        if (!groupedPosts[formattedDate]) {
          groupedPosts[formattedDate] = [];
        }

        groupedPosts[formattedDate].push(post);
      });
    }
    return groupedPosts;
  };

  const sortedArray = Object.keys(
    groupPostsByDay([...(allPosts ?? []), ...(allEvents ?? [])])
  )
    .sort((a, b) => {
      const dateA = moment(a, "YYYY-MM-DD");
      const dateB = moment(b, "YYYY-MM-DD");
      if (sortDirection === "asc") {
        return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
      }
      return dateB.isBefore(dateA) ? -1 : dateB.isAfter(dateA) ? 1 : 0;
    })
    .reduce((obj, key) => {
      obj[key] = groupPostsByDay([...(allPosts ?? []), ...(allEvents ?? [])])[
        key
      ];
      return obj;
    }, {});

  // const groupedPosts = groupPostsByDay([
  //   ...(allPosts ?? []),
  //   ...(allEvents ?? []),
  // ]);

  const formatDate = (inputDate) => {
    const dateParts = inputDate.split("-");
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];

    const formattedDate = new Date(`${month}/${day}/${year}`);

    // Formatting options for the date
    const options: any = {
      day: "numeric",
      month: "short",
      year: "numeric",
    };

    return formattedDate.toLocaleDateString("en-GB", options);
  };
  const showAuthPrompt = () => {
    setAuthOpen(true);
    return;
  };
  return (
    <div
      style={{
        marginBottom: "50px",
      }}
    >
      {/* Posts */}
      {Object.keys(sortedArray).length ? (
        Object.keys(sortedArray).map((day) => (
          <div key={day}>
            <span className={classes2.dayCheckbox}>{formatDate(day)}</span>
            <br />
            <br />
            {sortedArray[day].map((post, index) => (
              <div key={index}>
                {post?.dataType === "post" && (
                  <Grid
                    container
                    spacing={0}
                    style={{ marginTop: index === 0 ? "-50px" : 0 }}
                    key={`post-${index}`}
                  >
                    <AuthPrompt isOpen={authOpen} setIsOpen={setAuthOpen} />
                    <Grid item xs={12} sm={8}>
                      <SocialPostV2 post={post} user={user} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Decision
                        post={post}
                        user={user}
                        showAuthPrompt={showAuthPrompt}
                      />
                    </Grid>
                  </Grid>
                )}
                {post?.dataType === "event" && (
                  <Grid
                    container
                    spacing={0}
                    style={{ marginTop: index === 0 ? "-50px" : 0 }}
                    key={`post-${index}`}
                  >
                    <AuthPrompt isOpen={authOpen} setIsOpen={setAuthOpen} />
                    <Grid item xs={12} sm={8}>
                      <Event
                        event={post}
                        user={user}
                        key={index}
                        role="multichannel"
                        uuid={post.uuid}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}></Grid>
                  </Grid>
                )}

                <hr
                  style={{
                    marginTop: 20,
                    color: "#ABADAD",
                    border: "1px solid #ABADAD",
                  }}
                />
              </div>
            ))}
          </div>
        ))
      ) : (
        <NoPosts title="There are no posts in the selected period" />
      )}
    </div>
  );
};
