import React, { FC, useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Vimeo from "@u-wave/react-vimeo";

import Image from "../Image/Image";
import CarouselStyles from "components/styled/Carousel";
import { useStyles } from "./styles";

interface CarouselProps {
  images: Array<any>;
  video: string;
}
export const CarouselItem: FC<CarouselProps> = ({ images, video }) => {
  const classes = useStyles({});
  const getImages = () => {
    let data = images.map((image, i) =>
      image !== "" ? (
        <Image src={image} alt={`post pic ${i + 1}`} key={`${i}`} image />
      ) : null
    );
    data = data.filter((item) => item !== null);
    return data;
  };

  const [data, setData]: any = useState(getImages());

  const vimeoRegex = /^(http:\/\/|https:\/\/)(vimeo\.com)\/([\w]+)?$/;
  const youtubeRegex = /(http:|https:)?\/\/(www\.)?(youtube.com|youtu.be)\/(watch)?(\?v=)?(\S+)?/;
  const videoMarkup =
    video !== "" ? (
      <div key={video} className={classes.videoContainer}>
        {video && video !== "" && video.includes("firebasestorage") && (
          <div className={classes.videoPreview}>
            <video controls>
              <source src={video} />
              Your browser does not support HTML video.
            </video>
          </div>
        )}
        {video && video.includes("http") && vimeoRegex.test(video) && (
          <div className={classes.videoPreview}>
            <Vimeo video={video} className={classes.vimeo} />
          </div>
        )}
        {video && video.includes("http") && youtubeRegex.test(video) && (
          <div className={classes.vimeo} style={{ height: "470px" }}>
            <iframe
              src={`https://www.youtube.com/embed/${
                video.split("v=")[1]
              }?autoplay=0&showinfo=0`}
              frameBorder="0"
              allowFullScreen
              title="Embedded youtube"
            />
          </div>
        )}
      </div>
    ) : null;

  useEffect(() => {
    const data = getImages();
    if (videoMarkup) {
      data.unshift(videoMarkup);
    }
    setData(data);
    //eslint-disable-next-line
  }, [images]);
  return (
    <CarouselStyles isCarousel={data.length > 1}>
      {data.length === 1 ? (
        <div style={{ width: "100%" }}>
          {video === "" ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                position: "relative",
              }}
            >
              {getImages()}
            </div>
          ) : (
            <div style={{ position: "relative", width: "100%" }}>
              {videoMarkup}
            </div>
          )}
        </div>
      ) : (
        <CarouselStyles>
          <Carousel
            dynamicHeight={false}
            showThumbs={false}
            autoPlay={false}
            showArrows={data.length > 1}
            showIndicators={data.length > 1}
            showStatus={false}
            className={classes.container}
            infiniteLoop={true}
          >
            {data}
          </Carousel>
        </CarouselStyles>
      )}
    </CarouselStyles>
  );
};
