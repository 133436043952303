import axios from "axios";

export const getContainerPosts = async (
  containerId: string,
  hashtags: string[]
) => {
  const config = {
    headers: {
      Accept: "application/json",
      "X-Phantombuster-Key": process.env.REACT_APP_PHANTOM_API_KEY,
    },
  };
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/containers/fetch-result-object?id=${containerId}`,
      config
    );
    if (!response.data?.resultObject) {
      return null;
    }

    const resultObject = JSON.parse(response.data?.resultObject);
    let posts = resultObject;

    if (resultObject.jsonUrl) {
      const result = await axios.get(resultObject.jsonUrl);
      posts = result.data;
    }

    if (!posts) {
      return null;
    }

    return posts.filter((post) => hashtags.includes(post.query));
  } catch (error) {
    console.log(error, "RESPONSE ERROR");
    return null;
  }
};
