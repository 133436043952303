import { DB } from "firebase-tools/db";

export const fetchFbIGMetrics = async (
  collection: string,
  username: string,
  from,
  to
) => {
  const db = new DB();
  const results = await db.metricsByDate(
    collection,
    username,
    from,
    to,
    "status",
    "complete"
  );

  return results;
};

export const fetchMetrics = async (
  collection: string,
  username: string,
  from,
  to
) => {
  const db = new DB();
  const results = await db.metricsByDate(collection, username, from, to);

  return results;
};
