import React, { FunctionComponent, ReactNode } from "react";
import Typography from "@material-ui/core/Typography";
import { Link, useLocation } from "@reach/router";

import { useStyles } from "./styles";
import { useUnsaved } from "../../LinkContext";

interface LinkProps {
  path: string;
  children: ReactNode;
}
export const NavLink: FunctionComponent<LinkProps> = ({ path, children }) => {
  const classes = useStyles({});
  const location = useLocation();
  return (
    <Typography
      variant="h6"
      className={classes.navTitle}
      style={{
        opacity: location.pathname.includes(`/${path}`) ? 1 : 0.5,
      }}
    >
      <BlockingLink
        to={`/${path}`}
        style={{ textDecoration: "none", color: "#FFFFFF" }}
      >
        {children}
      </BlockingLink>
    </Typography>
  );
};

export const BlockingLink = (props) => {
  const [unsaved, setUnsaved] = useUnsaved() as any;
  return (
    <Link
      {...props}
      onClick={(e) => {
        if (unsaved) {
          if (!window.confirm("Changes you made may not be saved.")) {
            e.preventDefault();
          } else {
            setUnsaved(false);
          }
        }
        // continue
        props.onClick && props.onClick(e);
      }}
    />
  );
};
