/**
 * @prettier
 */
import { DB } from "firebase-tools/db";

export class AgentsService extends DB {
  private collectionName: string = "client-agents";
  private _doc: string = "";

  constructor(doc?: string) {
    super();
    if (doc) {
      this.doc = doc;
    }
  }
  set doc(value: string) {
    this._doc = value;
  }

  get doc() {
    return this._doc;
  }
  async getAgentByDoc(): Promise<any[]> {
    return this.getDoc(this.collectionName, this.doc);
  }

  async getAgentByField(
    fieldName: string,
    value: any,
    operation: string = "==",
    createdAt: string = "created_at"
  ): Promise<any> {
    return this.getByField(
      this.collectionName,
      fieldName,
      value,
      operation,
      createdAt
    );
  }

  async getAgents(): Promise<any> {
    return this.get(this.collectionName);
  }
}
