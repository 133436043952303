import React, { FunctionComponent, useState } from "react";
import { Grid, ClickAwayListener } from "@material-ui/core";
import { DateRangePicker } from "react-date-range";
import { fromEvent, Observable } from "rxjs";
import styled from "styled-components";

import { useStyles } from "./styles";
import "./styles.css";
import { ReactComponent as DropDownIcon } from "../../../statics/media/dropDown.svg";
import { longDate } from "../../../utils/dateFormat";

import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file

interface DateProps {
  dateRange?: any;
  setDateRange?: (range: any) => void;
  setParamsChange?: (val: any) => void;
  disableFuture?: boolean;
}

const DateRangeStyled = styled.div`
  .rdrDayToday > .rdrDayNumber {
    span::after {
      content: "";
      position: absolute;
      background: #000 !important;
    }
  }
`;

const DateRangePickerV2: FunctionComponent<DateProps> = ({
  dateRange,
  setDateRange,
  setParamsChange,
  disableFuture,
}) => {
  const classes = useStyles({});
  const [open, setOpen] = useState(false);
  const [dateClick, setDateClick] = useState(0);
  const [state, setState] = useState({
    startDate: dateRange.startDate ? new Date(dateRange.startDate) : new Date(),
    endDate: dateRange.endDate ? new Date(dateRange.endDate) : new Date(),
    key: "selection",
  });

  const [mobileMenu, switchMobileMenu] = useState(
    !(document.documentElement.offsetWidth > 1000)
  );

  const resize$: Observable<Event> = fromEvent(window, "resize");

  resize$.subscribe((res: any) => {
    if (res && res.currentTarget) {
      switchMobileMenu(!(res.currentTarget.innerWidth > 1000));
    }
  });
  const toggle = () => {
    setOpen(!open);
    setDateClick(0);
  };

  const handleChange = (range) => {
    const clickCount = dateClick + 1;
    setDateClick(clickCount);
    setState({ ...range.selection, key: "selection" });

    if (clickCount > 1) {
      setDateRange && setDateRange(range.selection);
      setParamsChange && setParamsChange("date-range");
      toggle();
    }
  };

  return (
    <div
      className={classes.datePickerContainer}
      id="date-picker"
      style={{
        width: "100%",
      }}
    >
      <span className={classes.dateLabel}>Choose Period</span>

      <div style={{ height: "45px" }}>
        <div onClick={toggle} className={classes.dateInput}>
          {longDate(dateRange.startDate)} - {longDate(dateRange.endDate)}
          <div
            style={{
              transform: open ? "rotate(180deg)" : "",
              marginLeft: "10px",
              marginTop: open ? 1 : "-4px",
            }}
          >
            <DropDownIcon />
          </div>
        </div>
        <Grid>
          {open && (
            <ClickAwayListener onClickAway={toggle}>
              <DateRangeStyled>
                <DateRangePicker
                  editableDateInputs={true}
                  className={classes.datePicker2}
                  ranges={[state]}
                  onChange={handleChange}
                  showSelectionPreview
                  moveRangeOnFirstSelection={false}
                  months={2}
                  direction={mobileMenu ? "vertical" : "horizontal"}
                  weekStartsOn={1}
                  minDate={new Date("2020-06-06")}
                  maxDate={disableFuture && (new Date() as any)}
                  rangeColors={["#000000"]}
                  color="#000000"
                  dragSelectionEnabled={false}
                />
              </DateRangeStyled>
            </ClickAwayListener>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default DateRangePickerV2;
