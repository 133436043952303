/**
 * @prettierdesc
 */

import React, { FC, useState } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { navigate } from "@reach/router";
import cuid from "cuid";

import CreateCampaignStyles from "components/styled/CreateCampaign";
import Image from "components/Common/Image/Image";
import history from "history/browser";
import twitter from "statics/media/twitter-vector.png";
import defaultIcon from "statics/media/universal-2.png";
import { PageType } from "types/PageType";
import { firestore } from "firebase-tools/firebase";
import { useUser } from "UserContext";
import CampaignSelect from "components/BeAmplified/Campaigns/Organic/Components/CampaignSelect/CampaignSelect";
import { SendButton } from "components/styled/Common";
import GetCookies from "components/Common/GetCookies";
import CampaignName from "components/Common/CampaignName";
import { autoDM } from "helpers/twitterAutoDM/autoDM";
import { SocialMediaPlatform } from "containers/BeAmplified/Campaigns/Organic/SelectCampaignPage";
import showToast from "utils/toast";

interface CreateCampaignProps extends PageType {
  uid?: string;
}

export const Twitter: FC<CreateCampaignProps> = () => {
  const [sessionCookie, setSessionCookie] = useState("");
  const [message, setMessage]: any = useState("");
  const [organicCampaign, setOrganicCampaign] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [campaignName, setCampaignName] = useState("");

  const [user]: any = useUser();
  const client = Array.isArray(user) ? user[0][0] : user;

  const createCampaign = (): void => {
    if (!campaignName || !message || !sessionCookie || !client) {
      showToast("Please fill all the fields");
      return;
    }

    setLoading(true);
    const id = cuid();

    firestore
      .collection("twitter-auto-dm")
      .doc(id)
      .set({
        campaignName,
        message,
        messagesSent: false,
        sessionCookie: client?.twitter?.sessionCookie || sessionCookie,
        organicCampaign,
        client: client.id,
        createdAt: new Date(),
        updatedAt: new Date(),
        archived: false,
        campaignType: "auto-dm",
        status: "running",
        screenName: client?.twitter?.username,
        followersMatched: false,
        followersFetched: false,
        allFollowers: [],
        messagedProfiles: [],
        username: client.twitter?.username || "",
      })
      .then(() => {
        setLoading(false);
        autoDM({ id });
        setMessage("");
        setOrganicCampaign("");
        setSessionCookie("");
        setCampaignName("");
        navigate(`/campaigns/organic/twitter/auto-dm/${id}`);
      })
      .catch(() => {
        showToast("There was an error creating the campaign");
      });
  };
  return (
    <CreateCampaignStyles>
      <ToastContainer />
      <div>
        <span className="title__main">{"Create"} Campaign</span>

        <Box marginTop="50px">
          <div className="items-center">
            <Image className="img__main" src={twitter || defaultIcon} alt="" />
            <span className="title__sub">Twitter Auto Direct Message</span>
          </div>
        </Box>
        <p className="description__main">
          Send personalized messages to your Twitter followers.
        </p>
      </div>

      <CampaignName
        campaignName={campaignName}
        setCampaignName={setCampaignName}
      />

      <CampaignSelect
        campaign={organicCampaign}
        setCampaign={setOrganicCampaign}
      />

      <GetCookies
        setSessionCookie={setSessionCookie}
        sessionCookie={sessionCookie}
        platform={SocialMediaPlatform.Twitter}
        title="Your Twitter session cookie"
        platformIcon={twitter}
      />

      <div className="campaign__container">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <span className="title__basic">Message</span>
        </Box>
        <span className="description__sub">Type your message here.</span>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="campaign__textarea"
        ></textarea>
      </div>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginTop="40px"
      >
        <div
          className="title__sub link"
          onClick={() => {
            history.back();
          }}
        >
          {"<"} Back
        </div>
        <SendButton
          onClick={(e) => {
            e.preventDefault();
            !loading && createCampaign();
          }}
          className="btn"
          disabled={loading}
        >
          Create Campaign
          {loading && <CircularProgress className="btn-progress" size={20} />}
        </SendButton>
      </Box>
    </CreateCampaignStyles>
  );
};
