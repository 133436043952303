import styled from "styled-components";

const Progress = styled.div`
  position: fixed;
  background: linear-gradient(
    to right,
    #1d4e89 ${(props) => props.scroll},
    transparent 0
  );
  width: 100%;
  height: 4px;
  z-index: 113;
  margin-top: ${(props) => (props.mgTop ? props.mgTop : "-1px")};
`;

export default Progress;
