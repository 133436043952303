import React, { FC } from "react";
import Card from "@material-ui/core/Card";

import { Navbar } from "../../containers/Dashboard/Navbar";
import { PageType } from "types/PageType";
import { useStyles } from "./styles";
import { useUser } from "UserContext";

export const NotFound: FC<PageType> = () => {
  const classes = useStyles({});
  const [authUser]: any = useUser();

  return (
    <div style={{ background: "#f2f2f2", minHeight: "100vh" }}>
      {authUser.role !== "user" && <Navbar />}

      <div className={classes.root}>
        <Card className={classes.card}>
          <div>
            <h1 style={{ fontSize: "4rem", margin: 0 }}>404</h1>
          </div>
          <div style={{ paddingBottom: "37px" }}>Page Not Found!</div>
          <div>It looks you're lost. Try another page.</div>
        </Card>
      </div>
    </div>
  );
};
