import { Box, Tooltip } from "@material-ui/core";
import React, { FC } from "react";

import InfoIcon from "@material-ui/icons/Info";

import Image from "components/Common/Image/Image";

import defaultIcon from "statics/media/universal-2.png";
import { SocialMediaPlatform } from "containers/BeAmplified/Campaigns/Organic/SelectCampaignPage";
import { TwitterInfo, FacebookInfo, InstagramInfo } from "./InfoDivComponent";

type GetCookiesProps = {
  sessionCookie: string;
  setSessionCookie: any;
  platform: SocialMediaPlatform;
  title: string;
  tokeType?: string;
  platformIcon: any;
};

const GetCookies: FC<GetCookiesProps> = ({
  sessionCookie,
  setSessionCookie,
  platform,
  platformIcon,
  title,
  tokeType,
}) => {
  const toolTip = () => {
    switch (platform) {
      case SocialMediaPlatform.Twitter:
        return <TwitterInfo />;
      case SocialMediaPlatform.Facebook:
        return <FacebookInfo tokenType={tokeType} />;
      case SocialMediaPlatform.Instagram:
        return <InstagramInfo />;
      default:
        return <FacebookInfo />;
    }
  };

  return (
    <div className="campaign__container">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <span className="title__basic">{title}</span>
        <Tooltip
          title={
            <Box>
              <h1>Get your cookies manually</h1>
              {toolTip()}
            </Box>
          }
          arrow
          interactive
        >
          <InfoIcon style={{ color: "#C4C4C4" }} />
        </Tooltip>
      </Box>

      <div className="campaign__connect" style={{ background: "white" }}>
        <Box display="flex" alignItems="center" width="100%" padding="14px">
          <Image
            className="img__small"
            src={platformIcon || defaultIcon}
            alt=""
          />

          <input
            value={sessionCookie}
            onChange={(e) => setSessionCookie(e.target.value)}
            type="text"
            className="description__small hidden-input"
            placeholder="Paste your cookie here"
          />
        </Box>
      </div>
    </div>
  );
};

export default GetCookies;
