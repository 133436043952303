export const postsFilter = (channels, multiChannel) => {
  let filteredPosts: any = [];

  if (!channels.length || channels.includes("All")) {
    filteredPosts = multiChannel.documents;
  } else {
    if (channels.includes("Facebook")) {
      const posts = multiChannel.documents.filter((ch) =>
        ch.channels.includes("facebook")
      );
      filteredPosts = filteredPosts.concat(posts);
    }
    if (channels.includes("Instagram")) {
      const posts = multiChannel.documents.filter((ch) =>
        ch.channels.includes("instagram")
      );
      filteredPosts = filteredPosts.concat(posts);
    }
    if (channels.includes("Twitter")) {
      const posts = multiChannel.documents.filter((ch) =>
        ch.channels.includes("twitter")
      );
      filteredPosts = filteredPosts.concat(posts);
    }
    if (channels.includes("TikTok")) {
      const posts = multiChannel.documents.filter((ch) =>
        ch.channels.includes("tiktok")
      );
      filteredPosts = filteredPosts.concat(posts);
    }
    if (channels.includes("YouTube")) {
      const posts = multiChannel.documents.filter((ch) =>
        ch.channels.includes("youtube")
      );
      filteredPosts = filteredPosts.concat(posts);
    }
    if (channels.includes("Others")) {
      const posts = multiChannel.documents.filter(
        (post) =>
          !!post.channels.filter(
            (x) =>
              ![
                "facebook",
                "twitter",
                "instagram",
                "tiktok",
                "youtube",
              ].includes(x.toLowerCase())
          ).length
      );
      filteredPosts = filteredPosts.concat(posts);
    }
  }

  return filteredPosts.filter((post) => post.is_visible_to_client === "Yes");
};
