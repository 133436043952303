/**
 * @prettier
 */

import React, { FunctionComponent, useState } from "react";
import { CircularProgress, Button } from "@material-ui/core";

import Email from "components/Common/Form/Email";
import Password from "components/Common/Form/Password";
import { useUser } from "UserContext";
import { auth, firestore } from "firebase-tools/firebase";
import { PageType } from "types/PageType";
import { Formik } from "formik";
import * as Yup from "yup";

import { UserService } from "services/UserService";
import { AppConfig } from "AppConfig";
import { IUser } from "interfaces/IUser";
import { useStyles } from "./styles";
import { getAgent, AllClientsInactive } from "../../utils/authHelpers";
import { getQueryParameter } from "utils/getQueryParameter";
import { redirectBasedOnPath } from "utils/redirectBasedOnPath";
import { usePrimaryUser } from "PrimaryUserContext";

export const Login: FunctionComponent<PageType> = () => {
  const classes = useStyles({});
  const [error, setError] = useState("");
  const [, setIsLogin] = useState(false);
  const [, setUser] = useUser() as any;
  const [, setPrimaryUser] = usePrimaryUser() as any;
  const [loading, setLoading] = useState(false);

  const fetchUsers = async (email: string) => {
    try {
      const snapshot = await firestore.collection("users").get();
      const matchingUsers = snapshot.docs
        .filter((doc) => {
          const primaryEmail = doc.data().email; // Assuming primary email is stored under 'email' field
          const secondaryEmails = doc.data().secondaryEmails || []; // Assuming secondary emails are stored under 'secondaryEmails' field

          // Check if primary email or any secondary email matches the authenticated user's email
          return (
            primaryEmail === email ||
            secondaryEmails.some((emailObj) => emailObj.email === email)
          );
        })
        .map((doc) => {
          console.log("Mapped doc:", doc);
          return {
            id: doc.id,
            ...doc.data(),
          };
        });

      console.log("Matching users:", matchingUsers);
      if (matchingUsers.length > 0) {
        return matchingUsers[0];
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      return null;
    }
  };

  const login = async (email: string, password: string) => {
    setLoading(true);
    const User = new UserService();
    const users = (await User.getUserByField("email", email).catch(
      setError
    )) as Array<IUser>;
    if (users && users.length && users[0].role !== AppConfig.role) {
      setLoading(false);
      return setError("You are admin, not client!");
    }
    if (users && users.length && !users[0].isSignedUp) {
      setLoading(false);
      return setError("Account does not exist yet!");
    }
    if (users && users.length && !users[0].isActivated) {
      setLoading(false);
      return setError("Account is deactivated!");
    }
    const agent = await getAgent(email);
    if (agent) {
      const { allInactive } = await AllClientsInactive(agent);
      if (allInactive) {
        setLoading(false);
        return setError("Account is deactivated!");
      }
    }
    await auth
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        localStorage.removeItem("clients");
        localStorage.removeItem("kit-authUser");
        localStorage.removeItem("account-chosen");
        localStorage.removeItem("kit-authUserData");
        setIsLogin(true);
        auth.onAuthStateChanged(async (userAuth) => {
          setUser(userAuth);
          // localStorage.setItem("kit-authUser", JSON.stringify(userAuth));
          localStorage.setItem("kit-authUserData", JSON.stringify(userAuth));
        });
        localStorage.setItem("account-chosen", JSON.stringify("chosen"));

        fetchUsers(email).then((user) => {
          if (user) {
            setPrimaryUser(user);
          }
        });

        const startDate = getQueryParameter("start");
        const endDate = getQueryParameter("end");
        const pagePath = getQueryParameter("path");

        if (startDate && endDate && pagePath) {
          redirectBasedOnPath(pagePath, startDate, endDate);
        }
      })
      .catch((error) => {
        if (
          error.code === "auth/user-not-found" ||
          error.code === "auth/wrong-password"
        ) {
          setError("Invalid credentials!");
        } else {
          setError("An error occurred!");
        }
        setLoading(false);
      });
  };

  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      onSubmit={(values) => {
        login(values.email, values.password);
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string().email("Invalid email!").required("Required!"),
        password: Yup.string().required("Required!"),
      })}
    >
      {(props) => {
        const { values, touched, errors, handleChange, handleSubmit } = props;
        return (
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <Email
              autoFocus
              onChangeHandler={(e) => {
                handleChange(e);
                setError("");
              }}
              value={values.email}
              helperText={errors.email && touched.email && errors.email}
              error={errors.email && touched.email}
              color={errors.email ? "#f44336 !important" : "#fff !important"}
              marginTop="24px"
            />
            <Password
              onChangeHandler={(e) => {
                handleChange(e);
                setError("");
              }}
              value={values.password}
              helperText={
                errors.password && touched.password && errors.password
              }
              error={errors.password && touched.password}
              color={errors.password ? "#f44336 !important" : "#fff !important"}
              marginTop="24px"
            />
            <span className={classes.error}>{error}</span>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              classes={{ disabled: classes.disabledButton }}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={30} color="inherit" />
              ) : (
                "Sign In"
              )}
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};
