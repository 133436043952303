/**
 * @prettier
 */

import React, {
  useContext,
  FunctionComponent,
  useState,
  useEffect,
} from "react";
type authUserType = Object;
type setAuthUserType = (user: authUserType) => void;
type authUserContextType = Array<authUserType | setAuthUserType>;

const AuthUserContext = React.createContext([] as authUserContextType);

const useAuthUser = (): authUserContextType => {
  const context: authUserContextType = useContext(AuthUserContext);
  if (!context) {
    throw new Error(`auth user must be used within a AuthUserProvider`);
  }
  return context;
};

const AuthUserProvider: FunctionComponent = (props) => {
  const existingAuthUser = localStorage.getItem("kit-authUserData");
  const [authUserData, setAuthUserData] = useState(
    existingAuthUser ? JSON.parse(existingAuthUser) : {}
  ) as authUserContextType;

  useEffect(() => {
    localStorage.setItem("kit-authUserData", JSON.stringify(authUserData));
  }, [authUserData]);

  return (
    <AuthUserContext.Provider
      value={[authUserData, setAuthUserData]}
      {...props}
    />
  );
};

export { useAuthUser, AuthUserProvider };
