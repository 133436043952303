import React, { FunctionComponent } from "react";
import { RouteComponentProps } from "@reach/router";
import { PageType } from "types/PageType";
import { useUser } from "UserContext";
import { Navbar } from "./Navbar";
import { useStyles } from "./styles";

export const Dashboard: FunctionComponent<PageType | RouteComponentProps> = ({
  children,
}) => {
  const classes = useStyles({});
  const [user] = useUser() as any;
  return (
    <>
      {user && (
        <div className={classes.dashboardRoot}>
          <div id="header">
            <Navbar />
          </div>
          <div className={classes.container} id="container">
            <div id="to-print" style={{ padding: "0 20px" }}>
              {children}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
