import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    marginTop: "25px",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#1D4E89",
    height: "46px",
    borderRadius: "10px",
    textTransform: "capitalize",
    fontFamily: "Avenir Book, sans-serif",
    fontSize: "20px",

    "&:hover": {
      opacity: 0.9,
      backgroundColor: "#1D4E89",
    },
  },
  backgroundColor: {
    backgroundColor: "#fff",
  },
  label: {
    color: "#fff",
    top: -10,
    fontWeight: "bold",
  },
  icon: {
    color: "gray",
  },
  error: {
    color: "#f44336",
    fontSize: "13px",
    textAlign: "center",
    width: "75%",
  },
  disabledButton: {
    backgroundColor: "#1D4E89 !important",
    color: "#fff !important",
  },
  checkbox: {
    paddingLeft: "0",
    marginLeft: "-2px",
    "&.MuiIconButton-colorPrimary": {
      color: "#fff",
    },
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    fontFamily: "Avenir Black, sans-serif",
    fontSize: "17px",
  },
}));
