import React, { FC, useEffect, useState } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import { navigate } from "@reach/router";
import { Modal } from "@material-ui/core";
import { PageType } from "types/PageType";
import PageTitle from "components/Common/PageTitle";
import { useStyles, CTAContainer } from "./styles";
import { SendButton } from "components/styled/Common";
import CampaignTypes from "components/BeAmplified/Campaigns/Organic/Components/CampaignTypes";
import BeAmplified from "components/BeAmplified/Campaigns/Organic";
import { useUser } from "UserContext";
import { firestore } from "firebase-tools/firebase";
import EmptyCampaigns from "components/BeAmplified/Campaigns/Organic/Components/EmptyCampaigns";
import useCollectionSubscriber from "hooks/useCollectionSubscriber";
import Loader from "components/Loader";
import { NoPosts } from "components/BePlanned/Post/NoPosts";
import Archived from "components/BeAmplified/Campaigns/Organic/Components/Archived";

const BeAmplifiedPage: FC<PageType> = () => {
  const classes = useStyles({});
  const [isLoading, setIsLoading] = useState(false);
  const [user]: any = useUser();
  const authUser = Array.isArray(user) ? user[0][0] : user;
  const [archivedOpen, setArchivedOpen] = useState(false);

  const twitterQueries = [
    firestore
      .collection("twitter-campaigns")
      .where("username", "==", authUser?.twitter?.username || ""),
    firestore
      .collection("twitter-auto-poster")
      .where("username", "==", authUser?.twitter?.username || ""),
    firestore
      .collection("twitter-auto-dm")
      .where("username", "==", authUser?.twitter?.username || ""),
    firestore
      .collection("twitter-auto-liker")
      .where("username", "==", authUser?.twitter?.username || ""),
  ];
  const fbQueries = [
    firestore
      .collection("facebook-auto-liker")
      .where("username", "==", authUser?.facebook?.username || ""),
    firestore
      .collection("facebook-group-scrapper")
      .where("username", "==", authUser?.facebook?.username || ""),
    firestore
      .collection("facebook-auto-dm")
      .where("username", "==", authUser?.facebook?.username || ""),
  ];

  const instagramQueries = [
    firestore
      .collection("instagram-autofollow-campaigns")
      .where("client", "==", authUser?.id || ""),
    firestore
      .collection("instagram-auto-liker")
      .where("username", "==", authUser?.instagram?.username || ""),
    firestore
      .collection("instagram-autocommenter")
      .where("username", "==", authUser?.instagram?.username || ""),
  ];
  const twitterCampaigns = useCollectionSubscriber<any[]>({
    queries: twitterQueries,
    deps: [authUser],
  });

  const instagramCampaigns = useCollectionSubscriber<any[]>({
    queries: instagramQueries,
    deps: [authUser],
  });
  const facebookCampaigns = useCollectionSubscriber<any[]>({
    queries: fbQueries,
    deps: [authUser],
  });
  const [selected, setSelected] = useState({
    facebook: [],
    instagram: [],
    twitter: [],
  });
  const [selectedArr, setSelectedArr]: any = useState([]);

  const processTwitterData = (data: Array<any>): Array<any> => {
    const campaingsMap = new Map();
    campaingsMap.set("Twitter Auto Followers", "autofollow");
    campaingsMap.set("Twitter Auto Poster", "autoPoster");
    campaingsMap.set("Twitter Auto Direct Message", "auto-dm");
    campaingsMap.set("Twitter Auto Liker", "autoLiker");
    campaingsMap.set("Twitter Auto Liker", "autoLiker");

    const typesArray = selectedArr.map((typeLabel: any) => {
      return campaingsMap.get(typeLabel);
    });
    return data
      .map((campaign: any) => ({
        ...campaign,
        posts:
          campaign.campaignType === "autoPoster"
            ? campaign.posts?.length || 0
            : campaign.tweetsCount,
        postCount:
          campaign.campaignType === "autoPoster"
            ? campaign.posts?.filter((post: any) => post.sent)?.length || 0
            : 0,
      }))
      .filter((campaign) => {
        return selectedArr.length > 0
          ? typesArray.indexOf(campaign.campaignType) > -1
          : [""].indexOf(campaign.campaignType);
      })
      .filter(
        (campaign) =>
          campaign.username === authUser.twitter?.username ||
          (!campaign.username && authUser.firstName === "1test")
      )
      .map((camp: any) => {
        camp.platform = "twitter";
        return camp;
      });
  };
  const processFacebookData = (data: Array<any>): Array<any> => {
    const campaingsMap = new Map();
    campaingsMap.set("Facebook Auto Liker", "fbAutoLiker");
    campaingsMap.set("Facebook Group Scrapper", "groupScrapper");
    campaingsMap.set("Facebook Auto Direct Message", "auto-dm");
    const typesArray = selectedArr.map((typeLabel: any) => {
      return campaingsMap.get(typeLabel);
    });

    return data
      .filter((campaign) => {
        return selectedArr.length > 0
          ? typesArray.indexOf(campaign.campaignType) > -1
          : [""].indexOf(campaign.campaignType);
      })
      .filter((campaign) => campaign.username === authUser?.facebook?.username)
      .map((campaign) => ({
        ...campaign,
        platform: "facebook",
        posts: campaign.profiles?.length || 0,
        followedProfilesCount:
          campaign.proofiles?.filter((profile) => profile.sent)?.length || 0,
      }));
  };
  const processInstagramData = (data: any[]): any[] => {
    const instagramMap = new Map();
    instagramMap.set("Instagram Auto Followers", "autoFollow");
    instagramMap.set("Instagram Auto Liker", "igAutoLiker");
    instagramMap.set("Instagram Auto Commenter", "igAutoCommenter");
    const typesArray = selectedArr.map((typeLabel) => {
      return instagramMap.get(typeLabel);
    });
    return data
      .filter((campaign) => {
        return selectedArr.length > 0
          ? typesArray.indexOf(campaign.campaignType) > -1
          : [""].indexOf(campaign.campaignType);
      })
      .map((campaign) => ({
        ...campaign,
        platform: "instagram",
        posts: campaign.profiles?.length,
        followsCount: campaign.profiles?.filter((profile) => profile.following)
          .length,
      }))
      .filter((campaign) => {
        return selectedArr.length > 0
          ? typesArray.indexOf(campaign.campaignType) > -1
          : [""].indexOf(campaign.campaignType);
      });
  };

  useEffect(() => {
    setIsLoading(true);
  }, []);

  useEffect(() => {
    if (isLoading) {
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [
    twitterCampaigns.documents,
    instagramCampaigns.documents,
    facebookCampaigns.documents,
  ]);

  const allTwitterCampaigns = twitterCampaigns.documents.filter(
    (campaign: any) => !campaign.archived
  );
  const twitterFilteredData = processTwitterData(allTwitterCampaigns);
  const instagramFilteredData = processInstagramData(
    instagramCampaigns.documents
  );
  const allFacebookCampaigns = facebookCampaigns.documents.filter(
    (campaign: any) => !campaign.archived
  );
  const facebookFilteredData = processFacebookData(allFacebookCampaigns);

  const allCampaigns = [
    ...allTwitterCampaigns,
    ...instagramCampaigns.documents,
    ...allFacebookCampaigns,
  ];

  return (
    <>
      <Modal
        style={{
          marginTop: "10%",
          marginLeft: "30%",
          width: "60%",
          height: "auto",
        }}
        open={archivedOpen}
        onClose={() => setArchivedOpen(false)}
      >
        <Archived setOpen={setArchivedOpen} />
      </Modal>
      {!isLoading ? (
        <>
          {allCampaigns.length ? (
            <div className={classes.root}>
              <PageTitle title="Organic Campaigns" />
              <CTAContainer>
                <div>
                  <p>Ready to start a new campaign?</p>
                  <p>
                    Use the button on the right side to bring your marketing
                    success to life.
                  </p>
                </div>
                <SendButton
                  onClick={() => navigate("/campaigns/organic/create")}
                  disableRipple
                >
                  Create new campaign
                </SendButton>
              </CTAContainer>
              <div className={classes.selectors}>
                <SendButton
                  style={{
                    width: "auto",
                    height: "50px",
                    padding: 10,
                  }}
                  onClick={() => setArchivedOpen(true)}
                  disableRipple
                >
                  Un-archive campaigns
                </SendButton>
                <div className={classes.selectItems}>
                  <CampaignTypes
                    selected={selected}
                    setSelected={setSelected}
                    selectedArr={selectedArr}
                    setSelectedArr={setSelectedArr}
                  />
                </div>
              </div>
              <>
                {twitterFilteredData.length ||
                facebookFilteredData.length ||
                instagramFilteredData.length ? (
                  <div id="admin-table" style={{ marginTop: 24 }}>
                    <TableContainer
                      component={Paper}
                      className={classes.tableContainer}
                    >
                      <Table
                        className={classes.table}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow className={classes.tableHeader}>
                            <TableCell
                              className={classes.tableCell}
                              align="left"
                              style={{ padding: "0px 0px 0px 17px" }}
                              colSpan={7}
                            >
                              {twitterFilteredData.length +
                                facebookFilteredData.length +
                                instagramFilteredData.length}{" "}
                              campaigns
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <BeAmplified
                          campaigns={{
                            twitter: twitterFilteredData,
                            instagram: instagramFilteredData,
                            facebook: facebookFilteredData,
                          }}
                        />
                      </Table>
                    </TableContainer>
                  </div>
                ) : (
                  <div style={{ marginTop: 20 }}>
                    <NoPosts title="There are no campaigns for the selected client" />
                  </div>
                )}
              </>
            </div>
          ) : (
            <EmptyCampaigns />
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default BeAmplifiedPage;
