import React, { FunctionComponent } from "react";
import { DateTime } from "luxon";
import { PlayArrow as PlayArrowIcon } from "@material-ui/icons";
import cuid from "cuid";
import Linkify from "react-linkify";

import { useStylesV2 } from "../stylesV2";
import { getScreenshot } from "utils/getScreenshot";
import { componentDecorator } from "components/BePlanned/Notes/Note";
import MediaItemProfileSection from "./MediaItemProfileSection";
import MediaItemImageSection from "./MediaItemImageSection";
import MediaItemDataCardVideo from "./MediaItemDataCardVideo";

interface MediaItem {
  post: any;
  role: string;
  igMetadata?: any;
  fbMetadata?: any;
  avatar: any;
}

const MediaItemInstagramVideoV2: FunctionComponent<MediaItem> = ({
  post,
  role,
  igMetadata,
  fbMetadata,
  avatar: avatarData,
}) => {
  const classes = useStylesV2({});
  const uuid: string = cuid();

  const getDate = () =>
    role === "twitter"
      ? post.created_at !== "·"
        ? !!post.created_at.split("·")[1] && !!post.created_at.split("·")[0]
          ? post.created_at.split("·")[1] +
            " at " +
            post.created_at.split("·")[0]
          : null
        : null
      : DateTime.fromISO(
          role === "facebook"
            ? post.metadata.created_time
            : post.metadata.timestamp
        ).toFormat("dd LLL, yyyy 'at' t");

  const generateScreenshot = () => {
    const mediaContainer: any = document.getElementById(
      `mediaContainer-${uuid}`
    );
    mediaContainer.style.border = "solid 1px #D8D8D8";

    getScreenshot(
      `${role}-${uuid}`,
      `${role[0].toUpperCase() + role.slice(1)}_Top_3_${getDate()}`
    );

    mediaContainer.style.border = "none";
  };

  const setAvatar = (role: any, post: any) => {
    let avatar: any = null;

    if (role !== "twitter" && role !== "tikTok") {
      avatar = role === "facebook" ? fbMetadata.avatar : igMetadata.avatar;
    } else {
      avatar = post.metadata.avatar;
    }

    avatar = avatarData.avatar;
    return avatar;
  };

  const shortenSentence = (sentence: string, maxLength: number) => {
    if (sentence?.length <= maxLength) {
      return sentence;
    }

    const shortenedSentence = sentence
      ? sentence.substring(0, maxLength - 3) + "..."
      : "";
    return shortenedSentence;
  };

  return (
    <div id={`${role}-${uuid}`} className={classes.mediaTop}>
      <div
        style={{
          borderRadius: "15px",
          padding: "20px",
          height: "auto",
          background: "#FFFFFF",
        }}
        id={`mediaContainer-${uuid}`}
      >
        <div
          id="body"
          style={{
            margin: "11.5px 0px 0px 0px",
          }}
        >
          <div style={{ minHeight: "192px" }}>
            <MediaItemDataCardVideo
              role={role}
              likes={post?.metadata?.like_count || 0}
              comments={post?.metadata?.comments_count || 0}
            />

            <MediaItemProfileSection
              avatar={setAvatar(role, post)}
              date={getDate()}
              accountName={
                role === "twitter" || role === "tikTok"
                  ? post.metadata.accountName
                  : role === "facebook"
                  ? fbMetadata.name
                  : igMetadata.name
              }
              link={
                role === "twitter"
                  ? post.url
                  : role === "facebook"
                  ? post.metadata.permalink_url
                  : post.metadata.permalink
              }
              generateScreenshot={generateScreenshot}
            />

            <div
              style={{
                width: "100%",
                // display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid #f2f2f2",
              }}
            >
              {role !== "tikTok" && (
                <>
                  {role === "twitter" ? (
                    !!post.imageUrl && (
                      <MediaItemImageSection imageUrl={post.imageUrl} />
                    )
                  ) : role === "facebook" ? (
                    post.metadata.full_picture &&
                    post.metadata.full_picture !== "" && (
                      <MediaItemImageSection
                        imageUrl={post.metadata.full_picture}
                      />
                    )
                  ) : (
                    <MediaItemImageSection
                      imageUrl={
                        post.metadata.media_type === "VIDEO"
                          ? post.metadata.thumbnail_url
                          : post.metadata.media_url
                      }
                    />
                  )}
                </>
              )}
              {role === "tikTok" && (
                <a
                  href={post.metadata.permalink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.playButton}
                >
                  <PlayArrowIcon style={{ width: "70%", height: "173px" }} />
                </a>
              )}
            </div>
            <div className={classes.postText}>
              <Linkify
                componentDecorator={componentDecorator}
                style={{ wordBreak: "break-word" }}
              >
                {role === "twitter"
                  ? `${shortenSentence(post.text, 80) || ""}`
                  : role === "facebook"
                  ? `${shortenSentence(post.metadata.message, 80) || ""}`
                  : `${shortenSentence(post.metadata.caption, 80) || ""}`}
              </Linkify>
            </div>
          </div>
          <div
            className={classes.flexContainer}
            style={{
              marginTop: "20px",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default MediaItemInstagramVideoV2;
