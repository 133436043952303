import React, { FC } from "react";
import styled from "styled-components";

import { PageType } from "types/PageType";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 75px;
  #body {
    width: 80%;
    margin-bottom: 65px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    background: #fff;
    padding: 30px;
  }
  #title {
    margin-bottom: 30px;
    font-family: AvenirNext-Medium;
    font-size: 24px;
  }
  h3 {
    margin-top: 45px;
    font-size: 16px;
    font-family: AvenirNext-Medium;
    font-weight: 400;
  }
  p {
    color: rgba(0, 0, 0, 0.87);
  }
`;

const Privacy: FC<PageType> = () => {
  return (
    <div style={{ background: "#f2f2f2" }}>
      <Container>
        <div id="body">
          <div id="title">Be-Hookd Privacy Policy and Terms & Conditions</div>
          <p>
            We are committed to safeguarding your privacy online and have
            developed this privacy policy to deal with issues which may concern
            you. Please read this policy to understand how your personal
            information will be treated. This policy may change from time to
            time so please check it periodically.
          </p>

          <h3>Personal information</h3>
          <p>
            The details which you provide about yourself and any information
            which identifies you (such as your name, address, email address)
            (“personal information”) will be collected by us when you register
            to use the site, when you submit such information directly by
            filling in an online form, and when you browse, where cookies may be
            used.
          </p>
          <p>
            Use of personal information This information will be held on our
            system and used only for setting up and managing your website
            account, and to make available to you all information relating to
            us.
          </p>
          <h3>Other Data Collection</h3>
          <p>
            If you have given our system access to your social media accounts
            data (e.g. your TikTok account) - we will automatically fetch the
            analytics data available officially through those social media
            platforms for your account. This is for the purpose of showing you
            an analytics dashboard across all of your social media accounts.
          </p>
          <p>
            Note that ONLY you will have access to YOUR social media accounts'
            data. The other users will NOT have access to YOUR social media
            accounts data.
          </p>
          <p>
            We store your data securely on Google Cloud servers with encryption
            and strict security rules.
          </p>
          <h3>Protection of information</h3>
          <p>
            We will not pass on your personal information to third parties
            unless we are required by law or by a regulatory or government
            authority to do so. We do not normally link ip addresses to anything
            personally identifiable, which means that you remain anonymous even
            though we include your ip address in our aggregate information.
            However, we can and will attempt to use ip addresses to identify a
            user when we feel it is necessary to enforce compliance with our
            terms and conditions, or to protect our services and other users.
          </p>
          <h3>Cookies</h3>
          <p>
            Cookies are small pieces of information that are stored by your
            browser on your computer’s hard drive. They make it possible for us
            to identify registered users without needing to ask for registration
            details every time you access our site. Using cookies makes
            accessing our site more convenient as you don’t need to remember
            your password each time you enter.
          </p>

          <h3>Protecting information</h3>
          <p>
            We take every precaution to protect your information. To this end
            all personal information stored by us is kept on a server in a
            secure environment. Only employees and approved
            contractors/developers who need the information to perform a
            specific job are granted access to personally identifiable
            information. Regular security reviews are held by us to ensure that
            the site remains safe and secure for your
          </p>
          <p>
            protection. We cannot guarantee the security of data you disclose
            online and you accept that the internet is not completely secure and
            agree that we shall not be liable for unauthorised use, distribution
            or destruction of your personal information.
          </p>

          <h3>Updating personal information</h3>
          <p>
            If your personal information changes or if you no longer wish to
            subscribe to the service, we will endeavour to correct, update or
            remove the personal information provided to us as speedily as
            possible.
          </p>
          <h3>Under 18</h3>
          <p>
            If you are under 18, please ensure that you obtain your
            parent/guardian’s consent beforehand whenever you provide personal
            information to the website. Users without such consent are not
            allowed to provide us with personal information.
          </p>

          <h3>Governing law</h3>
          <p>
            This privacy policy forms part of our website terms. Any dispute or
            claim arising out of this policy and the use of your personal
            information will be governed by English law and the courts of
            England and wales have exclusive jurisdiction to resolve any such
            dispute or claim.
          </p>
        </div>
      </Container>
    </div>
  );
};

export default Privacy;
