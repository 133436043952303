import { Button } from "@material-ui/core";
import Axios from "axios";
import React from "react";
const FacebookOAuthPersonal = ({ userInfo }) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const { email } = userInfo || {};
  const styles: any = {
    borderRadius: "10px",
    color: "#000000",
    fontSize: "14px",
    fontWeight: "bold",
    borderColor: "#000000",
  };

  const disabledButtonStyles = {
    ...styles,
    opacity: 0.5,
    cursor: "not-allowed !important",
  };
  const initiateOAuth = async () => {
    try {
      const { email } = userInfo || {};
      setIsLoading(true);
      const { data } = await Axios.get(
        `${process.env.REACT_APP_FUNC_BASE_URL}facebook-clientOauth?emailId=${email}&type=personal`
      );
      window.location.href = data.authUrl;
    } catch (error) {
      setIsLoading(false);
      throw error;
    } finally {
    }
  };

  const checkFB = () => {
    if (userInfo?.facebook?.accessToken || userInfo?.facebook?.username) {
      return true;
    }
    return false;
  };

  return (
    <>
      {!userInfo?.facebook?.accessToken && !userInfo?.facebook?.username && (
        <Button
          variant="outlined"
          onClick={async () => {
            setIsLoading(true);
            await initiateOAuth();
          }}
          style={!email || isLoading ? disabledButtonStyles : styles}
          disabled={!email || isLoading}
        >
          {isLoading ? "Connecting..." : "Connect user"}
        </Button>
      )}
      {checkFB() && (
        <Button
          variant="outlined"
          onClick={async () => {
            setIsLoading(true);
            await initiateOAuth();
          }}
          style={!email || isLoading ? disabledButtonStyles : styles}
          disabled={!email || isLoading}
        >
          {isLoading ? "Connecting..." : "Connect new user"}
        </Button>
      )}
    </>
  );
};

export default FacebookOAuthPersonal;
