import styled from "styled-components";
import Vimeo from "@u-wave/react-vimeo";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

//unless something is specific to a given component, let's add it to this file
//or another modularized file/dir. most of these wrappers are the same
export const PageTitle = styled.div`
  height: auto;
  font-style: normal;
  font-size: 30px;
  line-height: 25px;
  color: #101010;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 40px auto;
  font-family: "Avenir Black", sans-serif;
  font-weight: 900;
  #selector {
    display: flex;
  }
`;

export const Root = styled.div`
  overflow: hidden;
  padding-bottom: 20px;
  @media screen and (max-width: 768px) {
    padding: 0 10px;
  }
`;

export const Container = styled.div`
  /* max-width: 935px; */
`;
export const Image = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
`;
export const Video = styled.video`
  object-fit: cover;
  height: 100%;
  width: 100%;
`;
export const VimeoPlayer = styled(Vimeo)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    position: unset !important;
  }
`;
export const YouTube = styled.iframe`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    position: unset !important;
  }
`;
export const PostContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
`;
export const EmptyData = styled.div`
  font-size: 18px;
  line-height: 21px;
  color: #313131;
  font-family: AvenirNext;
  padding-top: 73px;
`;
export const SendButton = styled(Button)`
  width: 137px;
  height: 30px;
  font-family: "Avenir Black", sans-serif;
  font-weight: 900;
  font-size: 15px;
  line-height: 22px;
  color: #ffffff !important;
  text-transform: capitalize;
  mix-blend-mode: normal;
  background: ${(props) =>
    props.disabled ? "#c4c4c4 !important" : "#000000 !important"};
  border-radius: 10px;
  &:hover {
    opacity: 0.8;
    background: ${(props) =>
      props.disabled ? "#c4c4c4 !important" : "#000000 !important"};
  }
`;
export const CancelButton = styled(SendButton)`
  background: #abadad;
  &:hover {
    background: #abadad;
  }
`;

export const StatusButton = styled(CancelButton)`
  background: #abadad;
  &:hover {
    background: #abadad;
  }
  width: 100%;
  height: 45px;
`;
export const ControlLabel = styled(Box)`
  font-size: 15px;
  line-height: 22px;
  font-family: "Avenir Black", sans-serif;
  font-weight: 900;
  color: #000000;
  margin-right: 40.5px;
`;

export const Paper = styled.div`
  padding: 0px 32px 0px;
  @media screen and (max-width: 430px) {
    padding: 0px 10px 0px;
    & > div:first-of-type {
      margin: 0px -10px;
      padding: 0 5px;
    }
  }
`;

export const PostPaper = styled.div`
  padding: 0px 32px 0px;
  @media screen and (max-width: 430px) {
    padding: 0px 10px 0px;
    & > div:first-of-type {
      margin: 0px -10px;
      padding: 0 5px;
    }
  }
`;

export const Toast = styled.div`
  .toast-progressbar {
    background-color: #fff !important;
  }
`;
