export const facebookExtractor = async ({
  id,
  spreadsheetUrl,
  sessionCookie,
  sessionCookieXS,
  campaignName,
  username,
  membersCount,
}) => {
  if (!process.env.REACT_APP_FUNC_BASE_URL) {
    console.log("Invalid url");
    return;
  }
  const rawResponse = await fetch(
    `${process.env.REACT_APP_FUNC_BASE_URL}facebook-groupScrapper`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id,
        spreadsheetUrl,
        sessionCookie,
        sessionCookieXs: sessionCookieXS,
        campaignName,
        username,
        membersCount,
      }),
    }
  );
  const result = await rawResponse.json();
  return result;
};
