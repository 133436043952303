import React, { FC, useState } from "react";
import {
  Modal,
  Backdrop,
  Fade,
  Checkbox,
  CircularProgress,
} from "@material-ui/core";
import { Close, CheckCircle, RadioButtonUnchecked } from "@material-ui/icons";
import { fromEvent, Observable } from "rxjs";

import { useUser } from "UserContext";

import { useStyles } from "./styles";
import { firebase } from "../../firebase-tools/firebase";
import { updateUserPageVisit } from "../../utils/userActivity";
import { SendButton, CancelButton } from "components/styled/Common";

interface IClientAccounts {
  toggleModal: (param: boolean) => void;
}

const ClientAccounts: FC<IClientAccounts> = ({ toggleModal }) => {
  const classes = useStyles({});

  const [isOpen, setIsOpen] = useState(true);
  let localClients: any = localStorage.getItem("clients");

  localClients = localClients === null ? [] : JSON.parse(localClients);

  localClients = localClients.map((cli) => cli[0]);
  const [clients]: any = useState(localClients ? localClients : "");
  const [authUser, setUser] = useState(clients[0]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [, setCurrentUser] = useUser() as any;
  const [agent, setAgent]: any = useState("");

  const [mobileMenu, switchMobileMenu] = useState(
    !(document.documentElement.offsetWidth > 720)
  );

  const resize$: Observable<Event> = fromEvent(window, "resize");

  resize$.subscribe((res: any) => {
    if (res && res.currentTarget) {
      switchMobileMenu(!(res.currentTarget.innerWidth > 720));
    }
  });

  firebase.auth().onAuthStateChanged(function (user) {
    if (user) {
      setAgent(user);
    }
  });

  let selectedUser = localStorage.getItem("kit-authUser");

  if (selectedUser !== null && count === 0) {
    selectedUser = JSON.parse(selectedUser);

    setCount(1);
    setUser(selectedUser);
  }

  const handleIsOpen = (): void => {
    return toggleModal(false);
  };

  const handleChange = (item) => {
    setUser(item);
    localStorage.setItem("kit-authUser", JSON.stringify(item));
  };

  const handleNext = () => {
    setLoading(true);
    setCurrentUser(authUser);
    toggleModal(false);
    setLoading(false);
    agent.id && authUser.id && updateUserPageVisit(authUser, agent);
    window.location.reload();
  };

  const handleClose = (e) => {
    if (e.key === "Escape") {
      setIsOpen(false);
    }
  };

  const isDeactivated = (client: any) => {
    const secEmails = client.secondaryEmails;
    const agent = firebase.auth().currentUser;
    let deactivated = false;
    for (const item of secEmails) {
      if (agent?.email === item.email && !item.isActivated) {
        deactivated = true;
      }
    }
    return deactivated;
  };

  return (
    <div
      style={{
        display: "flex",
        zIndex: 2000,
      }}
    >
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <div className={classes.container}>
            <div className={classes.paper}>
              <div className={classes.top}>
                <div className={classes.print}>Choose Account</div>
                <Close className={classes.icon} onClick={handleIsOpen} />
              </div>
              <div style={{ fontSize: 15 }}>
                {`${
                  mobileMenu
                    ? "Please choose an account:"
                    : "Please choose the account that you want to see."
                }`}
              </div>
              <div className={classes.content}>
                {clients.map((item, i) => {
                  return (
                    <div key={`key-${i}`}>
                      <Checkbox
                        color="primary"
                        className={classes.checkbox}
                        checkedIcon={
                          <CheckCircle className={classes.checkboxIcon} />
                        }
                        icon={
                          <RadioButtonUnchecked
                            className={classes.checkboxIcon}
                          />
                        }
                        checked={
                          authUser.id === item.id && !isDeactivated(item)
                        }
                        onChange={() => handleChange(item)}
                        disabled={isDeactivated(item)}
                      />
                      <span>{`${item.firstName} ${item.lastName}`} </span>
                      <span style={{ color: "red" }}>
                        {isDeactivated(item) && "[deactivated]"}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={classes.line} />
            <div className={classes.bottom}>
              <div>
                <CancelButton onClick={handleIsOpen}>cancel</CancelButton>
                <SendButton
                  className={classes.printMode}
                  onClick={() => handleNext()}
                >
                  {loading ? (
                    <CircularProgress size={30} color="inherit" />
                  ) : (
                    "Next"
                  )}
                </SendButton>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default ClientAccounts;
