/**
 * @prettier
 */
import React, { FunctionComponent, Fragment } from "react";
import { LinearProgress } from "@material-ui/core";

import { PageType } from "types/PageType";
import { useAuthHook, AuthHookType } from "hooks/AuthHook";
import AppStyles from "components/styled/App";

const App: FunctionComponent<PageType> = ({ location, children }: PageType) => {
  const hook = useAuthHook({ location } as AuthHookType);

  const isAllowRender = hook.isAllowRender;
  return (
    <Fragment>
      {isAllowRender ? <AppStyles>{children}</AppStyles> : <LinearProgress />}
    </Fragment>
  );
};

export default App;
