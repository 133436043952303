import React, { FunctionComponent, useState, useEffect, memo } from "react";
import {
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Typography,
  Grid,
  Tooltip,
  Box,
} from "@material-ui/core";
import { Public } from "@material-ui/icons";
import { DateTime } from "luxon";
import moment from "moment";
import cuid from "cuid";
import linkifyHtml from "linkifyjs/html";
import userIcon from "statics/media/name.svg";
import { useStyles } from "containers/BePlanned/styles";
import Carousel from "components/Common/Carousel";
import facebook from "statics/media/fb-vector.png";
import twitter from "statics/media/twitter-vector.png";
import instagram from "statics/media/instagram-vector.png";
import youtubeshort from "statics/media/youtube-short.png";
import pinterest from "statics/media/pinterest.png";
import snapchat from "statics/media/snapchat.png";
import linkedin from "statics/media/linkedin.png";
import tiktok from "statics/media/tiktok.png";
import youtube from "statics/media/youtube.png";
import triller from "statics/media/triller.png";
import tumblr from "statics/media/tumblr.png";
import defaultIcon from "statics/media/universal-2.png";
import playButton from "statics/media/play-button.png";
import { getScreenshot } from "utils/getScreenshot";
import DownloadActionsV2 from "components/Common/DownloadActionsV2/DownloadActionsV2";
import EventIcon, {
  getBackground,
} from "components/BePlannedMonthly/EventIcon";
import Image from "components/Common/Image/Image";
import { FieldTitle } from "components/styled/FieldTitle";

const channelsList = [
  "facebook",
  "instagram",
  "twitter",
  "tiktok",
  "youtube",
  "linkedin",
  "youtubeshort",
];

interface SocialPostProps {
  post: any;
  user: any;
}

const SocialPostV2: FunctionComponent<SocialPostProps> = ({ post, user }) => {
  const {
    publicIcon,
    avatar,
    AvatarIcon,
    AvatarSvg,
    cardContent,
    img,
  } = useStyles({});
  const classes = useStyles({});

  const uuid: string = cuid();

  const getPostDate = (dateInSeconds) => {
    let day: any = DateTime.fromSeconds(dateInSeconds).toJSDate();

    day = moment(day).format("ddd").split(" ")[0];

    return DateTime.fromSeconds(dateInSeconds).toFormat(
      `'${day},' dd LLL yyyy 'at' HH:mm`
    );
  };
  const icons = {
    facebook,
    twitter,
    instagram,
    pinterest,
    snapchat,
    linkedin,
    youtubeshort,
    tiktok,
    triller,
    tumblr,
    youtube,
  };

  const getImages = () => {
    let images;
    if (typeof post?.image_url === "string") {
      if (post?.image_url !== "") {
        images = [post?.image_url];
      } else {
        images = [];
      }
    } else {
      images = post?.image_url;
    }
    return images;
  };

  const [images, setImages] = useState(getImages());
  useEffect(() => {
    const updatedImages = getImages();
    setImages(updatedImages);
    //eslint-disable-next-line
  }, [post?.image_url]);

  const displayCategory = (post, channel) => {
    if (post?.category) {
      const valid = Object.values(post?.category).every((ct) =>
        Array.isArray(ct)
      );
      const custom = Array.isArray(post?.category?.custom);

      if (valid && channelsList.includes(channel)) {
        return (
          <>
            {post?.category[channel.toLowerCase()] &&
              post?.category[channel.toLowerCase()]
                .filter((catOption) => catOption !== "")
                .map((catOption, i) => (
                  <div style={{ textAlign: "center" }} key={`category-${i}`}>
                    {catOption.split(" ").slice(1).join("")}
                  </div>
                ))}
          </>
        );
      } else if (custom) {
        return (
          <>
            {post?.category?.custom &&
              post?.category?.custom
                .filter((catOption) => catOption !== "")
                .map((catOption, i) => (
                  <div style={{ textAlign: "center" }} key={`category-${i}`}>
                    {catOption.split(" ")[0]}
                  </div>
                ))}
          </>
        );
      }
    }
  };

  const generateScreenshot = async (post: any, images: any, uuid: string) => {
    const socialPost: any = document.getElementById(`post-container-${uuid}`);
    socialPost.style.border = "solid 1px #D8D8D8";

    const carousel: any = document.getElementById("carousel");
    const staticImage: any = document.getElementById("image-placeholder");
    if (!!post?.video_url?.length && !!images?.length) {
      carousel.style.display = "none";
      staticImage.style.display = "flex";
    }

    const name = `Be-Planned_date_${getPostDate(post?.date.seconds)}`;

    await getScreenshot(`social-post-${uuid}`, name);

    if (!!post?.video_url?.length && !!images?.length) {
      carousel.style.display = "block";
      staticImage.style.display = "none";
    }
    socialPost.style.border = "none";
  };

  const formatGraphicsBrief = () => {
    let brief = post?.graphicsBrief || "";
    brief = brief.replaceAll("<div>", "");

    let content: any[] = brief.split("</div>");
    let index = 0;
    let arr = [""];

    for (let i = 0; i < content.length; i++) {
      const element = content[i];
      if (i !== index && element?.includes(":")) {
        index = i;
        arr.push(element);
      }
      const arrIndex = arr.length - 1;

      if (i === index && element?.includes(":")) {
        arr[arrIndex] = element;
      } else {
        arr[arrIndex] = arr[arrIndex].concat(" ", element);
      }
    }

    content = arr.map((val) => {
      const br = val.split(":");

      if (br.length > 1 && br[1]?.trim() !== "") {
        const keyWord = br.shift();
        return `<div>${keyWord}: ${br.join("")}</div>`;
      }
      return null;
    });

    const contentLen = content.join("").length;

    if (content.filter((val) => val).length) {
      return contentLen ? content.join("") : post.graphicsBrief || "";
    } else {
      return false;
    }
  };

  const postChannel = {
    instagram: 1,
    facebook: 2,
    twitter: 3,
    tiktok: 4,
    youtube: 5,
    default: Number.MAX_VALUE,
  };

  const postChannels = post?.channels?.sort(
    (a, b) =>
      (postChannel[a] || postChannel.default) -
        (postChannel[b] || postChannel.default) ||
      a > b ||
      -(a < b)
  );
  return (
    <div id={`social-post-${uuid}`}>
      <Grid
        container
        className={classes.postContainer}
        id={`post-container-${uuid}`}
      >
        <span
          style={{
            backgroundColor: getBackground(post.internal_status_weekly),
            padding: "0.7%",
            height: "auto",
          }}
        ></span>
        <Card
          style={{
            width: "98.4%",
          }}
        >
          <CardContent>
            <Grid xs={12} md={12} container>
              <Grid item xs={12} md={12} container>
                <Grid container>
                  <Grid item xs={12} md={7}>
                    <CardHeader
                      avatar={
                        user.avatarUrl !== "" ? (
                          <Avatar className={avatar} src={user.avatarUrl} />
                        ) : (
                          <Avatar
                            classes={{
                              root: AvatarSvg,
                              img: AvatarIcon,
                            }}
                            src={userIcon}
                          />
                        )
                      }
                      title={`${user.firstName} ${user.lastName}`}
                      subheader={
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span style={{ marginRight: 5, fontSize: "13px" }}>
                            {getPostDate(post.date.seconds)}
                          </span>
                          <Public className={publicIcon} id="publicIcon" />
                        </div>
                      }
                      classes={{
                        subheader: cardContent,
                        title: cardContent,
                      }}
                    />

                    <div
                      className={classes.postIcons}
                      style={{
                        margin: "10px",
                      }}
                    >
                      {user[post?.platform?.toLowerCase()]?.url ? (
                        post?.platform ? (
                          <a
                            href={user[post.platform.toLowerCase()].url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Image
                              className={img}
                              src={
                                icons[post.platform.toLowerCase()] ||
                                defaultIcon
                              }
                              alt=""
                            />
                          </a>
                        ) : (
                          post?.channels.map((channel, i) => (
                            <a
                              key={`${i}-channel`}
                              href={user[channel.toLowerCase()].url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Image
                                className={img}
                                src={
                                  icons[channel.toLowerCase()] || defaultIcon
                                }
                                alt=""
                              />
                            </a>
                          ))
                        )
                      ) : post?.platform ? (
                        <Image
                          className={img}
                          src={
                            icons[post.platform.toLowerCase()] || defaultIcon
                          }
                          alt=""
                        />
                      ) : (
                        postChannels.map((channel, i) => (
                          <Tooltip
                            title={displayCategory(post, channel) as any}
                            arrow
                            classes={{ tooltip: classes.customTooltip }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                              key={`post-${i}`}
                            >
                              <a
                                href={
                                  user[channel.toLowerCase()]
                                    ? user[channel.toLowerCase()].url
                                    : ""
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Image
                                  className={img}
                                  style={{ height: "30px", width: "30px" }}
                                  src={
                                    icons[channel.toLowerCase()] || defaultIcon
                                  }
                                  alt=""
                                />
                              </a>
                            </div>
                          </Tooltip>
                        ))
                      )}
                    </div>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    md={4}
                    style={{
                      paddingTop: "12px",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    container
                  >
                    <Grid item xs={2} md={2}>
                      <DownloadActionsV2
                        generateScreenshot={() =>
                          generateScreenshot(post, images, uuid)
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container>
                  <FieldTitle
                    style={{
                      paddingLeft: "calc(30px - 18px)",
                      fontWeight: 900,
                      fontSize: "16px",
                      fontFamily: "Avenir",
                      textTransform: "uppercase",
                    }}
                  >
                    {post.title}
                  </FieldTitle>

                  {!!post.video_url?.length || !!images?.length ? (
                    <>
                      <div
                        id={post.video_url && !images.length ? "carousel" : ""}
                      ></div>
                      <div
                        className={classes.placeholderImg}
                        id={
                          post.video_url && !images.length
                            ? "image-placeholder"
                            : ""
                        }
                      >
                        <img src={playButton} alt="Screenshot Placeholder" />
                      </div>
                    </>
                  ) : (
                    <>
                      {!!post.graphicsBrief?.length && formatGraphicsBrief() && (
                        <Box
                          className={classes.contentBriefContainer}
                          id="contentBriefContainer"
                        >
                          <Typography className={classes.briefTitle}>
                            Creative Content Brief
                          </Typography>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: formatGraphicsBrief(),
                            }}
                          />
                        </Box>
                      )}
                    </>
                  )}

                  {post.body && (
                    <div
                      className={cardContent}
                      style={{ padding: "12px", width: "100%" }}
                    >
                      <span
                        dangerouslySetInnerHTML={{
                          __html: linkifyHtml(
                            post.body?.trim() !== ""
                              ? post.body
                              : post.title || " ",
                            {
                              target: {
                                url: "_blank",
                              },
                            }
                          ),
                        }}
                      />
                    </div>
                  )}

                  <br />
                  {post?.tags?.length > 0 && (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        padding: "12px",
                      }}
                    >
                      {post?.tags?.length > 0 &&
                        post.tags.map((val, i) => (
                          <span
                            key={i}
                            style={{
                              fontSize: "15px",
                              fontFamily: "Avenir",
                              color: "#000000DE",
                              marginRight: 10,
                            }}
                          >
                            #{`${val} `}{" "}
                          </span>
                        ))}
                    </div>
                  )}
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{
                    marginBottom: "20px",
                    paddingBottom: "20px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  container
                >
                  <Carousel
                    images={images.length > 1 ? images : images}
                    video={post.video_url}
                  />
                </Grid>
                <Grid container style={{ marginTop: "20px", padding: "12px" }}>
                  {/* <Grid xs={6} md={6}>
                    <p
                      style={{
                        fontWeight: 900,
                        fontSize: "15px",
                        color: "#000000",
                        fontFamily: "Avenir",
                      }}
                    >
                      Visible to Client
                    </p>
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "15px",
                        color: "#000000",
                        width: "38px",
                        height: "24px",
                        top: "20px",
                        left: "20px",
                        padding: "4px 8px 4px 8px",
                        borderRadius: "8px",
                        gap: "8px",
                        backgroundColor:
                          post.is_visible_to_client === "Yes"
                            ? "#AEDBAD"
                            : "#EAA2AF",
                      }}
                    >
                      {post.is_visible_to_client ?? "No"}
                    </p>
                  </Grid> */}

                  <Grid xs={6} md={6}>
                    <p
                      style={{
                        fontWeight: 900,
                        fontSize: "15px",
                        color: "#000000",
                        fontFamily: "Avenir",
                      }}
                    >
                      Post Approval
                    </p>

                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "15px",
                        color: "#000000",
                      }}
                    >
                      <span
                        style={{
                          paddingRight: 10,
                        }}
                      >
                        {/* {getIcons(post.internal_status_weekly)} */}
                        {
                          <EventIcon
                            category={post.internal_status_weekly}
                            width={24}
                            height={24}
                            borderRadius={4}
                          />
                        }
                      </span>
                      {post.internal_status_weekly}
                    </p>
                  </Grid>
                </Grid>
              </Grid>{" "}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </div>
  );
};

export default memo(SocialPostV2);
