import React, { FunctionComponent, useEffect } from "react";
import { Card, CardHeader, Avatar, CardContent, Grid } from "@material-ui/core";

import userIcon from "statics/media/name.svg";
import { useStyles } from "../styles";
import EventIcon, {
  getBackground,
} from "components/BePlannedMonthly/EventIcon";
import { FieldTitle } from "components/styled/FieldTitle";
import { getPostDate } from "../helpers/socialPost";
import { SocialPostContainer } from "components/styled/SocialPost";

interface ImageWithTooltipProps {
  imagePath: string;
  tooltipText: string;
}

interface ISocialPost {
  event: any;
  user: any;
  role: string;
  uuid: string;
}
export const channelsList = [
  "instagram",
  "facebook",
  "twitter",
  "tiktok",
  "youtubeshort",
  "youtube",
  "discord",
  "snapchat",
  "threads",
];

const Event: FunctionComponent<ISocialPost> = ({ event, user, role, uuid }) => {
  const { avatar, AvatarIcon, AvatarSvg, cardContent } = useStyles({});

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getImages = () => {
    let images;
    if (typeof event.image_url === "string") {
      if (event.image_url !== "") {
        images = [event.image_url];
      } else {
        images = [];
      }
    } else {
      images = event.image_url;
    }
    return images;
  };

  useEffect(() => {
    // const updatedImages = getImages();
    // setImages(updatedImages);
    //eslint-disable-next-line
  }, [getImages, event, event.image_url]);

  return (
    <SocialPostContainer id="content-post">
      <Grid container>
        <Grid container>
          <div
            style={{
              backgroundColor: getBackground(event.event_type),
              padding: "0.7%",
              height: "auto",
            }}
          ></div>
          <Card
            style={{
              width: "98.4%",
            }}
          >
            <CardContent id={`post-${uuid}`}>
              <Grid xs={12} md={12} container>
                <Grid item xs={12} md={12} container>
                  <Grid container>
                    <Grid item xs={12} md={7}>
                      <CardHeader
                        avatar={
                          user.avatarUrl !== "" ? (
                            <Avatar className={avatar} src={user.avatarUrl} />
                          ) : (
                            <Avatar
                              classes={{
                                root: AvatarSvg,
                                img: AvatarIcon,
                              }}
                              src={userIcon}
                            />
                          )
                        }
                        title={`${user.firstName} ${user.lastName}`}
                        classes={{
                          subheader: cardContent,
                          title: cardContent,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={5}
                      style={{
                        paddingTop: "12px",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "right",
                      }}
                      container
                    ></Grid>
                  </Grid>

                  <Grid container>
                    <FieldTitle
                      style={{
                        paddingLeft: "calc(30px - 18px)",
                        fontWeight: 900,
                        fontSize: "16px",
                        fontFamily: "Avenir",
                        textTransform: "uppercase",
                      }}
                    >
                      {event.subject}
                    </FieldTitle>

                    <Grid
                      container
                      style={{ marginTop: "20px", padding: "12px" }}
                    >
                      <Grid xs={6} md={6}>
                        <p
                          style={{
                            fontWeight: 900,
                            fontSize: "15px",
                            color: "#000000",
                            fontFamily: "Avenir",
                          }}
                        >
                          Event Date
                        </p>
                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "15px",
                            color: "#000000",
                          }}
                        >
                          {getPostDate(event.start_date.seconds)}
                        </p>
                      </Grid>

                      <Grid xs={6} md={6}>
                        <p
                          style={{
                            fontWeight: 900,
                            fontSize: "15px",
                            color: "#000000",
                            fontFamily: "Avenir",
                          }}
                        >
                          Event type
                        </p>

                        <p
                          style={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "15px",
                            color: "#000000",
                          }}
                        >
                          <span
                            style={{
                              paddingRight: 10,
                            }}
                          >
                            {/* {getIcons(event.event_type)} */}
                            {
                              <EventIcon
                                category={event.event_type}
                                width={24}
                                height={24}
                                borderRadius={4}
                              />
                            }
                          </span>
                          {event.event_type}
                        </p>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>{" "}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </SocialPostContainer>
  );
};

export default Event;
