export const getChartsData = (campaign) => {
  const tweets = campaign.tweets?.map((tweet) => tweet.count || 0) || [];
  const users = campaign.users?.map((user) => user.count || 0) || [];
  const follows = campaign.follows?.map((follow) => follow.count || 0) || [];

  let dates = [];
  dates =
    campaign.tweets?.map((tweet) => new Date(tweet.date.toDate()).getTime()) ||
    [];
  const max = tweets.length;
  if (users.length > max) {
    dates =
      campaign.users?.map((user) => new Date(user.date.toDate()).getTime()) ||
      [];
  }
  if (follows.length > max) {
    dates =
      campaign.follows?.map((follow) =>
        new Date(follow.date.toDate()).getTime()
      ) || [];
  }

  return { tweets, users, follows, dates };
};
