/**
 * @prettier
 */
import { firestore } from "./firebase";

export class DB {
  private db: any;

  constructor() {
    this.db = firestore;
  }

  public get(collectionName: string): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      this.db
        .collection(collectionName)
        .get()
        .then((query: any) => {
          return resolve(query.docs.map((doc: any) => doc.data()));
        }, reject);
    });
  }

  public getByField(
    collectionName: string,
    fieldName: string,
    value: any,
    operation: string = "==",
    createdAt: string = "createdAt"
  ): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      this.db
        .collection(collectionName)
        .where(fieldName, operation, value)
        .orderBy(createdAt, "asc")
        .get()
        .then((query: any) => {
          return resolve(
            query.docs.map((doc: any) => ({ ...doc.data(), id: doc.id }))
          );
        }, reject);
    });
  }

  public metricsByDate = async (
    collectionName: string,
    fieldValue: string,
    from: any,
    to: any,
    fieldName2?: string,
    fieldValue2?: string,
    fieldName: string = "username",
    dateField: string = "created_at",
    sortType: string = "asc",
    operation: string = "=="
  ): Promise<Array<any>> => {
    return new Promise((resolve, reject) => {
      fieldName2
        ? this.db
            .collection(collectionName)
            .where(fieldName, operation, fieldValue)
            .where(fieldName2, operation, fieldValue2)
            .where(dateField, ">=", from)
            .where(dateField, "<=", to)
            .orderBy(dateField, sortType)
            .get()
            .then((query: any) => {
              return resolve(
                query.docs.map((doc: any) => ({ ...doc.data(), id: doc.id }))
              );
            }, reject)
        : this.db
            .collection(collectionName)
            .where(fieldName, operation, fieldValue)
            .where(dateField, ">=", from)
            .where(dateField, "<=", to)
            .orderBy(dateField, sortType)
            .get()
            .then((query: any) => {
              return resolve(
                query.docs.map((doc: any) => ({ ...doc.data(), id: doc.id }))
              );
            }, reject);
    });
  };
  protected getDoc(collectionName: string, doc: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.db
        .collection(collectionName)
        .doc(doc)
        .get()
        .then((doc: any) => {
          return resolve(doc.data());
        }, reject);
    });
  }

  protected set(
    collectionName: string,
    doc: string,
    data: any
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.db
        .collection(collectionName)
        .doc(doc)
        .set(data)
        .then((res) => {
          console.log("success in set", res);
          resolve(true);
        })
        .catch((res) => {
          console.log("error in set", res);
          reject(false);
        });
      // .then(
      //   () => resolve(true),
      //   () => reject(false)
      // );
    });
  }

  public update(
    collectionName: string,
    doc: string,
    data: any
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.db
        .collection(collectionName)
        .doc(doc)
        .update(data)
        .then(
          (res) => resolve(res),
          (res) => reject(res)
        );
    });
  }

  protected delete(collectionName: string, doc: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.db
        .collection(collectionName)
        .doc(doc)
        .delete()
        .then(
          () => resolve(true),
          () => reject(false)
        );
    });
  }
}
