/**
 * @prettier
 */

import React, {
  useContext,
  FunctionComponent,
  useState,
  useEffect,
} from "react";
type userType = Object;
type setPrimaryUserType = (user: userType) => void;
type primaryUserContextType = Array<userType | setPrimaryUserType>;

const PrimaryUserContext = React.createContext([] as primaryUserContextType);

const usePrimaryUser = (): primaryUserContextType => {
  const context: primaryUserContextType = useContext(PrimaryUserContext);
  if (!context) {
    throw new Error(`usePrimaryUser must be used within a PrimaryUserProvider`);
  }

  return context;
};

const PrimaryUserProvider: FunctionComponent = (props) => {
  const existingPrimaryUser = localStorage.getItem("kit-primaryUser");
  const [primaryUser, setPrimaryUser] = useState(
    existingPrimaryUser ? JSON.parse(existingPrimaryUser) : {}
  ) as primaryUserContextType;

  useEffect(() => {
    localStorage.setItem("kit-primaryUser", JSON.stringify(primaryUser));
  }, [primaryUser]);

  return (
    <PrimaryUserContext.Provider
      value={[primaryUser, setPrimaryUser]}
      {...props}
    />
  );
};

export { usePrimaryUser, PrimaryUserProvider };
