/**
 * @prettierdesc
 */

import React, { FC, useEffect, useState } from "react";
import { Box, CircularProgress, Tooltip } from "@material-ui/core";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InfoIcon from "@material-ui/icons/Info";

import CreateCampaignStyles from "components/styled/CreateCampaign";
import Image from "components/Common/Image/Image";
import history from "history/browser";
import facebook from "statics/media/fb-vector.png";
import { PageType } from "types/PageType";
import useDocumentSubscriber from "hooks/useDocumentSubscriber";
import { firestore } from "firebase-tools/firebase";
import { useUser } from "UserContext";
import { SendButton } from "components/styled/Common";
import GetCookies from "components/Common/GetCookies";
import CampaignName from "components/Common/CampaignName";
import { SocialMediaPlatform } from "../../../../../../containers/BeAmplified/Campaigns/Organic/SelectCampaignPage";
import { getPlatformImage } from "components/BeAmplified/Campaigns/Organic/Components/SelectCampaignBox";
import { launchFacebookAutoMessager } from "helpers/facebookAutoMessage/launchAutoMessager";
import { editAutoMessageCampaign } from "helpers/facebookAutoMessage/editAutoMessageCampaign";
import showToast from "utils/toast";

interface CreateCampaignProps extends PageType {
  uid?: string;
}

export const Facebook: FC<CreateCampaignProps> = ({ uid }) => {
  let campaignRef = firestore
    .collection("facebook-auto-dm")
    .doc(uid || "create");

  let campaignInfo = useDocumentSubscriber({ ref: campaignRef }).document;
  const edit = !!uid;

  const [campaignName, setCampaignName] = useState("");
  const [sessionCookieCUser, setSessionCookieCUser] = useState("");
  const [sessionCookieXs, setserSessionCookieXs] = useState("");
  const [spreadsheetUrl, setSpreadsheetUrl] = useState("");
  const [message, setMessage]: any = useState("");
  const [errors, setErrors] = useState({
    authUser: "",
    campaignName: "",
    spreadsheetUrl: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  const [user]: any = useUser();
  const authUser = Array.isArray(user) ? user[0][0] : user;

  useEffect(() => {
    if (edit) {
      setCampaignName(campaignInfo?.campaignName);
      campaignInfo?.sessionCookie &&
        setSessionCookieCUser(campaignInfo?.sessionCookie);
      campaignInfo?.sessionCookieXs &&
        setserSessionCookieXs(campaignInfo?.sessionCookieXs);
      campaignInfo?.message && setMessage(campaignInfo?.message);
      campaignInfo?.spreadsheetUrl &&
        setSpreadsheetUrl(campaignInfo?.spreadsheetUrl);
    }
  }, [edit, campaignInfo]);

  const createCampaign = async () => {
    if (!authUser)
      return setErrors((errors: any) => ({
        ...errors,
        authUser: "authUser is required!",
      }));
    if (!campaignName)
      return setErrors((errors: any) => ({
        ...errors,
        campaignName: "Campaign name is required!",
      }));
    if (!spreadsheetUrl)
      return setErrors((errors: any) => ({
        ...errors,
        spreadsheetUrl: "Spreadsheet Url is required!",
      }));
    if (!message.length)
      return setErrors((errors: any) => ({
        ...errors,
        message: "Message is required!",
      }));

    if (!spreadsheetUrl.includes("https://docs.google.com/spreadsheets/d/")) {
      return setErrors((errors: any) => ({
        ...errors,
        spreadsheetUrl: "Enter a valid spreadsheet URL!",
      }));
    }
    if (sessionCookieCUser === "") {
      showToast("Error: Add a C_user session Cookie to proceed");

      return;
    }
    if (sessionCookieXs === "") {
      showToast("Error: Add a xs session Cookie to proceed");

      return;
    } else {
      setLoading(true);
      if (edit) {
        try {
          await editAutoMessageCampaign(uid!, {
            spreadsheetUrl,
            sessionCookie:
              authUser?.facebook?.sessionCookieCUser || sessionCookieCUser,
            sessionCookieXs:
              authUser?.facebook?.sessionCookieXs || sessionCookieXs,
            campaignName,
            username: authUser?.facebook?.username,
            message,
          });
        } catch (error) {
          setLoading(false);
          showToast(
            `Error: ${
              error?.response.data.failure ||
              "something went wrong, please try again"
            }`
          );
          return;
        }
      } else {
        try {
          await launchFacebookAutoMessager({
            spreadsheetUrl,
            sessionCookie:
              authUser?.facebook?.sessionCookieCUser || sessionCookieCUser,
            sessionCookieXs:
              authUser?.facebook?.sessionCookieXs || sessionCookieXs,
            campaignName,
            username: authUser?.facebook?.username,
            message,
          });
        } catch (error) {
          setLoading(false);
          showToast(
            `Error: ${
              error.response.data.failure ||
              "something went wrong, please try again"
            }`
          );
          return;
        }
      }
      setCampaignName("");
      setserSessionCookieXs("");
      setSessionCookieCUser("");
      setSpreadsheetUrl("");
      setLoading(false);
    }
  };

  return (
    <CreateCampaignStyles>
      <ToastContainer />
      <div>
        <span className="title__main">{"Create"} Campaign</span>

        <Box marginTop="50px">
          <div className="items-center">
            <Image
              className="img__main"
              src={getPlatformImage(SocialMediaPlatform.Facebook)}
              alt=""
            />
            <span className="title__sub">Facebook Auto Direct Message</span>
          </div>
        </Box>
        <p className="description__main">
          Send personalized messages to your Facebook friends.
        </p>
      </div>

      <div
        style={{ margin: "20px 0px -20px 0px" }}
        className="campaign__container"
      >
        <span className="title__basic" style={{ marginBottom: 10 }}>
          Client name
        </span>
        <input
          type="text"
          value={`${authUser.firstName} ${authUser.lastName}`}
          style={{ color: "#606060" }}
          className="campaign__input description__sub campaign__input--disabled"
        />
      </div>

      <CampaignName
        campaignName={campaignName}
        setCampaignName={setCampaignName}
      />

      <GetCookies
        setSessionCookie={setSessionCookieCUser}
        sessionCookie={sessionCookieCUser}
        title="Your Facebook c_user session cookie"
        platform={SocialMediaPlatform.Facebook}
        platformIcon={facebook}
        tokeType="c_user"
      />
      <GetCookies
        setSessionCookie={setserSessionCookieXs}
        sessionCookie={sessionCookieXs}
        title="Your Facebook xs session cookie*"
        platform={SocialMediaPlatform.Facebook}
        platformIcon={facebook}
        tokeType="xs"
      />

      <div className="campaign__container">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <span className="title__basic">Facebook users to message</span>
          <Tooltip
            title={
              <Box>
                <h1>
                  Tell us which Facebook users you would like it to message.
                </h1>
                <Box fontSize={14}>
                  <div>
                    Create a spreadsheet on{" "}
                    <a
                      href="https://docs.google.com/spreadsheets/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Google Spreadsheet.
                    </a>{" "}
                    Fill it with your facebook profiles. One name per row, all
                    in column A:
                  </div>
                  <br />
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/be-measured-admin-staging.appspot.com/o/images%2Ffacebook-sheet.png?alt=media&token=f77526c8-2a66-4a19-bb51-2653ca1bf262"
                    alt="facebook-posts"
                    width="88%"
                  />
                  <br />

                  <div>Make this spreadsheet public so we can access it:</div>
                  <br />

                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/be-measured-admin-staging.appspot.com/o/images%2Fshare%20spreadsheet.png?alt=media&token=d1aaa739-4d5a-40a0-9562-8b82a633d61b"
                    alt="share spreadsheet"
                    width="98%"
                  />
                  <br />

                  <div>And paste that spreadsheet's URL back here.</div>
                </Box>
              </Box>
            }
            arrow
            interactive
          >
            <InfoIcon style={{ color: "#C4C4C4" }} />
          </Tooltip>
        </Box>
        <span className="description__sub">
          Must be a google spreadsheet URL.
        </span>
        <input
          type="text"
          value={spreadsheetUrl}
          placeholder="https://docs.google.com/spreadsheets/d/(...)"
          onChange={(e) => {
            setSpreadsheetUrl(e.target.value);
            setErrors((errors: any) => ({
              ...errors,
              spreadsheetUrl: "",
            }));
          }}
          className="campaign__input"
        />
        {errors.spreadsheetUrl && (
          <span style={{ color: "red", fontSize: 14, paddingLeft: 6 }}>
            {errors.spreadsheetUrl}
          </span>
        )}
      </div>

      <div className="campaign__container">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <span className="title__basic">Message</span>
        </Box>
        <span className="description__sub">Type your message here.</span>
        <textarea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          className="campaign__textarea"
        ></textarea>
        {errors.message && (
          <span style={{ color: "red", fontSize: 14, paddingLeft: 6 }}>
            {errors.message}
          </span>
        )}
      </div>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginTop="40px"
      >
        <div
          className="title__sub link"
          onClick={() => {
            history.back();
          }}
        >
          {"<"} Back
        </div>
        <SendButton
          onClick={(e) => {
            e.preventDefault();
            !loading && createCampaign();
          }}
          className="btn"
          disabled={loading}
        >
          {uid ? "Update" : "Create"} Campaign
          {loading && <CircularProgress className="btn-progress" size={20} />}
        </SendButton>
      </Box>
    </CreateCampaignStyles>
  );
};
