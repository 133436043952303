import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  readmoreButton: {
    display: "inline",
    backgroundColor: "transparent",
    outline: "none",
    border: 0,
    padding: 0,
    margin: 0,
    cursor: "pointer",
    color: "#1d4e89",
    fontStyle: "italic",
  },

  readmoreTextHide: {
    maxHeight: 0,
    fontSize: 0,
    opacity: 0,
  },

  readmoreTextShow: {
    maxHeight: "10rem",
    opacity: 1,
    fontSize: "inherit",
  },

  readmoreTextRemaining: {
    transition: "opacity 240ms ease",
  },
  yes: {
    color: "#4FD575",
    fontWeight: "bold",
    textTransform: "uppercase",
    fontFamily: "'Avenir Black', sans-serif",
    letterSpacing: "2px",
  },

  no: {
    color: "#FF5269",
    fontWeight: "bold",
    textTransform: "uppercase",
    fontFamily: "'Avenir Black', sans-serif",
  },

  delete: {
    fontSize: "13px",
    color: "#146dd9",
    fontFamily: "'Avenir Book', sans-serif",
  },

  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "'Avenir Book', sans-serif",
  },

  container: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    width: "533px",
    borderRadius: "15px",

    "&:focus": {
      outline: "none",
    },
  },

  paper: {
    padding: theme.spacing(2, 4, 3),
  },

  top: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "20px 0",
  },

  modalHeading: {
    fontSize: "22px",
    textTransform: "capitalize",
  },

  closeIcon: {
    color: "#000",
    cursor: "pointer",
    fontSize: "30px",
    fontWeight: "bold",
    transition: "all .3s ease",
  },

  field: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    marginTop: "26px",
    transition: "all 0.3s ease",
    fontSize: "15px",

    "& > :first-child": {
      marginBottom: "10px",
    },
  },

  textarea: {
    border: "1px solid #E8E8E8 !important",
  },

  input: {
    width: "94%",
    padding: "12.5px",
    border: "2px solid #ABADAD",
    boxSizing: "border-box",
    borderRadius: "10px",
    cursor: "pointer",
    fontFamily: "'Avenir Book', sans-serif",
    lineHeight: "20px",
    fontSize: "15px",
    "&:focus": {
      border: "2px solid #000",
      outline: "none",
    },
    "&:hover": {
      border: "2px solid #000",
    },
    "&:active": {
      border: "2px solid #000",
    },
    "&::placeholder": {
      fontFamily: "'Avenir Book', sans-serif",
      fontSize: "inherit",
      color: "#ABADAD",
    },
    "&::-webkit-input-placeholder": {
      fontFamily: "'Avenir Book', sans-serif",
      fontSize: "inherit",
      color: "#ABADAD",
    },
    "&:-ms-input-placeholder": {
      fontFamily: "'Avenir Book', sans-serif",
      fontSize: "inherit",
      color: "#ABADAD",
    },
  },

  postText: {
    height: "140px",
    resize: "none",
  },

  line: {
    opacity: " 0.3",
    height: "1px",
    background: "#979797",
    marginTop: "20px",
  },

  bottom: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    height: "74px",

    "& > div > *": {
      marginBottom: "5px",
      fontSize: "16px",
      color: "#fff",
    },
    "& > div > :last-child": {
      marginRight: "32px",
      marginLeft: "16px",
    },
  },
  decisionsTopMargin: {
    marginTop: "50px",
    marginLeft: "5%",
  },

  platform2: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "50px",
    fontSize: "24px",
    fontFamily: "AvenirNext-Medium",
  },
  platformIcon: {
    width: "44.75px",
    height: "50px",
    marginRight: "10px",
  },
  approvalTitle: {
    fontFamily: "'Avenir Black', sans-serif",
    fontSize: "15px",
    lineHeight: "25px",
    fontWeight: 900,
    marginBottom: 10,
  },

  archiveLabel: {
    fontFamily: "'Avenir Book', sans-serif !important",
    lineHeight: "22px",
    textTransform: "capitalize",
    textDecoration: "underline",
    color: "#abadad",
  },
  printMode: {
    display: "flex",
    alignItems: "center",
    marginTop: 30,

    "& > :first-child": {
      marginRight: 50,
    },
  },
  name: {
    textAlign: "center",
    fontFamily: "Avenir Black, sans-serif",
    fontSize: "18px",
  },
  period: {
    fontFamily: "AvenirNext",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "22px",
    color: "#000000",

    "& > :first-child": {
      marginLeft: "15px",
    },
  },
  info: {
    display: "flex",
    justifyContent: "space-around",
    fontFamily: "Avenir Book, sans-serif",
    fontSize: 12,
    backgroundColor: "#ABADAD",
    minWidth: 80,
  },
  arrow: {
    color: "#ABADAD",
  },
  avatar: {
    minWidth: "60px",
    minHeight: "60px",
    mixBlendMode: "normal",
    border: "solid 1px #f2f2f2",
  },
  AvatarIcon: {
    width: "30px",
    height: "30px",
    background: "#000",
  },
  AvatarSvg: {
    minWidth: "60px",
    minHeight: "60px",
    mixBlendMode: "normal",
    background: "#000",
  },
  cardContent: {
    fontFamily: "'Avenir Book', sans-serif",
    fontSize: "15px",
    lineHeight: "25px",
    whiteSpace: "pre-line",
    wordBreak: "break-word",
  },
  img: {
    width: "24.75px",
    objectFit: "contain",
  },
}));
