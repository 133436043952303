import React, { FC } from "react";
import Img from "react-cool-img";

import loadingIcon from "statics/media/iconPlaceholder.svg";
import loadingImage from "statics/media/imageLoader.gif";

interface Props {
  src: string;
  alt?: string;
  className?: any;
  style?: any;
  image?: boolean;
}

const Image: FC<Props> = ({ src, className, alt, style, image }) => {
  return (
    <Img
      placeholder={image ? loadingImage : loadingIcon}
      src={src}
      className={className || ""}
      alt={alt || ""}
      style={{ ...style }}
    />
  );
};

export default Image;
