import styled from "styled-components";

const BePlannedMonthly = styled.div`
  height: 100vh;

  .be-planned-monthly {
    height: auto;
    font-style: normal;
    font-size: 30px;
    line-height: 25px;
    color: #101010;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    font-family: "Avenir Black", sans-serif;
  }
  @media screen and (max-width: 1000px) {
    .be-planned-monthly {
      padding-top: 10px;
    }
  }
  @media screen and (max-width: 768px) {
    & > div > :nth-child(2) {
      flex-direction: column;
      align-items: center !important;
    }
    & > div > :nth-child(2) svg {
      margin-right: 0px !important;
    }
  }
  .selector {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    & > :last-child {
      margin-bottom: -5px;
    }
  }

  .post {
    padding-bottom: 6vh;
  }
`;

export default BePlannedMonthly;
