import React, { FC, useState } from "react";
import { DateTime } from "luxon";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import Tooltip from "@material-ui/core/Tooltip";

import "@fullcalendar/daygrid/main.css";
import CustomFullCalendarStyled, {
  EventTitle,
} from "components/styled/CustomFullCalendar";
import { ScrollToTop } from "components/ScrollToTop";
import facebook from "statics/media/fb-vector.png";
import twitter from "statics/media/twitter-vector.png";
import instagram from "statics/media/instagram-vector.png";
import pinterest from "statics/media/pinterest.png";
import snapchat from "statics/media/snapchat.png";
import youtubeshort from "statics/media/youtube-short.png";
import linkedin from "statics/media/linkedin.png";
import tiktok from "statics/media/tiktok.png";
import youtube from "statics/media/youtube.png";
import triller from "statics/media/triller.png";
import tumblr from "statics/media/tumblr.png";
import defaultIcon from "statics/media/universal-2.png";
import { ReactComponent as BlackTicks } from "statics/media/blackTicks.svg";
import EventIcon from "./EventIcon";
import { useStyles } from "./styles";

interface IBePlannedMonthly {
  posts: () => any[];
  events: any;
  user: any;
  height?: any;
  handleDateChange: (dbeplate) => void;
}
const icons = {
  facebook,
  twitter,
  instagram,
  pinterest,
  snapchat,
  linkedin,
  youtubeshort,
  tiktok,
  triller,
  tumblr,
  youtube,
};

export const BePlannedMonthly: FC<IBePlannedMonthly> = ({
  posts,
  events,
  user,
  height,
  handleDateChange,
}) => {
  const [, setSelectedPostId] = useState("id");
  const [, setCollection] = useState("fb");
  const [, setPlatform] = useState("");
  const classes = useStyles({});

  let multiChannel = posts().map((post) => {
    const date = DateTime.fromSeconds(post.date.seconds).toJSDate();

    return {
      title: post.title || "multiChannel Post",
      date,
      className: "event",
      id: post.id,
      post,
    };
  });

  const multiple: any = [];
  const eventsArr = events.filter(
    (v, i, a) =>
      a.findIndex((t) => JSON.stringify(t) === JSON.stringify(v)) === i
  );

  let eventsData = eventsArr.map((event) => {
    const start = DateTime.fromSeconds(event.start_date.seconds).toJSDate();
    const end: any = event?.end_date
      ? DateTime.fromSeconds(event?.end_date?.seconds).toJSDate()
      : start;

    const eventObj = {
      title: event.subject,
      start,
      end,
      className: "event",
      id: event.id,
      event,
      type: "event",
    };

    if (start !== end) {
      multiple.push(eventObj);
      return {};
    }
    return eventObj;
  });

  const calendarData = [...eventsData, ...multiChannel].sort(
    (a, b) => a.date - b.date
  );

  calendarData.unshift(...multiple);

  const handleEventClick = (event) => {
    if (event.title.includes("Facebook")) {
      setCollection("facebook-posts");
      setPlatform("facebook");
    }
    if (event.title.includes("Instagram")) {
      setCollection("instagram-posts");
      setPlatform("instagram");
    }
    if (event.title.includes("Twitter")) {
      setCollection("twitter-posts");
      setPlatform("twitter");
    }
    if (event.title.includes("Custom")) {
      setPlatform("custom");
      setCollection("custom-posts");
    }
    if (
      ![
        "Custom Post",
        "Instagram Post",
        "Facebook Post",
        "Twitter Post",
      ].includes(event.title)
    ) {
      return;
    }

    setSelectedPostId(event.id);
    setTimeout(() => {
      document.location.href = "#post";
    }, 100);
  };

  const eventsContent = (eventInfo) => {
    return (
      <Tooltip
        title={
          <span style={{ whiteSpace: "pre-line" }}>
            {eventInfo.event.extendedProps.event?.event_type ||
              eventInfo.event.title}
          </span>
        }
        arrow
        interactive
        classes={{ tooltip: classes.info }}
      >
        <EventTitle
          style={{
            background: !eventInfo.event.extendedProps.post
              ? "#000"
              : "#ffffff",
            height: !eventInfo.event.extendedProps.post && 20,
            borderRadius: !eventInfo.event.extendedProps.post && "5px",
            color: !eventInfo.event.extendedProps.post && "#fff",
            border: eventInfo.event.extendedProps.post && "0.5px solid #abadad",
          }}
          multipleDate={!!eventInfo.event.extendedProps.event?.end_date}
          event={!!eventInfo.event.extendedProps.event}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "0px 5px",
            }}
          >
            {eventInfo.event.extendedProps.post?.channels.map((channel) => (
              <img
                className={classes.channelIcon}
                src={icons[channel.toLowerCase()] || defaultIcon}
                alt=""
              />
            ))}
            {eventInfo.event.extendedProps.event && (
              <EventIcon
                category={eventInfo.event.extendedProps.event?.event_type || ""}
              />
            )}
          </div>
          <span className={classes.eventTitle}>{eventInfo.event.title}</span>
          <div style={{ marginRight: 14, width: "20%" }}>
            {eventInfo.event.extendedProps.post?.internal_status_weekly ===
            "scheduled" ? (
              <BlackTicks className={classes.checkMarks} />
            ) : null}
          </div>
        </EventTitle>
      </Tooltip>
    );
  };

  return (
    <ScrollToTop>
      <CustomFullCalendarStyled>
        <div className="calendar-container">
          <FullCalendar
            plugins={[dayGridPlugin]}
            events={calendarData}
            eventContent={eventsContent}
            nextDayThreshold={"23:59"}
            eventOrder={(a: any, b: any) => {
              const categories = [
                "",
                "Custom Event",
                "Community Management",
                "TV/Radio Promo",
                "Performance",
                "Rehearsal/Recording",
                "Release",
                "Viral Events",
                "Tentpole Idea",
              ];
              const sortCategories = (a, b) => {
                const indexA = categories.indexOf(a.event?.event_type);
                const indexB = categories.indexOf(b.event?.event_type);
                return indexB - indexA;
              };
              return (
                a.type?.localeCompare(b.type) ||
                b.end - b.start - (a.end - a.start) ||
                (a.event && b.event && sortCategories(a, b))
              );
            }}
            dayMaxEvents={6}
            fixedWeekCount={false}
            firstDay={1}
            dayHeaderFormat={{ weekday: "long" }}
            datesSet={(arg) => {
              handleDateChange(arg);
            }}
            height={1560}
            eventClick={(info) => handleEventClick(info.event)}
          />
        </div>
      </CustomFullCalendarStyled>
    </ScrollToTop>
  );
};
