export const redirectBasedOnPath = (path: string, startDate, endDate) => {
  if (path === "contentplanfeed") {
    window.location.replace(
      `${process.env.REACT_APP_WEBSITE_URL}/content-plan-feed?start=${startDate}&end=${endDate}`
    );
  } else if (path === "bemeasured") {
    window.location.replace(
      `${process.env.REACT_APP_WEBSITE_URL}/be-measured?start=${startDate}&end=${endDate}`
    );
  } else {
    return;
  }
};
