import styled from "styled-components";

const App = styled.div`
  @media screen and (max-width: 900px) {
    #page-title {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    #be-planned-title {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    #be-planned-title > :first-child {
      margin-bottom: 30px !important;
    }
  }
`;

export default App;
