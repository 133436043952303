import { UserService } from "services/UserService";
import { AgentsService } from "services/AgentsService";

export const getAgent = async (email) => {
  const ClientAgent = new AgentsService();
  const agents = await ClientAgent.getAgentByField("email", email);
  return agents[0];
};
export const getClients = async (agent) => {
  const User = new UserService();
  const clients: any = await Promise.all(
    agent.clients.map(async (mail) => await User.getUserByField("email", mail))
  );
  return clients;
};

const removeArchivedClients = (clients) => {
  let filteredClients = clients.map((client) => {
    return client
      .map((item) => {
        if (item.isArchived) {
          return null;
        } else {
          return item;
        }
      })
      .filter((item) => item !== null);
  });

  // remove empty arrays
  filteredClients = filteredClients.filter((item) => item.length !== 0);
  return filteredClients;
};

export const AllClientsInactive = async (agent) => {
  const data = await getClients(agent);
  const clients = removeArchivedClients(data);
  const isActivated: any = [];
  for (const client of clients) {
    for (const item of client[0].secondaryEmails) {
      if (item.email === agent.email) isActivated.push(item.isActivated);
    }
    // Bypass secondaryEmails check
    if (!client[0].secondaryEmails.some((item) => item.email === agent.email)) {
      isActivated.push(true);
    }
  }
  const allInactive = isActivated.every((val) => !val);

  return { allInactive, clients };
};
