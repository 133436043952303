import React, { FunctionComponent } from "react";
import { Box, FormControl, Select, MenuItem } from "@material-ui/core";

import { useStyles } from "./styles";
import useCollectionSubscriber from "hooks/useCollectionSubscriber";
import { firestore } from "firebase-tools/firebase";
import { BootstrapInput } from "components/Common/BootstrapInput";
import { useUser } from "UserContext";

interface CampaignSelectProps {
  campaign: any;
  setCampaign: (e: any) => void;
}

export const CampaignSelect: FunctionComponent<CampaignSelectProps> = ({
  campaign,
  setCampaign,
}) => {
  const [user]: any = useUser();
  const client = Array.isArray(user) ? user[0][0] : user;
  const queries = [
    firestore
      .collection("twitter-campaigns")
      .where("username", "==", client.twitter.username)
      .where("campaignType", "==", "autofollow"),
  ];
  const campaignsRef = useCollectionSubscriber<any[]>({ queries }).documents;
  const campaigns: any[] = campaignsRef.map((doc) => ({ ...doc }));
  const campaignsWithFollows: any[] = campaigns.filter(
    (campaign: any) =>
      !!campaign?.followedProfilesCount || !!campaign?.followsCount
  );

  const classes = useStyles({
    listLength: campaigns.length > 11 ? "400px" : "auto",
  });

  return (
    <FormControl style={{ width: "100%" }}>
      <Box component="span" className={classes.dateLabel} mb={1}>
        Choose Campaign
      </Box>

      <Select
        labelId="demo-customized-select-label"
        id="demo-customized-select"
        value={campaign}
        onChange={(event) => {
          event.preventDefault();
          setCampaign(event.target.value);
        }}
        input={<BootstrapInput />}
      >
        <MenuItem value="">
          {campaigns.length === 0 ? (
            <em>No campaigns for the selected client</em>
          ) : (
            <em>Select</em>
          )}
        </MenuItem>
        {(campaignsWithFollows || []).map((doc) => {
          return (
            <MenuItem key={doc.id} value={doc.id}>
              {doc?.campaignName}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default CampaignSelect;
