import React from "react";
import { useStyles } from "./styles";
import { Avatar, Grid } from "@material-ui/core";
import userIcon from "statics/media/name.svg";

const UserProfile = ({ user }: { user: any }) => {
  const classes = useStyles({});

  return (
    <Grid container className={classes.userContainer}>
      <Grid xs={12} md={1} className={classes.avCon}>
        {user.avatarUrl === "" ? (
          <Avatar
            src={userIcon}
            classes={{
              root: classes.AvatarSvg,
              img: classes.AvatarIcon,
            }}
            style={{
              width: "80px",
              height: "80px",
              flexShrink: 0,
            }}
          />
        ) : (
          <Avatar className={classes.avatar} src={user.avatarUrl} />
        )}
      </Grid>
      <Grid xs={12} md={10}>
        <div className={classes.userName}>
          {user.firstName && user.firstName + " " + user.lastName}
        </div>
      </Grid>
    </Grid>
  );
};

export default UserProfile;
