/**
 * @prettierdesc
 */

import React, { FC, useState } from "react";

import { PageType } from "types/PageType";

import { CreateCampaign } from "components/BeAmplified/Campaigns/Organic/Create/AutoFollow/SharedCreateCampaign";
import { SelectCampaign, SocialMediaPlatform } from "../SelectCampaignPage";

export const CreateOrganicCampaign: FC<PageType> = () => {
  const [isCreate, setIsCreate] = useState(false);
  const [platform, setPlatform] = useState<SocialMediaPlatform>(
    SocialMediaPlatform.Twitter
  );

  const toggleCreate = (
    platform: SocialMediaPlatform = SocialMediaPlatform.Twitter
  ) => {
    setPlatform(platform);
    setIsCreate(!isCreate);
  };

  return isCreate ? (
    <CreateCampaign toggleCreate={toggleCreate} platform={platform} />
  ) : (
    <SelectCampaign toggleCreate={toggleCreate} />
  );
};
