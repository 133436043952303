/**
 * @prettier
 */

import React, { FunctionComponent, ChangeEvent } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { ReactComponent as PASSWORD } from "statics/media/password.svg";

import { makeStyles } from "@material-ui/core/styles";

type PasswordType = {
  value?: string;
  autoFocus?: boolean;
  name?: string;
  label?: string;
  helperText?: any;
  error?: any;
  color?: string;
  marginTop?: string;
  width?: string;
  onChangeHandler?: (e: ChangeEvent<HTMLInputElement>) => any;
};

const useStyles = makeStyles((theme) => ({
  text: {
    width: (props: any) => props.width,
    marginTop: (props: any) => props.marginTop,
  },
  backgroundColor: {
    backgroundColor: "#fff",
    height: "45px",
    borderRadius: "10px",
    fontFamily: "Avenir Book, sans-serif",
  },
  label: {
    color: (props: any) => props.color,
    top: -10,
    fontFamily: "Avenir Book, sans-serif",
    fontSize: 20,
  },
  underline: {
    "&:after": {
      borderBottom: "1.5px solid #1D4E89",
    },
  },
  icon: {
    color: "gray",
    marginLeft: "10px",
  },
  helperText: {
    color: "rgb(209, 44, 0)",
    fontSize: "13px",
    textAlign: "center",
  },
}));

const Password: FunctionComponent<PasswordType> = ({
  value = "",
  autoFocus = false,
  name = "password",
  label = "Password",
  helperText = "",
  error = false,
  color = "fff",
  onChangeHandler,
  marginTop,
  width,
}) => {
  const classes = useStyles({ color, marginTop, width });
  return (
    <TextField
      id={name}
      name={name}
      label={label}
      type="password"
      margin="normal"
      placeholder="********"
      defaultValue={value}
      error={error}
      autoFocus={autoFocus}
      onChange={onChangeHandler}
      helperText={helperText}
      fullWidth
      className={classes.text}
      InputLabelProps={{
        className: classes.label,
      }}
      autoComplete="current-password"
      FormHelperTextProps={{
        className: classes.helperText,
      }}
      InputProps={{
        className: classes.backgroundColor,
        classes: { underline: classes.underline },
        startAdornment: (
          <InputAdornment position="start">
            <PASSWORD className={classes.icon} />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default Password;
