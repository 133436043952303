/**
 * @prettier
 */
import { DB } from "firebase-tools/db";
import { IUser } from "interfaces/IUser";

export class UserService extends DB {
  private collectionName: string = "users";
  private _doc: string = "";

  constructor(doc?: string) {
    super();
    if (doc) {
      this.doc = doc;
    }
  }
  set doc(value: string) {
    this._doc = value;
  }

  get doc() {
    return this._doc;
  }
  async getUserByDoc(): Promise<IUser> {
    return this.getDoc(this.collectionName, this.doc);
  }

  async getUserByField(
    fieldName: string,
    value: any,
    operation: string = "=="
  ): Promise<Array<IUser>> {
    return this.getByField(this.collectionName, fieldName, value, operation);
  }

  async getUsers(): Promise<Array<IUser>> {
    return this.get(this.collectionName);
  }

  async addUser(user: IUser): Promise<boolean> {
    return this.set(this.collectionName, this.doc, user);
  }

  async updateUser(user: any): Promise<boolean> {
    return this.update(this.collectionName, this.doc, user);
  }

  async deleteUser(): Promise<boolean> {
    return this.delete(this.collectionName, this.doc);
  }
}
