import { DateTime } from "luxon";
import moment from "moment";

import { getScreenshot } from "utils/getScreenshot";

export const formatGraphicsBrief = (post) => {
  let brief = post.graphicsBrief;
  brief = brief.replaceAll("<div>", "");

  let content: any[] = brief.split("</div>");
  let index = 0;
  let arr = [""];

  for (let i = 0; i < content.length; i++) {
    const element = content[i];
    if (i !== index && element?.includes(":")) {
      index = i;
      arr.push(element);
    }
    const arrIndex = arr.length - 1;

    if (i === index && element?.includes(":")) {
      arr[arrIndex] = element;
    } else {
      arr[arrIndex] = arr[arrIndex].concat(" ", element);
    }
  }

  content = arr.map((val) => {
    const br = val.split(":");

    if (br.length > 1 && br[1]?.trim() !== "") {
      const keyWord = br.shift();
      return `<div>${keyWord}: ${br.join("")}</div>`;
    }
    return null;
  });

  const contentLen = content.join("").length;

  if (content.filter((val) => val).length) {
    return contentLen ? content.join("") : post.graphicsBrief || "";
  } else {
    return false;
  }
};

export const getPostDate = (dateInSeconds) => {
  let day: any = DateTime.fromSeconds(dateInSeconds).toJSDate();

  day = moment(day).format("ddd").split(" ")[0];

  return DateTime.fromSeconds(dateInSeconds).toFormat(
    `'${day},' dd LLL yyyy 'at' HH:mm`
  );
};

export const generateScreenshot = (post, images, uuid) => {
  const socialPost: any = document.getElementById(`post-${uuid}`);

  if (socialPost) {
    socialPost.style.border = "solid 1px #D8D8D8";

    const carousel: any = document.getElementById("carousel");
    const staticImage: any = document.getElementById("image-placeholder");

    if (!!post.video_url?.length && !!images?.length) {
      carousel.style.display = "none";
      staticImage.style.display = "flex";
    }

    const name = `Be-Planned_date_${getPostDate(post.date.seconds)}`;

    getScreenshot(`post-${uuid}`, name);

    socialPost.style.border = "none";
    if (!!post.video_url?.length && !!images?.length) {
      carousel.style.display = "block";
      staticImage.style.display = "none";
    }
  } else {
    console.error(`Element with ID 'post-${uuid}' not found.`);
  }
};

const getImages = (posts: any[]) => {
  let images: any[] = [];
  for (let post of posts) {
    if (post.image_url) {
      // images = [post.image_url];
      images.push(post.image_url);
    }
  }
  return images;
};

export const generateScreenshots = (posts) => {
  let images = getImages(posts);
  posts.forEach((post) => {
    let uid = post.uuid;
    generateScreenshot(uid, post, images);
  });
};
