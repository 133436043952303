import React, { FC } from "react";
import { ErrorBoundary } from "react-error-boundary";

import BlueScreen from "components/BlueScreen";

const ErrorBoundaryLayer: FC<{ children: any }> = ({ children }) => {
  const onError = (error: Error, info: { componentStack: string }) => {
    const errorString = JSON.stringify(
      error,
      Object.getOwnPropertyNames(error)
    );
    const componentStack = JSON.stringify(info.componentStack);
    const text = `Error occurred:\n${errorString}\nComponent stack: ${componentStack}`;
    console.log("nmfnmdfdmfdtext", text);
  };

  return (
    <ErrorBoundary FallbackComponent={BlueScreen} onError={onError}>
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryLayer;
