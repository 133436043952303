import React, { useState, FC } from "react";
import "react-toastify/dist/ReactToastify.css";

import { PageType } from "types/PageType";
import { useStyles } from "./styles";
import { ReactComponent as CalenderViewIcon } from "../../../statics/media/calenderView.svg";
import { ReactComponent as ListViewIcon } from "../../../statics/media/listView.svg";

import { Grid } from "@material-ui/core";

import { BePlannedPage } from "containers/BePlanned/BePlanned";
import BePlannedMonthly from "containers/BePlannedMonthly";

export const ContentPlannerFeed: FC<PageType> = ({ location }) => {
  const classes = useStyles({});

  const [openList, setOpenList] = useState(true);

  const handleCalender = () => {
    setOpenList(false);
  };
  const handleList = () => {
    setOpenList(true);
  };
  return (
    <>
      <div id="analytics">
        <div
          className={`${classes.pageTitle} be-planned-title`}
          id="page-title"
        >
          <Grid xs={12} md={5} sm={12} className={classes.newPageTitle}>
            Content Planner
          </Grid>

          <Grid xs={12} md={1}>
            <div
              className={`${classes.listIcon} ${classes.smHide}`}
              style={{ justifyContent: "flex-end" }}
            >
              <ListViewIcon
                onClick={handleList}
                style={{
                  stroke: openList ? "none" : "white",
                  cursor: "pointer",
                }}
              />
              <CalenderViewIcon
                onClick={handleCalender}
                style={{
                  stroke: !openList ? "none" : "white",
                  cursor: "pointer",
                }}
              />
            </div>
            <div
              className={`${classes.listIcon} ${classes.mdHide}`}
              style={{
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <ListViewIcon
                onClick={handleList}
                style={{
                  stroke: openList ? "none" : "white",
                  cursor: "pointer",
                }}
              />
              <CalenderViewIcon
                onClick={handleCalender}
                style={{
                  stroke: !openList ? "none" : "white",
                  cursor: "pointer",
                }}
              />
            </div>
          </Grid>
        </div>
      </div>

      {openList && <BePlannedPage location={location} />}
      {!openList && <BePlannedMonthly location={location} />}
    </>
  );
};
