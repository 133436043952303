import React, { FC } from "react";
import Card from "@material-ui/core/Card";

import { PageType } from "types/PageType";
import { useStyles } from "./styles";

const Invalid: FC<PageType> = () => {
  const classes = useStyles({});

  return (
    <div style={{ background: "#f2f2f2", minHeight: "100vh" }}>
      <div className={classes.root}>
        <Card className={classes.card}>
          <div>
            Sorry, your public link is invalid! Get a new one from
            kit@be-hookd.com
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Invalid;
