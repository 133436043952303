import React, { FC } from "react";
import { Grid, Box, Input, Select, MenuItem } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

import { ControlLabel } from "components/styled/Common";
import { useStyles } from "./styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

function getStyles(name, channels, theme) {
  return {
    fontWeight:
      channels.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
interface SearchProps {
  items: any;
  setItems: (e: any) => void;
  options: Array<string>;
  label?: string;
  width?: string;
}

const MultiSelect: FC<SearchProps> = ({
  items,
  setItems,
  options,
  label,
  width = "170px",
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const handleChange = (event) => {
    setItems(event.target.value);
  };
  return (
    <Grid
      id="client-list"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <ControlLabel component="span">{label}</ControlLabel>
      <Box>
        <Select
          disableUnderline
          autoWidth={true}
          id="multi-select"
          multiple
          value={items}
          displayEmpty={true}
          renderValue={() =>
            !!items.length ? items.join(", ") : "Default (all)"
          }
          onChange={handleChange}
          input={<Input />}
          className={classes.select}
          inputProps={{
            className: classes.selectInput,
            classes: {
              icon: classes.selectIcon,
              iconOpen: classes.selectIconOpen,
              selectMenu: classes.selectOverride,
            },
          }}
          MenuProps={{
            classes: { paper: classes.selectOverride },
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width,
                marginLeft: "6px",
                marginTop: "8px",
                borderRadius: 15,
              },
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            getContentAnchorEl: null,
          }}
        >
          {options.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, items, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Grid>
  );
};

export default MultiSelect;
