import axios from "axios";
import { navigate } from "@reach/router";

export interface FaceBookAutoMessageCampaign {
  id: string;
  campaignName: string;
  username: string;
  status: string;
  campaignType: string;
  sessionCookie: string;
  sessionCookieXs: string;
  message: string;
  createdAt: Date;
  updatedAt: Date;
  spreadsheetUrl: string;
  profiles: FacebookAutoDMProfiles[];
  dmContainerId?: string;
  dmStatus?: string;
}

export interface FacebookAutoDMProfiles {
  url: string;
  sent: boolean;
  skipped: boolean;
}

export type FacebookAutoDMLauncherArgs = Omit<
  FaceBookAutoMessageCampaign,
  "id" | "profiles" | "createdAt" | "updatedAt" | "campaignType" | "status"
>;

export const launchFacebookAutoMessager = async (
  campaign: FacebookAutoDMLauncherArgs
) => {
  const response = await axios.post(
    `${process.env.REACT_APP_FUNC_BASE_URL}facebook-createAutoMessagerCampaign`,
    campaign
  );
  navigate(`/campaigns/organic/facebook/auto-dm/${response.data.id}`);
  return response.data;
};
