import { useEffect, useState } from "react";
import { collectionData } from "rxfire/firestore";
import { combineLatest } from "rxjs";

import { WithId } from "../types/typeHelpers";

interface UseCollectionSubscriberParams {
  queries: firebase.firestore.Query[];
  idField?: string;
  deps?: Array<any>;
}

function useCollectionSubscriber<T>({
  queries,
  idField = "id",
  deps = [],
}: UseCollectionSubscriberParams) {
  const [documents, setDocuments] = useState<WithId<T>[]>([]);
  const [error, setError] = useState("");
  const subscribeToPublisherCollection = () => {
    const getDocuments$ = combineLatest(
      queries.map((query) => collectionData<WithId<T>>(query, idField))
    );
    const subscription = getDocuments$.subscribe(
      (observedDocuments) => setDocuments(observedDocuments.flat()),
      (err: any) => {
        setError(err);
      }
    );
    return () => subscription.unsubscribe();
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(subscribeToPublisherCollection, deps);
  return { documents, error };
}

export default useCollectionSubscriber;
