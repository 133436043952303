import React, { FC } from "react";

import Campaign from "components/BeAmplified/Campaigns/Organic/View/AutoFollower";
import { firestore } from "firebase-tools/firebase";
import useDocumentSubscriber from "hooks/useDocumentSubscriber";
import { SocialMediaPlatform } from "../SelectCampaignPage";

type CampaignType = {
  uid?: string;
  platform?: SocialMediaPlatform;
  path: string;
};

const CampaignPage: FC<CampaignType> = ({ uid, platform }) => {
  let collectionName = "twitter-campaigns";
  if (platform) {
    switch (platform) {
      case SocialMediaPlatform.Instagram:
        collectionName = "instagram-autofollow-campaigns";
        break;
      default:
        collectionName = "twitter-campaigns";
        break;
    }
  }
  const campaignsRef = firestore.collection(collectionName).doc(uid);
  const campaign = useDocumentSubscriber({ ref: campaignsRef }).document;
  return (
    <div>
      {campaign?.status && (
        <Campaign
          campaign={campaign}
          platform={platform || SocialMediaPlatform.Twitter}
          collectionName={collectionName}
        />
      )}
    </div>
  );
};

export default CampaignPage;
