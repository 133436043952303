import React, { FC, useEffect, useState } from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import { Box } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";

import { useStyles } from "./styles";
import { useUser } from "UserContext";
import { firestore } from "firebase-tools/firebase";
import useCollectionSubscriber from "hooks/useCollectionSubscriber";
import Loader from "components/Loader";
import { NoPosts } from "components/BePlanned/Post/NoPosts";
import { SendButton } from "components/styled/Common";

type ArchivedProps = {
  setOpen: any;
};

const Archived: FC<ArchivedProps> = ({ setOpen }) => {
  const classes = useStyles({});
  const [isLoading, setIsLoading] = useState(false);
  const [authUser] = useUser() as Array<any>;
  const [checked, setChecked] = React.useState<
    Array<{ id: string; type: string }>
  >([]);

  const twitterQueries = [
    firestore
      .collection("twitter-campaigns")
      .where("username", "==", authUser?.twitter?.username || ""),
    firestore
      .collection("twitter-auto-poster")
      .where("username", "==", authUser?.twitter?.username || ""),
    firestore
      .collection("twitter-auto-dm")
      .where("username", "==", authUser?.twitter?.username || ""),
    firestore
      .collection("twitter-auto-liker")
      .where("username", "==", authUser?.twitter?.username || ""),
  ];
  const fbQueries = [
    firestore
      .collection("facebook-auto-liker")
      .where("username", "==", authUser?.facebook?.username || ""),
    firestore
      .collection("facebook-auto-dm")
      .where("username", "==", authUser?.facebook?.username || ""),
    firestore
      .collection("facebook-group-scrapper")
      .where("username", "==", authUser?.facebook?.username || ""),
  ];

  const instagramQueries = [
    firestore
      .collection("instagram-autofollow-campaigns")
      .where("client", "==", authUser?.id || ""),
  ];
  const twitterCampaigns = useCollectionSubscriber<any[]>({
    queries: twitterQueries,
    deps: [authUser],
  });

  const instagramCampaigns = useCollectionSubscriber<any[]>({
    queries: instagramQueries,
    deps: [authUser],
  });
  const facebookCampaigns = useCollectionSubscriber<any[]>({
    queries: fbQueries,
    deps: [authUser],
  });

  const processTwitterData = (data: Array<any>): Array<any> => {
    return data.filter(
      (campaign) => campaign.username === authUser.twitter?.username
    );
  };

  const processFacebookData = (data: Array<any>): Array<any> => {
    return data
      .filter((campaign: any) => campaign.archived)
      .map((campaign) => ({
        ...campaign,
        campaignType: `fb-${campaign.campaignType}`,
      }));
  };
  const processInstagramData = (data: any[]): any[] => {
    return data.filter((campaign: any) => campaign.archived);
  };

  useEffect(() => {
    setIsLoading(true);
  }, []);

  useEffect(() => {
    if (isLoading) {
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [twitterCampaigns.documents]);

  const allCampaigns = twitterCampaigns.documents.filter(
    (campaign: any) => campaign.archived
  );
  const filteredData = [
    ...processTwitterData(allCampaigns),
    ...processFacebookData(facebookCampaigns.documents),
    ...processInstagramData(instagramCampaigns.documents),
  ];

  const handleToggle = (value: { id: string; type: string }) => () => {
    const currentIndex = checked.findIndex((item) => item.id === value.id);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const typesMap = new Map();
  typesMap.set("autofollow", "twitter-campaigns");
  typesMap.set("autoPoster", "twitter-auto-poster");
  typesMap.set("auto-dm", "twitter-auto-dm");
  typesMap.set("autoLiker", "twitter-auto-liker");
  typesMap.set("fb-auto-dm", "facebook-auto-dm");
  typesMap.set("autoFollow", "instagram-autofollow-campaigns");
  typesMap.set(`fb-groupScrapper`, "facebook-group-scrapper");

  const unarchive = () => {
    if (!checked.length) {
      return;
    }
    checked.forEach((item) => {
      firestore
        .collection(typesMap.get(item.type))
        .doc(item.id)
        .update({ archived: false });
    });
  };

  return (
    <>
      {!isLoading ? (
        <div
          style={{
            width: "70%",
            paddingBottom: "20",
            borderRadius: "10px",
          }}
        >
          {filteredData.length ? (
            <div>
              {authUser ? (
                filteredData.length ? (
                  <div id="admin-table">
                    <TableContainer
                      component={Paper}
                      style={{
                        boxShadow: "0px 5px 45px rgba(0, 0, 0, 0.07)",
                      }}
                    >
                      <div
                        style={{
                          padding: "24px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "34px",
                            color: "#000000",
                            fontFamily: "'Avenir Book', sans-serif",
                            fontWeight: 900,
                          }}
                        >
                          Un-archive Campaigns
                        </span>
                      </div>
                      <Divider />
                      <List
                        style={{
                          height: 300,
                          overflowY: "auto",
                          padding: "24px",
                        }}
                      >
                        {[...(filteredData || [])].map((campaign) => {
                          const labelId = `checkbox-list-label-${campaign?.id}`;
                          return (
                            <ListItem
                              key={campaign?.id}
                              role={undefined}
                              onClick={handleToggle({
                                id: campaign?.id,
                                type: campaign.campaignType,
                              })}
                              style={{
                                padding: 0,
                                height: "55px",
                              }}
                            >
                              <ListItemIcon>
                                <Checkbox
                                  edge="start"
                                  color="default"
                                  checked={checked.some(
                                    (i) => i.id === campaign?.id
                                  )}
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{
                                    "aria-labelledby": labelId,
                                  }}
                                />
                              </ListItemIcon>
                              <Box
                                display="flex"
                                flexDirection="row"
                                justifyContent="space-between"
                                alignItems="flex-end"
                                width="100%"
                                id={labelId}
                              >
                                <p
                                  style={{
                                    fontSize: "18px",
                                    color: "#000000",
                                    fontFamily: "'Avenir Book', sans-serif",
                                    fontWeight: 600,
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    width: "320px",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {campaign?.campaignName}
                                </p>
                                <p
                                  style={{
                                    fontFamily: "'Avenir Book', sans-serif",
                                  }}
                                >
                                  {campaign?.campaignType}
                                </p>
                              </Box>
                            </ListItem>
                          );
                        })}
                      </List>
                      <Divider />
                      <Box display="flex" justifyContent="space-between" p={2}>
                        <SendButton
                          style={{
                            width: "150px",
                            height: "50px",
                            padding: 10,
                            color: "#7F7F7F",
                          }}
                          onClick={() => setOpen(false)}
                          disableRipple
                        >
                          Cancel
                        </SendButton>
                        <SendButton
                          style={{
                            width: "auto",
                            height: "50px",
                            padding: 10,
                          }}
                          onClick={unarchive}
                          disableRipple
                        >
                          Un-archive selected
                        </SendButton>
                      </Box>
                    </TableContainer>
                  </div>
                ) : (
                  <div style={{ marginTop: 20 }}>
                    <NoPosts title="There are no campaigns for the selected client" />
                  </div>
                )
              ) : (
                <div className={classes.noClient}>
                  To view the list of available campaigns, please choose a
                  client above.
                </div>
              )}
            </div>
          ) : (
            <Box width="95%" bgcolor="#FFFFFF" p={4} borderRadius="10px">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="100px"
              >
                No Archived campaigns
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <SendButton
                  style={{
                    width: "150px",
                    height: "50px",
                    padding: 10,
                    color: "#7F7F7F",
                  }}
                  onClick={() => setOpen(false)}
                  disableRipple
                >
                  Close
                </SendButton>
              </Box>
            </Box>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Archived;
