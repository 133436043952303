import html2canvas from "html2canvas";
import { saveAs } from "file-saver";

export const getScreenshot = async (componentId, name) => {
  const postComponent: any = document.getElementById(componentId);

  await html2canvas(postComponent, {
    allowTaint: true,
    scale: 4,
    useCORS: true,
    scrollX: 0,
    scrollY: -window.scrollY,
  }).then(async (canvas) => {
    canvas.toBlob((blob) => {
      saveAs(blob, `${name}.png`);
    });
  });
};
