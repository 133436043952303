import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  channelIcon: {
    width: "10px",
    height: "10px",
    margin: "3px",
  },
  eventTitle: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "85%",
    textAlign: "left",
  },
  checkMarks: {
    margin: "3px",
    width: "10px",
    height: "10px",
    transform: "scale(1.5)",
  },
  info: {
    fontFamily: "Avenir Book, sans-serif",
    fontSize: 10,
    backgroundColor: "slategrey",
  },
  archiveLabel: {
    fontFamily: "'Avenir Book', sans-serif !important",
    lineHeight: "22px",
    textTransform: "capitalize",
    textDecoration: "underline",
    color: "#abadad",
  },
  printMode: {
    display: "flex",
    alignItems: "center",
    marginTop: 30,
    position: "relative",
  },
  avatar: {
    minWidth: "60px",
    minHeight: "60px",
    mixBlendMode: "normal",
  },
  name: {
    textAlign: "center",
    fontFamily: "Avenir Black, sans-serif",
    fontSize: "18px",
    marginLeft: 20,
  },
  AvatarIcon: {
    width: "40px",
    height: "40px",
    background: "#000",
  },
  AvatarSvg: {
    minWidth: "60px",
    minHeight: "60px",
    mixBlendMode: "normal",
    background: "#000",
  },
  info2: {
    display: "flex",
    justifyContent: "space-around",
    fontFamily: "Avenir Book, sans-serif",
    fontSize: 12,
    backgroundColor: "#ABADAD",
    minWidth: 80,
  },
  arrow: {
    color: "#ABADAD",
  },
});
