import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  root: {
    overflow: "hidden",
    paddingBottom: "20px",
    color: "#000000",
  },

  pageTitle: {
    height: "auto",
    fontStyle: "normal",
    fontSize: "30px",
    lineHeight: "25px",
    color: "#101010",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "40px 0 20px 0",
    fontFamily: "'Avenir Black', sans-serif",
  },

  newPageTitle: {
    fontFamily: "Avenir",
    fontSize: "30px",
    fontWeight: 900,
    lineHeight: "25px",
    letterSpacing: "0em",
    textAlign: "left",
  },

  icon: {
    width: "24.75px",
    objectFit: "contain",
  },

  cardContent: {
    fontFamily: "'Avenir Book', sans-serif",
    fontSize: "15px",
    lineHeight: "25px",
    whiteSpace: "pre-line",
    wordBreak: "break-word",
  },

  title: {
    fontFamily: "AvenirNext-DemiBold",
    fontSize: "15px",
  },

  subheader: {
    fontFamily: "AvenirNext",
    fontSize: "12px",
  },

  media: {
    "&.MuiCardMedia-img": {
      objectFit: "contain",
      height: "300px",
      paddingBottom: "20px",
    },
  },

  avatar: {
    minWidth: "60px",
    minHeight: "60px",
    mixBlendMode: "normal",
    border: "solid 1px #f2f2f2",
  },

  AvatarIcon: {
    width: "30px",
    height: "30px",
    background: "#000",
  },

  AvatarSvg: {
    minWidth: "60px",
    minHeight: "60px",
    mixBlendMode: "normal",
    background: "#000",
  },

  postCard: {
    boxShadow: "none",
    width: "100%",
    maxHeight: "fit-content",
    borderRadius: "unset",
  },

  postCardHeader: {
    padding: "30px 30px 0px 30px",
  },

  postCardContent: {
    display: "flex",
    padding: "30px",
  },

  noPosts: {
    display: "flex",
    justifyContent: "center",
    borderRadius: "15px",
    boxShadow: "0 5px 10px 0 rgba(0, 0, 0, 0.1)",
    height: "315px",
  },

  publicIcon: {
    fontSize: "15px",
  },
  postsTitle: {
    marginTop: "50px",
    fontSize: "24px",
    fontFamily: "AvenirNext-Medium",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center",
  },
  postIcons: {
    display: "flex",
  },
  contentBriefContainer: {
    display: "flex",
    border: "2px solid #C4C4C4",
    borderRadius: "10px",
    margin: "10px 30px 30px 30px",
    padding: "20px",
    fontFamily: "'Avenix Book', sans-serif",
    fontSize: 15,
    minHeight: 120,
  },
  img: {
    width: "24.75px",
    objectFit: "contain",
  },
  customTooltip: {
    backgroundColor: "white",
    color: "black",
    borderRadius: "5px",
    boxShadow: "0px 5px 5px -3px #00000033",
  },
  placeholderImg: {
    display: "none",
    justifyContent: "center",
    marginBottom: 10,
    height: 260,
  },
  briefTitle: {
    fontWeight: 600,
    marginBottom: 5,
    fontSize: 16,
  },
  postContainer: {
    marginTop: "50px",
  },
  contentPlannerRightAlign: {
    // display: "flex",
    marginBottom: "20px",
    justifyContent: "flex-start",
    "@media screen and (max-width: 750px)": {
      justifyContent: "center",
    },
  },
  smHide: {
    "@media screen and (max-width: 1050px)": {
      display: "none",
    },
  },
  mdHide: {
    "@media screen and (min-width: 1050px)": {
      display: "none",
    },
  },
  mdShow: {
    display: "block",
  },
});
