import Axios from "axios";
import { fetchMetrics } from "helpers/fetchMetrics";

export const getMetrics = async (
  user: any,
  from: any,
  to: any,
  dateRange: any,
  previousDateRange: any,
  previousClient: any,
  setMetrics: Function,
  setFbMeta: Function,
  setIgMeta: Function,
  setLoading: Function,
  setPreviousDateRange: Function,
  setPreviousClient: Function
) => {
  if (
    previousDateRange.startDate?.toLocaleDateString() ===
      dateRange.startDate?.toLocaleDateString() &&
    previousDateRange.endDate?.toLocaleDateString() ===
      dateRange.endDate?.toLocaleDateString() &&
    previousClient.email === user.email
  ) {
    return;
  }

  setLoading((loading) => ({ ...loading, pageLoading: true }));

  if (user?.facebook?.username) {
    setLoading((loading) => ({ ...loading, fbMetricsLoading: true }));

    // const results = await fetchFbIGMetrics(
    //   "facebook-metrics",
    //   user.facebook.username,
    //   from,
    //   to
    // );
    const res = await Axios.post(
      `${process.env.REACT_APP_FUNC_BASE_URL}facebook-getMetrics`,
      {
        username: user.facebook.username,
        startDate: from,
        endDate: to,
      }
    );

    let results = res.data.data;

    if (!!results.length) {
      setMetrics((metrics) => ({ ...metrics, fbMetrics: results }));
      const lastMetric = results[results.length - 1];
      const name = lastMetric?.name ? lastMetric.name : "";
      const avatar = lastMetric?.picture ? lastMetric.picture : "";
      setFbMeta({ name, avatar });
    }
    setLoading((loading) => ({ ...loading, fbMetricsLoading: false }));
  } else {
    setMetrics((metrics) => ({ ...metrics, fbMetrics: [] }));
  }

  if (user?.instagram?.username) {
    setLoading((loading) => ({ ...loading, igMetricsLoading: true }));
    const res = await Axios.post(
      `${process.env.REACT_APP_FUNC_BASE_URL}instagram-getMetrics`,
      {
        username: user.instagram.username,
        startDate: from,
        endDate: to,
      }
    );

    const results = res.data.data;
    const res2 = await Axios.post(
      `${process.env.REACT_APP_FUNC_BASE_URL}instagram-getMetrics?type=stories`,
      {
        username: user.instagram.username,
        startDate: from,
        endDate: to,
      }
    );

    const storiesResults = res2.data.data;

    if (!!results.length) {
      const lastMetric = results[results.length - 1];
      const name = lastMetric?.metadata.name;
      const avatar = lastMetric?.metadata.profile_picture_url;
      setIgMeta({ name, avatar });
      setMetrics((metrics) => ({
        ...metrics,
        igStoriesMetrics: storiesResults,
        igMetrics: results,
      }));
    }
    setLoading((loading) => ({ ...loading, igMetricsLoading: false }));
  } else {
    setMetrics((metrics) => ({
      ...metrics,
      igMetrics: [],
      igStoriesMetrics: [],
    }));
  }

  if (user?.twitter?.username) {
    setLoading((loading) => ({ ...loading, twitterMetricsLoading: true }));

    let res = await Axios.post(
      `${process.env.REACT_APP_FUNC_BASE_URL}twitter-getMetric`,
      {
        username: user.twitter.username,
        startDate: from,
        endDate: to,
      }
    );
    let results = res.data.data;

    if (!!results.length) {
      setMetrics((metrics) => ({ ...metrics, twitterMetrics: results }));
    }
    setLoading((loading) => ({ ...loading, twitterMetricsLoading: false }));
  } else {
    setMetrics((metrics) => ({ ...metrics, twitterMetrics: [] }));
  }
  if (user?.tiktok?.username) {
    setLoading((loading) => ({ ...loading, tiktokMetricsLoading: true }));
    let res = await Axios.post(
      `${process.env.REACT_APP_FUNC_BASE_URL}tiktok-getMetrics`,
      {
        username: user.tiktok.username,
        userId: user.id,
        startDate: from,
        endDate: to,
      }
    );
    let results = res.data.data;

    if (!!results.length) {
      setMetrics((metrics) => ({ ...metrics, tiktokMetrics: results }));
    }
    setLoading((loading) => ({ ...loading, tiktokMetricsLoading: false }));
  } else {
    setMetrics((metrics) => ({ ...metrics, tiktokMetrics: [] }));
  }

  if (user?.youtube?.username) {
    setLoading((loading) => ({ ...loading, youtubeMetricsLoading: true }));

    const data = await Axios.post(
      `${process.env.REACT_APP_FUNC_BASE_URL}youtube-dbMetric`,
      {
        username: user.youtube.username,
        from,
        to,
      }
    );
    const results = data.data;
    // const results = data.data;
    if (!!results.length) {
      setMetrics((metrics) => ({ ...metrics, youtubeMetrics: results }));

      setLoading((loading) => ({ ...loading, youtubeMetricsLoading: false }));
    }
  } else {
    setMetrics((metrics) => ({ ...metrics, youtubeMetrics: [] }));
  }
  if (user?.appleMusic?.username?.length) {
    const results = await fetchMetrics(
      "apple-music-metrics",
      user.appleMusic.username,
      from,
      to
    );
    if (!!results.length)
      setMetrics((metrics) => ({ ...metrics, appleMusicMetrics: results }));
  } else {
    setMetrics((metrics) => ({ ...metrics, appleMusicMetrics: [] }));
  }
  if (user?.spotify?.username) {
    let res = await Axios.post(
      `${process.env.REACT_APP_FUNC_BASE_URL}spotify-getMetrics`,
      {
        username: user.spotify.username,
        startDate: from,
        endDate: to,
      }
    );
    let results = res.data.data;
    if (!!results.length) {
      setMetrics((metrics) => ({ ...metrics, spotifyMetrics: results }));
    }
  } else {
    setMetrics((metrics) => ({ ...metrics, spotifyMetrics: [] }));
  }
  setLoading((loading) => ({ ...loading, pageLoading: false }));
  setPreviousDateRange(dateRange);
  setPreviousClient(user);
};
