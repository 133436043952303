/**
 * @prettierdesc
 */
import React, { FC, useState } from "react";
import { Box, Tooltip, CircularProgress, Typography } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import history from "history/browser";
import cuid from "cuid";
import CreateCampaignStyles from "components/styled/CreateCampaign";
import Image from "components/Common/Image/Image";
import instagram from "statics/media/instagram-vector.png";
import defaultIcon from "statics/media/universal-2.png";
import { SendButton } from "components/styled/Common";
import { PageType } from "types/PageType";
import GetCookies from "components/Common/GetCookies";
import CampaignName from "components/Common/CampaignName";
import { useUser } from "UserContext";
import { instagramAutoCommenter } from "helpers/autoCommenter";
import { SocialMediaPlatform } from "../../SelectCampaignPage";

interface CreateAutoCommenterProps extends PageType {
  uid?: string;
  toggleCreate?: Function;
}

export const Instagram: FC<CreateAutoCommenterProps> = ({
  toggleCreate,
  uid,
}) => {
  const [user]: any = useUser();
  const client = Array.isArray(user) ? user[0][0] : user;
  const [campaignName, setCampaignName] = useState("");
  const [sessionCookie, setSessionCookie] = useState("");
  const [spreadsheetUrl, setSpreadsheetUrl] = useState("");
  const [numberOfPostsPerLaunch, setNumberOfInputsPerLaunch] = useState(10);
  const [errors, setErrors] = useState({
    client: "",
    campaignName: "",
    spreadsheetUrl: "",
    numberOfPostsPerLaunch: "",
  });
  const [loading, setLoading] = useState(false);

  const id = cuid();

  const createCampaign = async () => {
    if (!client)
      return setErrors((errors: any) => ({
        ...errors,
        client: "Client is required!",
      }));
    if (!campaignName)
      return setErrors((errors: any) => ({
        ...errors,
        campaignName: "Campaign name is required!",
      }));
    if (!spreadsheetUrl)
      return setErrors((errors: any) => ({
        ...errors,
        spreadsheetUrl: "Spreadsheet Url is required!",
      }));

    if (!spreadsheetUrl.includes("https://docs.google.com/spreadsheets/d/")) {
      return setErrors((errors: any) => ({
        ...errors,
        spreadsheetUrl: "Enter a valid spreadsheet URL!",
      }));
    }

    if (
      !numberOfPostsPerLaunch ||
      numberOfPostsPerLaunch > 80 ||
      numberOfPostsPerLaunch < 1
    ) {
      return setErrors((errors: any) => ({
        ...errors,
        numberOfPostsPerLaunch:
          "Enter a valid value for the number of posts to process per launch!",
      }));
    }

    if (sessionCookie === "") {
      toast.error("Error: Add a sessionId to proceed", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });

      return;
    } else {
      setLoading(true);
      const res = await instagramAutoCommenter({
        id,
        spreadsheetUrl,
        sessionCookie: client?.instagram?.sessionCookieXs || sessionCookie,
        campaignName,
        username: client?.instagram?.username,
        numberOfPostsPerLaunch,
        delayBetween: 5,
      });
      if (res.message) {
        toast.error(`Error: ${res.message}`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      } else {
        setCampaignName("");
        setSessionCookie("");
        setSpreadsheetUrl("");
      }
      setLoading(false);
    }
  };

  return (
    <CreateCampaignStyles>
      <ToastContainer />
      <div>
        <span className="title__main">{uid ? "Edit" : "Create"} Campaign</span>
        <Box marginTop="50px">
          <div className="items-center">
            <Image
              className="img__main"
              src={instagram || defaultIcon}
              alt=""
            />
            <span className="title__sub">Instagram Auto Commenter</span>
          </div>
        </Box>
      </div>
      <CampaignName
        campaignName={campaignName}
        setCampaignName={setCampaignName}
        error={errors.campaignName}
        setErrors={setErrors}
      />
      <GetCookies
        setSessionCookie={setSessionCookie}
        sessionCookie={sessionCookie}
        title="Your Instagram SessionId "
        platform={SocialMediaPlatform.Instagram}
        platformIcon={instagram}
      />
      {/* More info */}
      <Box marginTop="40px" className="connection-container">
        <div className="campaign__container">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <span className="title__basic">Instagram Posts To comment On</span>
            <Tooltip
              title={
                <Box>
                  <h1>
                    Tell us which Instagram posts you would like it to comment
                    on.
                  </h1>
                  <Box fontSize={14}>
                    <div>
                      Create a spreadsheet on{" "}
                      <a
                        href="https://docs.google.com/spreadsheets/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Google Spreadsheet.
                      </a>{" "}
                      Fill it with your Instagram posts. One name per row, all
                      in column A, and on column B add the comment you would
                      like to add
                    </div>
                    <br />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/be-measured-admin-staging.appspot.com/o/images%2Fig-auto-commenter-image.png?alt=media&token=75091101-ee9f-4574-b89b-0c9607af4342"
                      alt="instagram-posts"
                      width="88%"
                    />
                    <br />
                    <div>Make this spreadsheet public so we can access it:</div>
                    <br />
                    <img
                      src="https://firebasestorage.googleapis.com/v0/b/be-measured-admin-staging.appspot.com/o/images%2Fshare%20spreadsheet.png?alt=media&token=d1aaa739-4d5a-40a0-9562-8b82a633d61b"
                      alt="share spreadsheet"
                      width="98%"
                    />
                    <br />

                    <div>And paste that spreadsheet's URL back here.</div>
                  </Box>
                </Box>
              }
              arrow
              interactive
            >
              <InfoIcon style={{ color: "#C4C4C4" }} />
            </Tooltip>
          </Box>
          <span className="description__sub">
            Must be a google spreadsheet URL.
          </span>
          <input
            type="text"
            value={spreadsheetUrl}
            placeholder="https://docs.google.com/spreadsheets/d/(...)"
            onChange={(e) => {
              setSpreadsheetUrl(e.target.value);
              setErrors((errors: any) => ({
                ...errors,
                spreadsheetUrl: "",
              }));
            }}
            className="campaign__input"
          />
          {errors.spreadsheetUrl && (
            <span style={{ color: "red", fontSize: 14, paddingLeft: 6 }}>
              {errors.spreadsheetUrl}
            </span>
          )}
        </div>
        <div className="campaign__container">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography className="title__basic">
              Number Of Posts To Comment On Per Launch
            </Typography>

            <Tooltip
              title={
                <Box>
                  <Typography variant="body1">
                    There's a limit to how much you can automate on Instagram.
                  </Typography>
                  <Typography variant="body1">
                    We recommend you process a maximum of{" "}
                    <strong>80 comments per day</strong> in order to keep your
                    account safe.{" "}
                  </Typography>
                </Box>
              }
              arrow
              interactive
            >
              <InfoIcon style={{ color: "#C4C4C4" }} />
            </Tooltip>
          </Box>
          <span className="description__sub">
            A maximum of 80 posts is allowed to be processed per launch.
          </span>
          <input
            type="number"
            value={numberOfPostsPerLaunch}
            className="campaign__input campaign__input--disabled"
            onChange={(e) => setNumberOfInputsPerLaunch(Number(e.target.value))}
          />
          {errors.numberOfPostsPerLaunch && (
            <span style={{ color: "red", fontSize: 14, paddingLeft: 6 }}>
              {errors.numberOfPostsPerLaunch}
            </span>
          )}
        </div>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginTop="40px"
      >
        <div
          className="title__sub link"
          onClick={() => {
            toggleCreate && toggleCreate();
            !toggleCreate && history.back();
          }}
        >
          {"<"} Back
        </div>
        <SendButton
          onClick={(e) => {
            e.preventDefault();
            !loading && createCampaign();
          }}
          className="btn"
          disabled={loading}
        >
          {uid
            ? `Updat${loading ? "ing" : "e"}`
            : `Launch${loading ? "ing" : ""}`}{" "}
          Campaign
          {loading && <CircularProgress className="btn-progress" size={20} />}
        </SendButton>
      </Box>
    </CreateCampaignStyles>
  );
};
