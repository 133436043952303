import styled from "styled-components";

export const BePlannedContainer = styled.div`
  .post-container {
    display: flex;
    margin-top: 10px;
    margin-right: 50px;
    border-radius: 15px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    flex-direction: column;
    background: #cccccc;
  }

  @media screen and (max-width: 768px) {
    #client-list > span,
    #client-list > div > div {
      margin-right: 0px;
    }
    #be-planned-title > div {
      flex-direction: column;
      & > div {
        margin-bottom: 20px;
      }
    }

    #client-list {
      width: 100%;

      & > div {
        width: 100%;

        & > div {
          width: 100%;
        }
      }
    }

    .post-container {
      margin-right: 0;
    }
  }
`;
