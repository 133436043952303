import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  // container: {
  //   display: "flex",
  //   justifyContent: "center",
  //   height: "100%",
  // },
  // close: {
  //   color: "#FF5269",
  //   minWidth: "20px",
  //   marginLeft: "12px",
  //   position: "absolute",
  //   right: 0,
  //   top: 0,
  // },
  // videoPreview: {
  //   display: "flex",
  //   justifyContent: "center",
  //   paddingBottom: 20,
  // },
  // vimeo: {
  //   width: "99% !important",
  //   display: "flex",
  //   justifyContent: "center",
  //   "& iframe": {
  //     width: "470px !important",
  //     height: (props: any) => props.isCarousel && "470px !important",
  //   },
  // },
  // videoContainer: {
  //   position: "relative",
  //   width: "100%",
  //   display: "flex",
  //   justifyContent: "center",
  // },
  container: {
    // display: "flex",
    // justifyContent: "center",
    height: (props: any) => props.edit && "336px",
  },
  close: {
    color: "#FF5269",
    minWidth: "20px",
    marginLeft: "12px",
    position: "absolute",
    zIndex: 100,
    right: 0,
    top: 0,
  },
}));
