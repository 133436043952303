import styled from "styled-components";

export const BeMeasuredContainer = styled.div`
  @media screen and (max-width: 768px) {
    & > div > :nth-child(2) {
      flex-direction: column;
      align-items: center !important;
    }
    & > div > :nth-child(2) > div svg {
      margin-right: 0px !important;
    }
    #date-picker {
      margin-top: 10px;
    }
  }
`;
