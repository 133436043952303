import React, { FC } from "react";
import WarningIcon from "@material-ui/icons/Warning";
import { navigate } from "@reach/router";

import { signOut } from "hooks/AuthHook";

// Import components
import BlueScreenContainer from "components/styled/BlueScreenContainer";

const BlueScreen: FC = () => (
  <BlueScreenContainer>
    <div className="content-container">
      <div className="header">
        <div className="" onClick={() => navigate("/be-measured")}>
          Home
        </div>
        <div
          className=""
          onClick={() => {
            signOut();
            navigate("/sign-in");
          }}
        >
          Sign out
        </div>
        <div
          className=""
          onClick={() => {
            signOut();
            navigate("/sign-in");
          }}
        >
          Add Social Account
        </div>
      </div>
      <div className="content">
        <WarningIcon style={{ fontSize: "3rem" }} />
        <h1>Website Error</h1>
        <p style={{ lineHeight: "24px" }}>
          Unfortunately, there has been an error in our page. Please try:
          <br />
          (1) refreshing by clicking the button below, <br />
          (2) signing out by clicking the button in the top right, <br />
          (3) email us at <strong>kit@be-hookd.com</strong>
        </p>
        <div
          className="home"
          style={{ justifyContent: "center", margin: "20px 0px 0px 0px" }}
        >
          <span onClick={() => window.location.reload(true)}>Refresh</span>
        </div>
      </div>
    </div>
  </BlueScreenContainer>
);

export default BlueScreen;
