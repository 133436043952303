import axios from "axios";
import cuid from "cuid";
import firebase from "firebase/app";
import { DateTime } from "luxon";

import { firestore } from "../../../firebase-tools/firebase";

export const sendNotification = (
  type: string,
  feedback: string,
  postData: {
    clientName: string;
    clientEmail: string;
    date: any;
    title: string;
  }
): any => {
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_FUNC_BASE_URL}email-notification`,
    headers: { "Content-Type": "application/json" },
    data: {
      type,
      feedback,
      postData,
    },
  })
    .then((response: any) => {
      return response;
    })
    .catch((error: any) => {
      console.log(error);
    });
};

export const saveFeedback = async (
  type: string,
  feedbackText: string,
  postData: {
    id: string;
    clientName: string;
    userEmail: string;
    date: any;
    title: string;
  },
  clientEmail: string
) => {
  const feedbackHtml = generateTemplate(type, feedbackText, postData);
  const existingFeedback = (
    await firestore
      .collection("posts-feedback")
      .where("user_email", "==", clientEmail)
      .get()
  ).docs;
  if (existingFeedback.length) {
    const doc = existingFeedback[0];
    await firestore
      .collection("posts-feedback")
      .doc(doc.id)
      .update({
        feedback: firebase.firestore.FieldValue.arrayUnion(feedbackHtml),
      });
  } else {
    const feedback = {
      user_email: clientEmail,
      client_name: postData.clientName,
      feedback: [feedbackHtml],
      created_at: new Date(),
      post_id: postData.id,
    };
    await firestore.collection("posts-feedback").doc(cuid()).set(feedback);
  }
};

const feedbackMessage = (type: string, feedbackText: string) => {
  switch (type) {
    case "approval":
      return `
      The post was set to <b>${feedbackText}</b>.<br/><br/>
      `;
    case "comment":
      return `<b>Feedback text:</b><br/>
      ${feedbackText}<br/><br/>
      `;
    default:
      return "";
  }
};

const generateTemplate = (
  type: string,
  feedbackText: string,
  postData: {
    clientName: string;
    userEmail: string;
    date: any;
    title: string;
  }
) => {
  const env =
    process.env.REACT_APP_PROJECTID === "be-measured-admin-staging"
      ? "-staging"
      : "";
  let startDate: any = postData.date;
  const endDate = startDate.plus({ days: 1 }).toFormat("dd-LL-yyyy");
  startDate = startDate.toFormat("dd-LL-yyyy");
  const postDateTime = postData.date.toFormat("dd-LL-yyyy HH:mm");
  const feedbackDateTime = DateTime.fromJSDate(new Date()).toFormat(
    "dd-LL-yyyy HH:mm:ss"
  );
  const url = `https://admin${env}.be-hookd.com/be-planned-feed?start=${startDate}&end=${endDate}`;
  return `
    <p>
    ${feedbackMessage(type, feedbackText)}
    Client Name: ${postData.clientName} <br/>
    User email: ${postData.userEmail} <br/>
    Post date: ${postDateTime} <br/>
    Post title: ${postData.title}<br/>
    Feedback date: ${feedbackDateTime}<br/>
    <span>You can see the post on <a href=${url}>Be-Planned</a>.</span>
    </p><hr/>
    `;
};
