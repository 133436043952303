import React, { FC, useState } from "react";
import styled from "styled-components";
import Collapse from "@material-ui/core/Collapse";

import { useUser } from "UserContext";

import { useStyles } from "../styles";
import { useStyles as useNoteStyles } from "./styles";
import NoteField from "./NoteField";
import Note, { NoteType } from "./Note";
import { IUser } from "interfaces/IUser";

interface NotesProps {
  user: IUser;
  note: string;
  setNote: (note: string) => void;
  notes: Array<any>;
  post?: any;
  showAuthPrompt: any;
}

export const Title = styled.div`
  font-family: "Avenir Black", sans-serif;
  font-size: 15px;
  font-weight: 900;
`;
const ExpandButton = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 10px;
  line-height: 25px;
  color: #abadad;
  margin-left: 14px;
  font-family: "Avenir Book", sans-serif;
`;

const Notes: FC<NotesProps> = ({
  user,
  note,
  setNote,
  notes,
  post,
  showAuthPrompt,
}) => {
  const classes = useStyles({});
  const notesClass = useNoteStyles({});
  const [open, setNotesOpen] = useState(true);
  const [currentUser] = useUser() as any;

  let author;

  if (currentUser?.email !== user.email) {
    author = currentUser;
  } else {
    author = user;
  }

  return (
    <div className={classes.field} style={{ marginTop: 30 }}>
      <Title>Write a Comment</Title>
      <NoteField
        user={author}
        note={note}
        setNote={setNote}
        post={post}
        showAuthPrompt={showAuthPrompt}
      />
      <div className={notesClass.noteTitle}>
        <Title style={{}}>Comments {"(" + notes.length + ")"}</Title>
        <ExpandButton onClick={() => setNotesOpen(!open)}>
          {" "}
          {open ? "Show Less" : "Show More"}
        </ExpandButton>
      </div>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {notes.map((noteObject: NoteType) => (
          <Note
            note={noteObject}
            noteType="internal"
            post={post}
            key={noteObject.id}
            authUser={author}
            showAuthPrompt={showAuthPrompt}
          />
        ))}
      </Collapse>
    </div>
  );
};

export default Notes;
