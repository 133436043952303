import React from "react";

import { toast } from "react-toastify";
import { Check, Error } from "@material-ui/icons";

export const success = (message) => {
  toast.dark(
    <div style={{ display: "flex", alignItems: "center" }}>
      {message} <Check style={{ marginLeft: 8 }} />
    </div>,
    {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    }
  );
};

export const successAsync = async (message, closeTime = 5000) => {
  return new Promise((resolve) => {
    const toastId = toast.dark(
      <div style={{ display: "flex", alignItems: "center" }}>
        {message} <Check style={{ marginLeft: 8 }} />
      </div>,
      {
        position: "bottom-center",
        autoClose: closeTime,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      }
    );
    resolve(toastId);
  });
};

export const warning = (message, closeTime = 5000) => {
  toast.dark(
    <div style={{ display: "flex", alignItems: "center" }}>
      {message} <Error style={{ marginLeft: 8 }} />
    </div>,
    {
      position: "bottom-center",
      autoClose: closeTime,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    }
  );
};

export const failure = (message: string, closeTime = 5000) => {
  toast.dark(
    <div style={{ display: "flex", alignItems: "center" }}>{message}</div>,
    {
      position: "bottom-center",
      autoClose: closeTime,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    }
  );
};

// create a async failure function
export const failureAsync = async (message: string, closeTime = 5000) => {
  return new Promise((resolve) => {
    const toastId = toast.error(
      <div style={{ display: "flex", alignItems: "center" }}>{message}</div>,
      {
        position: "bottom-center",
        autoClose: closeTime,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      }
    );

    setTimeout(() => {
      if (toast.isActive(toastId)) {
        resolve(false);
      }
    }, closeTime + 100);
  });
};
