import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  card: {
    width: "40%",
    minHeight: "300px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "30px",

    "@media screen and (max-width: 600px)": {
      width: "94%",
    },
  },
  root: {
    fontFamily: "Avenir Black, sans-serif",
    marginTop: "115px",
    display: "flex",
    justifyContent: "center",
  },
}));
