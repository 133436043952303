import { navigate } from "@reach/router";
import { SocialMediaPlatform } from "containers/BeAmplified/Campaigns/Organic/SelectCampaignPage";

export const facebookAutoLiker = async ({
  id,
  spreadsheetUrl,
  sessionCookieCUser,
  sessionCookieXs,
  campaignName,
  username,
  numberOfLikesPerProfile,
  numberOfPostsToLoad,
  numberOfInputsPerLaunch,
}) => {
  if (!process.env.REACT_APP_FUNC_BASE_URL) {
    console.log("Invalid url");
    return;
  }
  const rawResponse = await fetch(
    `${process.env.REACT_APP_FUNC_BASE_URL}facebook-autoLiker`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id,
        spreadsheetUrl,
        sessionCookieCUser,
        sessionCookieXs,
        campaignName,
        username,
        numberOfLikesPerProfile,
        numberOfPostsToLoad,
        numberOfInputsPerLaunch,
      }),
    }
  );
  const result = await rawResponse.json();
  navigate(
    `/campaigns/organic/auto-liker/${SocialMediaPlatform.Facebook.toLowerCase()}/${
      result?.id
    }`
  );

  return result;
};

export const twitterAutoLiker = async ({
  id,
  spreadsheetUrl,
  sessionCookie,
  campaignName,
  username,
  likesCountPerProfile,
}) => {
  if (!process.env.REACT_APP_FUNC_BASE_URL) {
    console.log("Invalid url");
    return;
  }
  const rawResponse = await fetch(
    `${process.env.REACT_APP_FUNC_BASE_URL}twitter-autoLiker`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id,
        spreadsheetUrl,
        sessionCookie,
        campaignName,
        username,
        likesCountPerProfile,
      }),
    }
  );
  const result = await rawResponse.json();
  navigate(
    `/campaigns/organic/auto-liker/${SocialMediaPlatform.Twitter.toLowerCase()}/${id}`
  );

  return result;
};

export const instagramAutoLiker = async ({
  id,
  spreadsheetUrl,
  sessionCookie,
  campaignName,
  username,
  numberOfRowsPerLaunch,
  numberOfPostsPerProfile,
}) => {
  if (!process.env.REACT_APP_FUNC_BASE_URL) {
    console.log("Invalid url");
    return;
  }
  const rawResponse = await fetch(
    `${process.env.REACT_APP_FUNC_BASE_URL}instagram-autoLiker`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id,
        spreadsheetUrl,
        sessionCookie,
        campaignName,
        username,
        numberOfRowsPerLaunch,
        numberOfPostsPerProfile,
      }),
    }
  );
  const result = await rawResponse.json();
  navigate(
    `/campaigns/organic/auto-liker/${SocialMediaPlatform.Instagram.toLowerCase()}/${
      result?.id
    }`
  );

  return result;
};
