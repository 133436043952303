import React, { FunctionComponent, useState, useEffect } from "react";
import { Grid, Box } from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fromEvent, Observable } from "rxjs";

import FacebookIcon from "../../statics/media/fb-vector.png";
import InstagramIcon from "../../statics/media/instagram-vector.png";

import Name from "components/Common/Form/Name";
import { useStyles } from "./styles";
import { IUser } from "interfaces/IUser";
import { navigate } from "@reach/router";

import { sliceToBackslash } from "../../utils/stringFormat";
import { useUnsaved } from "LinkContext";
import { firebase } from "../../firebase-tools/firebase";
import { CancelButton } from "components/styled/Common";
import ClientInfoStyled from "components/styled/ClientInfo";
import { warning } from "utils/notification";
import { Toast } from "components/styled/Common";
import { cancelReloadTabClose } from "utils/navigate";
import { checkChange } from "./helpers/checkChange";
import { handleCreateEdit } from "./helpers/createEdit";
import SocialMediaField from "./SocialMediaField";

type ClientInfoType = {
  userInfo: IUser;
  create?: boolean;
};


export const ClientInfo: FunctionComponent<ClientInfoType> = ({
  userInfo,
  create,
}) => {
  const [error, setError] = useState("");
  const [, setIsSaving] = useState(false);
  const [, setIsInviting] = useState(false);
  const classes = useStyles({});
  const [secEmails, setEmails] = useState(userInfo?.secondaryEmails || []);
  const [toNotify, setToNotify] = useState(true);
  const [, setSecondaryUserExist] = useState("");
  const [avatar, ]: any = useState("");
  const [requestType, ] = useState("save");
  const [dupError, ] = useState("");
  const [dataErrors, setDataErrors]: any = useState({
    igError: userInfo?.instagram?.error || "",
    fbError: userInfo?.facebook?.error || "",
    twError: userInfo?.twitter?.error || "",
  });
  const [retry, setRetry] = useState(false);
  const [toSave, setToSave] = useState(false);
  const [, setUnsaved] = useUnsaved() as any;

  const [mobileMenu, switchMobileMenu] = useState(
    !(document.documentElement.offsetWidth > 500)
  );

  const resize$: Observable<Event> = fromEvent(window, "resize");

  resize$.subscribe((res: any) => {
    if (res && res.currentTarget) {
      switchMobileMenu(!(res.currentTarget.innerWidth > 500));
    }
  });



  const cancel = async () => {
    navigate("/be-measured");
    if (create) {
      if (avatar.includes("firebasestorage")) {
        const avatarRef = await firebase.storage().refFromURL(avatar);
        await avatarRef.delete();
      }
    }
    setUnsaved(false);
  };

  useEffect(() => {
    setDataErrors({
      igError: "",
      fbError: "",
      twError: "",
    });
    // setDataErrors({
    //   igError: userInfo?.instagram?.error,
    //   fbError: userInfo?.facebook?.error,
    //   twError: userInfo?.twitter?.error,
    // });
    if (userInfo.secondaryEmails) {
      if (secEmails.length !== userInfo.secondaryEmails.length) {
        toNotify && warning("Another user just saved changes to this page");
      }
    }
    setEmails(userInfo?.secondaryEmails || []);
    // eslint-disable-next-line
  }, [userInfo]);

  useEffect(() => {
    cancelReloadTabClose(toSave);
    setUnsaved(toSave);
    // eslint-disable-next-line
  }, [toSave]);

  return (
    <ClientInfoStyled>
      {toNotify && (
        <Toast>
          <ToastContainer progressClassName="toast-progressbar" />
        </Toast>
      )}
      <Formik
        enableReinitialize={true}
        initialValues={{
          clientName: !userInfo.firstName
            ? ""
            : `${userInfo?.firstName} ${userInfo?.lastName}`,
          email: userInfo ? userInfo?.email : "",
          facebook_username: create ? "" : userInfo?.facebook?.username,
          instagram_username: create ? "" : userInfo?.instagram?.username,
          twitter_username: create ? "" : userInfo?.twitter?.username,
          tiktok_username: create ? "" : userInfo?.tiktok?.username || "",
          channel_id: create ? "" : userInfo?.youtube?.username || "",
          spotifyId: create ? "" : userInfo?.spotify?.username || "",
          appleMusicId: create ? "" : userInfo?.appleMusic?.username || "",
          secondaryEmails: secEmails,
          notes: userInfo?.notes || "",
          isExternal: userInfo?.isExternal || false,
        }}
        onSubmit={(values) => {
          const facebook: any = sliceToBackslash(values.facebook_username);
          values.facebook_username = facebook;
          const instagram: any = sliceToBackslash(values.instagram_username);
          values.instagram_username = instagram;
          const twitter: any = sliceToBackslash(values.twitter_username);
          values.twitter_username = twitter;
          const tiktok: any = sliceToBackslash(values.tiktok_username);
          values.tiktok_username = tiktok;
          const youtube: any = sliceToBackslash(values.channel_id, "youtube");
          values.channel_id = youtube;
          const spotify: any = sliceToBackslash(values.spotifyId, "spotify");
          values.spotifyId = spotify;
          const appleMusic: any = sliceToBackslash(values.appleMusicId);
          values.appleMusicId = appleMusic;
          handleCreateEdit(
            values,
            userInfo,
            create,
            dupError,
            requestType,
            avatar,
            error,
            setSecondaryUserExist,
            setIsInviting,
            setIsSaving,
            setError,
            retry,
            dataErrors,
            setDataErrors,
            setToSave,
            setToNotify
          );
        }}
        validationSchema={Yup.object().shape({
          clientName: Yup.string().required("Required!"),
          email: Yup.string().email("Invalid email!").required("Required!"),
          secondaryEmails: Yup.array().of(
            Yup.object().shape({
              email: Yup.string().email("Invalid email!").required("Required!"),
            })
          ),
        })}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            handleChange,
            handleSubmit,
          } = props;
          return (
            <form noValidate onSubmit={handleSubmit} className={classes.form}>
              <Grid item xs={12} className={classes.bar} container>
                <Box className={classes.title} component="p">
                  {create ? "Create " : "Edit "}
                  Client
                </Box>
                <Box className={classes.actions}>
                  <CancelButton
                    disableRipple
                    type="button"
                    style={{ marginRight: "30px" }}
                    classes={{
                      root: classes.button,
                    }}
                    onClick={cancel}
                  >
                    Cancel
                  </CancelButton>
                </Box>
              </Grid>
              <Grid item container xs={12} style={{ height: "100%" }}>
                <Grid
                  item
                  container
                  lg={5}
                  md={12}
                  xs={12}
                  justify="flex-start"
                  alignItems="flex-start"
                  className={classes.client}
                  style={{ height: "fit-content" }}
                >
                  <Grid item xs={12} style={{ height: "92px" }}>
                    <p
                      className={classes.inputLabel}
                      style={{ color: "#000000" }}
                    >
                      Client Name
                    </p>
                    <Name
                      name="clientName"
                      color="#000000"
                      label=""
                      variant="outlined"
                      width="100%"
                      value={values.clientName}
                      placeholder="Enter Client Name"
                      error={
                        errors.clientName &&
                        touched.clientName &&
                        !values.clientName
                      }
                      iconWidth="24px"
                      onChangeHandler={(e) => {
                        checkChange(e, "name", userInfo, setToSave);
                        handleChange(e);
                      }}
                      readonly={true}
                      borderColor={
                        errors.clientName && touched.clientName
                          ? "#f44336"
                          : "#000"
                      }
                      helperText={
                        errors.clientName &&
                        touched.clientName &&
                        errors.clientName
                      }
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={2}></Grid>
                <Grid
                  item
                  container
                  lg={5}
                  md={12}
                  xs={12}
                  className="socialMedia"
                >
                  <Grid item container xs={12} style={{ maxWidth: 450 }}>
                    <Box component="p" width={1} className={classes.subTitle}>
                      Social Media Accounts
                    </Box>

                    <SocialMediaField
                      value={{
                        instagram: values.instagram_username,
                        facebook: values.facebook_username,
                      }}
                      touched={touched}
                      errors={errors}
                      userInfo={userInfo}
                      mediaName="instagram"
                      link="https://instagram.com/"
                      name="instagram_username"
                      placeholder="instagram-username"
                      dataError={dataErrors.igError}
                      MediaIcon={InstagramIcon}
                      checkChange={checkChange}
                      handleChange={handleChange}
                      setRetry={setRetry}
                      setToSave={setToSave}
                      mobileMenu={mobileMenu}
                    />
                    <SocialMediaField
                      value={values.facebook_username}
                      touched={touched}
                      errors={errors}
                      userInfo={userInfo}
                      mediaName="facebook"
                      link="https://facebook.com/"
                      name="facebook_username"
                      placeholder="facebook-username"
                      dataError={dataErrors.fbError}
                      MediaIcon={FacebookIcon}
                      checkChange={checkChange}
                      handleChange={handleChange}
                      setRetry={setRetry}
                      setToSave={setToSave}
                      mobileMenu={mobileMenu}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </ClientInfoStyled>
  );
};
