import React, { FC } from "react";
import { FormControlLabel, FormControl, Checkbox } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { ReactComponent as CheckedIcon } from "statics/media/checkedIcon.svg";
import { ReactComponent as UncheckedIcon } from "statics/media/uncheckedIcon.svg";

import { capitalize } from "utils/stringFormat";
import { SocialMediaPlatform } from "containers/BeAmplified/Campaigns/Organic/SelectCampaignPage";

const useStyles = makeStyles({
  fieldset: {
    borderBottom: "1px solid #C4C4C4",
    borderTop: "1px solid #C4C4C4",
  },
});

interface ICampaign {
  selected: any;
  setSelected: (selected: any) => void;
  platform: SocialMediaPlatform;
  selectedArr: string[];
  setSelectedArr: (arr: string[]) => void;
}

const updateArray = (arr, val) => {
  const i = arr.indexOf(val);
  if (i > -1) {
    arr.splice(i, 1);
  } else {
    arr.push(val);
  }
};
const twitterCampaignTypes = [
  "Auto Followers",
  "Auto Poster",
  "Auto Direct Message",
  "Auto Liker",
];
const facebookTypes = ["Auto Liker", "Group Scrapper", "Auto Direct Message"];
const instagramCampaignTypes = [
  "Auto Followers",
  "Auto Liker",
  "Auto Commenter",
];

const getCampaignTypes = (platform: SocialMediaPlatform) => {
  switch (platform) {
    case SocialMediaPlatform.Instagram:
      return instagramCampaignTypes;
    case SocialMediaPlatform.Twitter:
      return twitterCampaignTypes;
    case SocialMediaPlatform.Facebook:
      return facebookTypes;
    default:
      return [];
  }
};

const CampaignType: FC<ICampaign> = ({
  selected,
  setSelected,
  platform,
  selectedArr,
  setSelectedArr,
}) => {
  const classes = useStyles({});

  const isSelected = (value: string) => {
    return selectedArr.includes(value);
  };

  const handleChange = (event, platform) => {
    const newList: string[] = [...selected[platform]];
    updateArray(newList, event.target.value);
    setSelected({
      ...selected,
      [platform]: newList,
    });
  };

  const campaignTypes = getCampaignTypes(platform);

  return (
    <FormControl
      component="fieldset"
      style={{ width: "100%", padding: "20px 0px 20px 0px" }}
      className={classes.fieldset}
    >
      {campaignTypes.map((type, i) => (
        <FormControlLabel
          key={`type-${i}`}
          value={`${capitalize(platform)} ${type}`}
          control={
            <Checkbox
              key={`type-${i}`}
              checked={isSelected(`${capitalize(platform)} ${type}`)}
              style={{ color: "#000" }}
              onChange={(e) => {
                handleChange(e, platform.toLowerCase());
                const newList = selectedArr;
                updateArray(newList, `${capitalize(platform)} ${type}`);
                setSelectedArr(newList);
              }}
              icon={<UncheckedIcon />}
              checkedIcon={<CheckedIcon />}
            />
          }
          label={`${capitalize(platform)} ${type}`}
        />
      ))}
    </FormControl>
  );
};

export default CampaignType;
