import moment from "moment";
import firebase from "firebase/app";

import { signOut } from "./../hooks/AuthHook";
import { firestore } from "../firebase-tools/firebase";
import { dateObjectFromStr, longDate } from "./dateFormat";

export const updateUserPageVisit = async (user: any, agent?: any) => {
  const userRef = await firestore.collection("users").doc(user.id);

  const currentUser: any = (await userRef.get()).data();

  if (!currentUser) return signOut();

  try {
    let today: any = longDate(new Date());

    if (today.includes("Invalid")) {
      today = moment().format("DD MMM YYYY");
    }

    if (agent) {
      const { toUpdate } = getPageVisits(agent, today);

      if (toUpdate) {
        const agentRef = await firestore
          .collection("client-agents")
          .doc(agent.id);
        await agentRef.update({
          page_visit: firebase.firestore.FieldValue.arrayUnion(today),
        });
      }
      let update;

      const secEmail = currentUser.secondaryEmails?.filter(
        (user) => user.email === agent.email
      );

      const updateEmail = { ...secEmail[0] };

      if (secEmail.length) {
        const { updatedVisitArr, toUpdate } = getPageVisits(updateEmail, today);
        updateEmail.page_visit = updatedVisitArr;
        update = toUpdate;
      }

      if (update) {
        await userRef.update({
          secondaryEmails: firebase.firestore.FieldValue.arrayRemove(
            secEmail[0]
          ),
        });

        await new Promise((resolve) => setTimeout(resolve, 1000));

        await userRef.update({
          secondaryEmails: firebase.firestore.FieldValue.arrayUnion(
            updateEmail
          ),
        });
      }
    } else {
      const { toUpdate } = getPageVisits(currentUser, today);
      if (toUpdate) {
        await userRef.update({
          page_visit: firebase.firestore.FieldValue.arrayUnion(today),
        });
      }
    }
    return true;
  } catch (err) {
    console.log(err);
    throw new Error(err);
  }
};

const getPageVisits = (data, today) => {
  let updatedVisitArr: Array<any> = [];
  let toUpdate = true;
  if (data.page_visit && Array.isArray(data.page_visit)) {
    updatedVisitArr = data.page_visit.map((item) => {
      // this ensures date format existing in local storage are
      //formatted b4 the check below
      const date = dateObjectFromStr(item);
      const dateStr = longDate(date);
      return dateStr;
    });
    if (!updatedVisitArr.includes(today)) {
      updatedVisitArr.push(today);
    } else {
      toUpdate = false;
    }
  } else {
    updatedVisitArr = [today];
  }

  return { updatedVisitArr, toUpdate };
};
