import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles({
  root: {
    overflow: "auto",
    paddingBottom: "20px",
  },

  pageTitle: {
    height: "auto",
    fontStyle: "normal",
    fontSize: "30px",
    lineHeight: "25px",
    color: "#000000",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "40px auto",
    fontFamily: "Avenir Black, sans-serif",
  },
  newPageTitle: {
    fontFamily: "Avenir",
    fontSize: "30px",
    fontWeight: 900,
    lineHeight: "25px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  datePrint: {
    display: "flex",
    alignItems: "flex-end",
    flexFlow: "wrap",
    justifyContent: "center",

    "& > :first-child": {
      marginBottom: "-5px",
    },
  },

  avatar: {
    minWidth: "60px",
    minHeight: "60px",
    mixBlendMode: "normal",
    // [theme.breakpoints.down("sm")]: {
    "@media screen and (max-width: 450px)": {
      alignItems: "center",
      justifyContent: "center",
      // width: "100%",
    },
  },

  AvatarIcon: {
    width: "80px",
    height: "80px",
    flexShrink: 0,
    background: "#000",
  },
  AvatarSvg: {
    width: "80px",
    height: "80px",
    flexShrink: 0,
    mixBlendMode: "normal",
    background: "#000",
  },
  avCon: {
    // [theme.breakpoints.down("sm")]: {
    "@media screen and (max-width: 750px)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
  },
  userName: {
    color: "#000",
    fontFamily: "Avenir",
    fontSize: "27px",
    fontStyle: "normal",
    fontWeight: 900,
    lineHeight: "32px",
    letterSpacing: "0.5px",
    display: "flex",
    flexDirection: "column",
    paddingTop: 20,
    "@media screen and (max-width: 750px)": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
  },
  userContainer: {
    marginBottom: "30px",
  },
});
