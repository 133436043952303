/**
 * @prettier
 */
import React, { FunctionComponent, useEffect, useState } from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as Logo } from "statics/logo.svg";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { PageType } from "types/PageType";
import { useUser } from "UserContext";
// import { IUser } from "interfaces/IUser";
import { signOut } from "hooks/AuthHook";
import { navigate } from "@reach/router";
import { useStyles } from "./styles";
import {
  Avatar,
  Box,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuItem,
  MenuList,
} from "@material-ui/core";
import { fromEvent, Observable } from "rxjs";
import HamburgerMenu from "react-hamburger-menu";

import { NavLink, BlockingLink } from "./NavLink";
// import { CalenderLink } from "./CalenderLink";
import ClientAccounts from "components/ClientAccounts/ClientAccounts";
import Progress from "components/styled/Progress";

export const Navbar: FunctionComponent<PageType> = () => {
  const classes = useStyles({});
  const [user, setUser] = useUser() as any;
  const [open, setOpen] = React.useState(false);
  const [bePlannedOpen, setBePlannedOpen] = useState(false);
  const data: any = localStorage.getItem("kit-authUser");

  const [navOpen, setNavOpen] = React.useState(false);
  const [nav2Open, setNav2Open] = React.useState(false);
  const anchorRef: any = React.useRef(null);
  const [mobileMenu, switchMobileMenu] = useState(
    !(document.documentElement.offsetWidth > 1000)
  );
  const [mobileMenuStatus, toggleMenuStatus] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const inBePlannedRoute =
    window.location.href.includes("content-plan-feed") ||
    window.location.href.includes("monthly-conversation-planner") ||
    window.location.href.includes("be-planned-grid");

  const resize$: Observable<Event> = fromEvent(window, "resize");

  resize$.subscribe((res: any) => {
    if (res && res.currentTarget) {
      switchMobileMenu(!(res.currentTarget.innerWidth > 1000));
    }
  });

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
    setBePlannedOpen(false);
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleNavToggle = () => {
    setNavOpen(!navOpen);
  };

  const handleClickAway = () => {
    setNav2Open(false);
    setNavOpen(false);
  };

  function handleListKeyDown(event: any) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  const getDocHeight = () => {
    return Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    );
  };

  useEffect(() => {
    const calculateScrollDistance = () => {
      const scrollTop = window.pageYOffset; // how much the user has scrolled by
      const winHeight = window.innerHeight;
      const docHeight = getDocHeight();

      const totalDocScrollLength = docHeight - winHeight;
      const scrollPosition = Math.floor(
        (scrollTop / totalDocScrollLength) * 100
      );

      setScrollPosition(scrollPosition);
    };

    const listenToScrollEvent = () => {
      document.addEventListener("scroll", () => {
        requestAnimationFrame(() => {
          calculateScrollDistance();
        });
      });
    };

    listenToScrollEvent();
  }, []);

  const prevOpen = React.useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  let clients: any = localStorage.getItem("clients");
  clients = JSON.parse(clients);

  const useForceUpdate = () => {
    const [, setValue] = useState(0); // integer state
    return () => setValue((value) => value + 1); // update the state to force render
  };
  const forceUpdate = useForceUpdate();

  const logout = () => {
    signOut();
    setUser(null);
    forceUpdate();
  };

  const path = window.location.pathname;
  const isPublic = path.includes("/public");

  let encodedString: any = path.split("/");
  encodedString = encodedString[encodedString.length - 1];
  const beMeasuredPath = isPublic
    ? `public/be-measured/${encodedString}`
    : "be-measured";
  const bePlannedPath = isPublic
    ? `public/content-plan-feed/${encodedString}`
    : "content-plan-feed";
  // const calendarPath = isPublic
  //   ? `public/monthly-conversation-planner/${encodedString}`
  //   : "monthly-conversation-planner";

  return (
    <>
      {mobileMenu ? (
        <>
          <div style={{ height: "65px !important" }}>
            <div
              className={`${classes.menuRow} ${classes.navBar}`}
              style={{ justifyContent: "space-between", zIndex: 10000 }}
            >
              <Logo
                className={classes.logo}
                style={{ marginLeft: "17px" }}
                onClick={() => navigate(`/${beMeasuredPath}`)}
              />
              <HamburgerMenu
                isOpen={mobileMenuStatus}
                menuClicked={(e) => {
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                  e.preventDefault();
                  toggleMenuStatus(!mobileMenuStatus);
                }}
                width={25}
                height={12}
                strokeWidth={2}
                rotate={0}
                color="white"
                borderRadius={0}
                animationDuration={0.5}
                className={classes.burger}
              />

              <Progress scroll={scrollPosition + "%"} mgTop="74px" />
            </div>
          </div>
          {
            <div
              className={`${classes.mobileSideBarContainer} ${
                mobileMenuStatus ? "" : classes.mobileSideBarContainerClosed
              }`}
            >
              <div
                className={classes.menuRow}
                style={{
                  marginTop: "-7px",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                }}
              >
                <HamburgerMenu
                  isOpen={mobileMenuStatus}
                  menuClicked={(e) => {
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                    e.preventDefault();
                    toggleMenuStatus(!mobileMenuStatus);
                  }}
                  width={25}
                  height={12}
                  strokeWidth={1.5}
                  rotate={0}
                  color="white"
                  borderRadius={0}
                  animationDuration={0.5}
                  className={classes.closeButton}
                />
              </div>

              <div
                className={classes.menuRow}
                style={{ justifyContent: "space-between", marginBottom: 20 }}
              >
                <Avatar
                  alt={user.firstName + " " + user.lastName}
                  src={user.avatarUrl}
                  className={classes.avatar}
                  onClick={handleToggle}
                >
                  <p className={classes.avatarText}>
                    {user?.firstName &&
                      user?.lastName &&
                      user.firstName[0] + user.lastName[0]}
                  </p>
                </Avatar>
                <Typography
                  ref={anchorRef}
                  onClick={handleToggle}
                  style={{
                    fontFamily: '"Avenir Book", sans-serif',
                    fontWeight: 600,
                  }}
                >
                  {user.firstName} {user.lastName}
                </Typography>
              </div>

              <div
                className={classes.menuRow}
                style={{
                  flexDirection: "column",
                  fontFamily: '"Avenir Book", sans-serif',
                }}
              >
                <span onClick={() => toggleMenuStatus(false)}>
                  <NavLink path={beMeasuredPath}>Be-Measured</NavLink>

                  <NavLink path={bePlannedPath}>Content Planner</NavLink>

                  {/* {!window.location.pathname.includes("/public") && (
                    <NavLink path="campaigns/organic">Be-Amplified</NavLink>
                  )} */}
                  {/* <NavLink path="campaigns/organic">Be-Amplified</NavLink> */}

                  {/* <CalenderLink
                    duration="monthly"
                    toggleMenuStatus={toggleMenuStatus}
                  /> */}
                  {/* Commented for future use */}
                  {/* <CalenderLink
                    duration="weekly"
                    toggleMenuStatus={toggleMenuStatus}
                  /> */}

                  {/* <NavLink path="be-planned-grid">Be-Planned Grid</NavLink> */}
                  {clients !== null && clients.length > 1 && (
                    <MenuItem
                      onClick={(e) => {
                        handleClose(e);
                        setIsOpen(true);
                      }}
                      style={{
                        opacity: 0.5,
                        fontWeight: 600,
                        fontFamily: '"Avenir Book", sans-serif',
                      }}
                    >
                      Choose Account
                    </MenuItem>
                  )}
                </span>

                <Typography variant="h6" className={classes.navTitle}>
                  {data && (
                    <MenuItem
                      onClick={() => {
                        logout();
                        toggleMenuStatus(false);
                      }}
                    >
                      Sign Out
                    </MenuItem>
                  )}
                  {user && (
                    <MenuItem
                      onClick={() => {
                        navigate(`/client-info/${user.id}`);
                      }}
                    >
                      Edit Profile
                    </MenuItem>
                  )}
                  {!data && (
                    <MenuItem
                      onClick={() => {
                        window.location.href = "/sign-in";
                        toggleMenuStatus(false);
                      }}
                    >
                      Sign In
                    </MenuItem>
                  )}
                </Typography>
              </div>
            </div>
          }
        </>
      ) : (
        <>
          <div className={classes.root}>
            <AppBar position="static" className={classes.navBar} id="navbar">
              <Toolbar className={classes.toolbar}>
                <div className={classes.nav}>
                  <Logo
                    className={classes.logo}
                    onClick={() => navigate(`/${beMeasuredPath}`)}
                  />
                  <Box className={classes.menu}>
                    <div className={classes.links}>
                      <NavLink path={beMeasuredPath}>Be-Measured</NavLink>
                      <div
                        style={{ position: "relative", marginRight: "58px" }}
                      >
                        <div style={{ display: "flex" }}>
                          <div
                            className={
                              inBePlannedRoute
                                ? `${classes.activeLink}`
                                : `${classes.iconActive}`
                            }
                            style={{
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => setBePlannedOpen(true)}
                          >
                            <div
                              className={`${classes.link}
                                ${
                                  inBePlannedRoute
                                    ? classes.activeLink
                                    : classes.inActive
                                }`}
                            >
                              Be-Planned
                            </div>

                            <ArrowDropDownIcon
                              className={classes.dropdown}
                              fontSize="small"
                              style={{
                                fontSize: "2rem",
                              }}
                            />
                          </div>
                        </div>
                        {bePlannedOpen && (
                          <div className="be-planned-dropdown">
                            <ClickAwayListener onClickAway={handleClose}>
                              <div className={classes.bePlanned}>
                                <div
                                  onClick={() => {
                                    setBePlannedOpen(false);
                                    navigate(`/${bePlannedPath}`);
                                  }}
                                >
                                  <BlockingLink
                                    to={`/${bePlannedPath}`}
                                    style={{
                                      textDecoration: "none",
                                      color: "unset",
                                    }}
                                  >
                                    Content Planner
                                  </BlockingLink>
                                </div>
                                {/* <div
                                  onClick={() => {
                                    setBePlannedOpen(false);
                                    navigate(`/${bePlannedPath}`);
                                  }}
                                >
                                  <BlockingLink
                                    to={`/${bePlannedPath}`}
                                    style={{
                                      textDecoration: "none",
                                      color: "unset",
                                    }}
                                  >
                                    Monthly Planner
                                  </BlockingLink>
                                </div> */}
                                {/* commented for future use */}
                                {/* <div
                                  onClick={() => {
                                    navigate(
                                      "/monthly-conversation-planner?weekly=true"
                                    );
                                    setBePlannedOpen(false);
                                  }}
                                >
                                  Be-Planned Weekly
                                </div> */}
                                {/* <div
                                  onClick={() => {
                                    setBePlannedOpen(false);
                                    navigate("/be-planned-grid");
                                  }}
                                >
                                  <BlockingLink
                                    to={"/be-planned-grid"}
                                    style={{
                                      textDecoration: "none",
                                      color: "unset",
                                    }}
                                  >
                                    Be-Planned Grid
                                  </BlockingLink>
                                </div> */}
                              </div>
                            </ClickAwayListener>
                          </div>
                        )}
                      </div>
                      {/* {!window.location.pathname.includes("/public") && (
                        <NavLink path="campaigns/organic">Be-Amplified</NavLink>
                      )} */}
                    </div>

                    <div className={classes.logout}>
                      <Avatar
                        alt={user.firstName + " " + user.lastName}
                        src={user.avatarUrl}
                        className={classes.avatar}
                        onClick={handleNavToggle}
                      >
                        <p className={classes.avatarText}>
                          {user?.firstName &&
                            user?.lastName &&
                            user.firstName[0] + user.lastName[0]}
                        </p>
                      </Avatar>
                      <Typography
                        ref={anchorRef}
                        onClick={handleNavToggle}
                        style={{ fontFamily: "Avenir Black, sans-serif" }}
                      >
                        {user.firstName} {user.lastName}
                      </Typography>
                      {data && (
                        <>
                          <ArrowDropDownIcon
                            className={classes.dropdown}
                            fontSize="small"
                            onClick={handleNavToggle}
                          />
                          <Popper
                            open={navOpen || nav2Open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            transition
                            disablePortal
                            style={{ padding: "15px" }}
                          >
                            {({ TransitionProps, placement }) => (
                              <Grow
                                {...TransitionProps}
                                style={{
                                  transformOrigin:
                                    placement === "bottom"
                                      ? "center top"
                                      : "center bottom",
                                }}
                              >
                                <Paper style={{ borderRadius: 2, width: 150 }}>
                                  <ClickAwayListener
                                    onClickAway={handleClickAway}
                                  >
                                    <MenuList
                                      autoFocusItem={open}
                                      id="menu-list-grow"
                                      onKeyDown={handleListKeyDown}
                                      style={{
                                        fontFamily: "Avenir Black, sans-serif",
                                        fontWeight: 600,
                                        fontSize: 14,
                                      }}
                                    >
                                      {clients !== null && clients.length > 1 && (
                                        <MenuItem
                                          onClick={(e) => {
                                            handleClose(e);
                                            setIsOpen(true);
                                          }}
                                          style={{
                                            fontFamily:
                                              "Avenir Black, sans-serif",
                                            fontWeight: 600,
                                            fontSize: 14,
                                          }}
                                        >
                                          Choose Account
                                        </MenuItem>
                                      )}
                                      <MenuItem
                                        onClick={logout}
                                        style={{
                                          fontFamily:
                                            '"Avenir Black", sans-serif',
                                          fontWeight: 600,
                                          color: "#FF5269",
                                          fontSize: 14,
                                        }}
                                      >
                                        Sign Out
                                      </MenuItem>

                                      {user && (
                                        <MenuItem
                                          onClick={(e) => {
                                            navigate(`/client-info/${user.id}`);
                                          }}
                                          style={{
                                            fontFamily:
                                              '"Avenir Black", sans-serif',
                                            fontWeight: 600,
                                            color: "#FF5269",
                                            fontSize: 14,
                                          }}
                                        >
                                          Edit Profile
                                        </MenuItem>
                                      )}
                                    </MenuList>
                                  </ClickAwayListener>
                                </Paper>
                              </Grow>
                            )}
                          </Popper>
                        </>
                      )}
                      {!data && (
                        <>
                          <ArrowDropDownIcon
                            className={classes.dropdown}
                            fontSize="small"
                            onClick={handleNavToggle}
                          />
                          <Popper
                            open={navOpen || nav2Open}
                            anchorEl={anchorRef.current}
                            role={undefined}
                            transition
                            disablePortal
                            style={{ padding: "15px" }}
                          >
                            {({ TransitionProps, placement }) => (
                              <Grow
                                {...TransitionProps}
                                style={{
                                  transformOrigin:
                                    placement === "bottom"
                                      ? "center top"
                                      : "center bottom",
                                }}
                              >
                                <Paper style={{ borderRadius: 2, width: 150 }}>
                                  <ClickAwayListener
                                    onClickAway={handleClickAway}
                                  >
                                    <MenuList
                                      autoFocusItem={open}
                                      id="menu-list-grow"
                                      onKeyDown={handleListKeyDown}
                                      style={{
                                        fontFamily: "Avenir Black, sans-serif",
                                        fontWeight: 600,
                                        fontSize: 14,
                                      }}
                                    >
                                      <MenuItem
                                        onClick={(e) => {
                                          e.preventDefault();
                                          window.location.href = "/sign-in";
                                        }}
                                        style={{
                                          fontFamily:
                                            '"Avenir Black", sans-serif',
                                          fontWeight: 600,
                                          color: "#FF5269",
                                          fontSize: 14,
                                        }}
                                      >
                                        Sign in
                                      </MenuItem>
                                    </MenuList>
                                  </ClickAwayListener>
                                </Paper>
                              </Grow>
                            )}
                          </Popper>
                        </>
                      )}
                    </div>
                  </Box>
                </div>
              </Toolbar>
            </AppBar>
          </div>
          <Progress scroll={scrollPosition + "%"} />
        </>
      )}
      {isOpen && <ClientAccounts toggleModal={setIsOpen} />}
    </>
  );
};
