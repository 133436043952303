import React, { FC } from "react";

import ModalWrapper from "components/Common/Modal";
import { getQueryParameter } from "utils/getQueryParameter";

interface Props {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

const AuthPrompt: FC<Props> = ({ isOpen, setIsOpen }) => {
  const handleSubmit = () => {
    const startDate = getQueryParameter("start");
    const endDate = getQueryParameter("end");
    const path = getCurrentPagePath();

    window.location.replace(
      `${process.env.REACT_APP_WEBSITE_URL}/sign-in?start=${startDate}&end=${endDate}&path=${path}`
    );
  };

  const getCurrentPagePath = () => {
    const url = window.location.href;
    if (url.includes("content-plan-feed")) {
      return "contentplanfeed";
    }

    if (url.includes("be-measured")) {
      return "bemeasured";
    }

    return "";
  };

  return (
    <ModalWrapper
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      handleSubmit={handleSubmit}
      title="Submit feedback"
      submitButtonText="Sign in"
    >
      <div style={{ paddingTop: 26 }}>
        <p style={{ margin: 0, fontSize: 15 }}>
          To submit feedback, you have to first get authenticated. Click on the
          the Sign In button to be redirected to the Sign In page.
        </p>
      </div>
    </ModalWrapper>
  );
};

export default AuthPrompt;
