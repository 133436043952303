import styled from "styled-components";

const SelectCampaign = styled.div`
  width: 100%;
  padding-bottom: 50px;
  margin-top: 70px;
  font-family: "Avenir Book", sans-serif;

  .title {
    &__main {
      font-family: "Avenir Black", sans-serif;
      font-weight: 900;
      color: #000000;
      font-size: 30px;
      line-height: 41px;
    }

    &__organic {
      font-family: "Avenir Black", sans-serif;
      display: flex;
      align-items: center;
      font-weight: 900;
      color: #000000;
      font-size: 28px;
      line-height: 38px;
      margin-bottom: 40px;
      cursor: pointer;
    }

    &__element {
      font-family: "Avenir Black", sans-serif;
      font-weight: 800;
      font-size: 24px;
      line-height: 33px;
    }
  }

  .page-description {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    max-width: 840px;
    margin-top: 20px;
  }

  .dropdownArrow {
    margin-left: 24px;
    width: 12.97px;
  }

  .collapse {
    transform: rotateX(180deg);
  }

  .elements-container {
    margin-top: 60px;
  }

  .campaign-element {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 25px 22px;
    max-width: 331px;
    cursor: pointer;
    box-shadow: 0px 5px 45px rgba(0, 0, 0, 0.07);
    border-radius: 15px;
    transition: background 0.3s linear;
    background: #fff;

    &:hover {
      transition: background 0.3s linear;
      background: #000000;
      color: #fff;

      .right-arrow {
        border-color: transparent #fff;
      }
    }
  }

  .img {
    width: 91.26px;
    height: 102px;
    margin-right: 30px;
  }

  .context {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
  }

  .link {
    text-decoration: none;
    color: #000000;
    cursor: pointer;
  }

  .right-arrow {
    border-color: transparent black;
    border-style: solid;
    border-width: 14px 0px 14px 16px;
    height: 0px;
    width: 0px;
    margin-right: 1px;
  }

  @media screen and (max-width: 768px) {
    margin-top: 130px;
  }
`;

export default SelectCampaign;
