import React, { FC } from "react";
import {
  Tooltip,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  TableBody,
  Paper,
  Button,
} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";

import { useStyles } from "../styles";
import { numberWithCommas } from "utils/numberFormat";

const SpotifySongs: FC<{ posts: Array<any> }> = ({ posts }) => {
  const classes = useStyles({});

  const getSpotifyLink = (uri: string) => {
    const endpoint = uri.split(":").splice(1).join("/");
    return `https://open.spotify.com/${endpoint}`;
  };

  return (
    <div>
      <div
        className={classes.statsName}
        style={{
          display: "flex",
          justifyContent: "space-between",
          fontSize: "20px",
          padding: "30px 0px 25px 0px",
          fontFamily: "Avenir Book, sans-serif",
          fontWeight: 900,
        }}
      >
        <span>Top 10 Most Streamed Songs</span>
        <Tooltip
          title={
            "Top 10 most streamed songs on the last date of the selected period. The metrics are for the last day."
          }
          arrow
          interactive
          classes={{ tooltip: classes.info }}
        >
          <InfoIcon />
        </Tooltip>
      </div>
      <TableContainer
        component={Paper}
        style={{
          boxShadow: "0px 5px 45px rgba(0, 0, 0, 0.07)",
          borderRadius: "15px",
        }}
      >
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow className={classes.tableHeader}>
              <TableCell className={classes.tableCell} align="center">
                Song Title
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                Streams
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                Listeners
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                Views
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                Saves
              </TableCell>
              <TableCell
                className={classes.tableCell}
                align="center"
              ></TableCell>
            </TableRow>
          </TableHead>
          {/* Table Body */}
          <TableBody>
            {posts.map((post) => (
              <TableRow key={post.videoLink} className={classes.row}>
                <TableCell align="center">
                  <div className={`${classes.post} ${classes.tableCell}`}>
                    <span className={classes.postDetails}>
                      {post.trackName}
                    </span>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={`${classes.post} ${classes.tableCell}`}>
                    <span className={classes.postDetails}>
                      {numberWithCommas(parseInt(post.numStreams))}
                    </span>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={`${classes.post} ${classes.tableCell}`}>
                    <span className={classes.postDetails}>
                      {numberWithCommas(parseInt(post.numListeners))}
                    </span>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={`${classes.post} ${classes.tableCell}`}>
                    <span className={classes.postDetails}>
                      {numberWithCommas(parseInt(post.numCanvasViews))}
                    </span>
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className={`${classes.post} ${classes.tableCell}`}>
                    <span className={classes.postDetails}>
                      {numberWithCommas(parseInt(post.numSavers))}
                    </span>
                  </div>
                </TableCell>

                <TableCell align="center">
                  <div className={`${classes.post} ${classes.tableCell}`}>
                    <a
                      href={getSpotifyLink(post.trackUri)}
                      className={classes.postDetails}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Button
                        disableRipple
                        type="button"
                        classes={{
                          label: classes.archiveLabel,
                        }}
                        style={{
                          marginTop: "-6px",
                        }}
                      >
                        URL
                      </Button>
                    </a>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default SpotifySongs;
