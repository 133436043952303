/**
 * @prettier
 */
import React, { FunctionComponent, useState, useEffect } from "react";
import moment from "moment";
import { DateRange } from "materialui-daterange-picker";
import history from "history/browser";
import { SendButton } from "components/styled/Common";

import { useStyles } from "./styles";
import DateRangePicker from "components/Common/DateRange";
import { BePlanned } from "components/BePlanned/BePlanned";
import { PageType } from "types/PageType";
import { Loader } from "components/Loader/Loader";
import useCollectionSubscriber from "../../hooks/useCollectionSubscriber";
import { firestore } from "../../firebase-tools/firebase";
import { useUser } from "UserContext";
import MultiSelect from "components/Common/MultiSelect";
import { postsFilter } from "utils/postsFilter";
import { urlDates } from "utils/dateFormat";
import { BePlannedContainer } from "components/styled/BePlanned";
import { Grid } from "@material-ui/core";
import Pagination from "containers/BeplannedV2/Pagination";
import cuid from "cuid";
import DateRangePickerV2 from "components/Common/DateRange/DateRangePickerV2";
// import { usePrimaryUser } from "PrimaryUserContext";
// import { addKitActivityItem } from "helpers/kitActivities/addKitActivity";
// import { useAuthUser } from "AuthUserContext";
// import { Pages } from "interfaces/IKitActivities";
import UserProfile from "containers/BeMeasured/UserProfile";

const channelOptions = [
  "Instagram",
  "Facebook",
  "Twitter",
  "TikTok",
  "YouTube",
  "Youtube Short",
  "LinkedIn",
  "Others",
  "All",
];
export const BePlannedPage: FunctionComponent<PageType> = ({ location }) => {
  const classes = useStyles({});
  const [dateRange, setDateRange] = React.useState<DateRange>({
    startDate: moment().startOf("day").toDate(),
    endDate: moment().add(6, "days").startOf("day").toDate(),
  });
  const [user]: any = useUser();
  // const [authUserData]: any = useAuthUser();
  // const [primaryUser]: any = usePrimaryUser();
  const authUser = Array.isArray(user) ? user[0][0] : user;
  // const selectedUsers: any = primaryUser?.email ? primaryUser : authUser;
  const selectedUsers: any = authUser;
  const [loading, setLoading] = useState(false);
  const [channels, setChannels]: any = useState([]);
  const [paramsChange, setParamsChange]: any = useState("");
  const [defaultPageSize, setDefaultPageSize] = useState(5);
  // const [searchTerm, setSearchTerm] = useState("");
  const extractedUids: any[] = [];

  const fromDate = new Date(dateRange.startDate as Date);
  let toDate = new Date(dateRange.endDate as Date);

  const from = moment(fromDate).startOf("day").toDate();
  const to = moment(toDate).endOf("day").toDate();

  const postsQueries = [
    firestore
      .collection("multichannel-posts")
      .where("user_email", "==", selectedUsers?.email)
      .where("date", ">=", from)
      .where("date", "<=", to)
      .orderBy("date", "desc"),
  ];

  const eventsQueries = [
    firestore
      .collection("events")
      .where("user_email", "==", selectedUsers?.email)
      .where("start_date", ">=", from)
      .where("start_date", "<=", to)
      .orderBy("start_date", "desc"),
  ];

  let posts;
  let events;
  let visiblePosts;
  let visibleEvents;

  posts = useCollectionSubscriber({
    queries: postsQueries,
    deps: [dateRange, authUser],
  });

  events = useCollectionSubscriber({
    queries: eventsQueries,
    deps: [dateRange, authUser],
  });

  if (posts.documents) {
    posts.documents = posts.documents.filter(
      (post) => post.is_visible_to_client === "Yes"
    );
  }

  // Page size options and default page size
  const pageSizeOptions = [3, 5, 10, 20];

  // const handleSearchChange = (event) => {
  //   const newSearchTerm = event.target.value;
  //   setSearchTerm(newSearchTerm);
  // };

  const [currentPage, setCurrentPage] = useState(1);
  let totalPages = Math.ceil(posts.documents.length / defaultPageSize);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // const [sortDirection, setSortDirection] = useState("desc");
  // const handleSortToggle = () => {
  //   setSortDirection((prevDirection) =>
  //     prevDirection === "desc" ? "asc" : "desc"
  //   );
  //   // Now 'visiblePosts' contains the sorted documents array
  // };

  const startIndex = (currentPage - 1) * defaultPageSize;
  const endIndex = startIndex + defaultPageSize;
  visiblePosts = {
    ...posts,
    documents: posts.documents.slice(startIndex, endIndex).map((val) => {
      let uuid = cuid();
      extractedUids.push(uuid);
      return {
        ...val,
        uuid,
      };
    }),
  };

  const populateEvents = (events: any[]) => {
    // TODO: Add logic to populate events based on the seleced events start_date and end_date. new events should be added with a new start_date based the range selected on the start_date and end_date.
    // const newEvents: any[] = [];

    // events.forEach((event: any) => {
    //   const dates = generateDates(event.start_date, event.end_date);
    //   console.log("dates", event);
    //   dates.forEach((date) => {
    //     newEvents.push({
    //       ...event,
    //       new_start_date: date,
    //     });
    //   });
    // });

    // console.log("newEvents", newEvents, "events", events.length);

    return events.map((data) => {
      return {
        ...data,
        new_start_date: data.start_date,
      };
    });
  };
  visibleEvents = {
    documents: populateEvents([...events.documents])
      .slice(startIndex, endIndex)
      .map((val) => {
        let uuid = cuid();
        extractedUids.push(uuid);
        return {
          ...val,
          uuid,
        };
      }),
  };

  // visibleEvents = {
  //   documents: [...events.documents].slice(startIndex, endIndex).map((val) => {
  //     let uuid = cuid();
  //     extractedUids.push(uuid);
  //     return {
  //       ...val,
  //       uuid,
  //     };
  //   }),
  // };

  // if (posts?.documents?.length && !posts?.loading) {
  //   addKitActivityItem({
  //     firstName: authUserData?.displayName ?? "",
  //     lastName: authUserData?.lastName || "",
  //     email: authUserData?.email || "",
  //     page: Pages.BE_PLANNED_LIST_VIEW,
  //     clientName: selectedUsers?.firstName + " " + selectedUsers?.lastName,
  //     primaryEmail: selectedUsers?.email,
  //     startDate: from,
  //     endDate: to,
  //     posts: posts.documents,
  //     createdAt: new Date(),
  //   }).then(() => {});
  // }

  console.log("dateRange.startDate", posts.documents);

  useEffect(() => {
    if (loading && !posts?.loading && posts?.documents) {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [posts.documents]);

  useEffect(() => {
    urlDates(dateRange, history, paramsChange, setDateRange, location);

    setLoading(true);
  }, [dateRange, paramsChange, location]);

  const clearBrowserPageCache = (reload: boolean) => {
    if (window && caches && reload) {
      // If the window and caches objects exist, we can safely clear the cache
      caches.keys().then((names) => {
        // Get all the names of the caches
        names.forEach((name) => {
          // For each cache name, delete the cache
          caches.delete(name);
        });
        // Finally, reload the page to get the most up-to-date data
        window.location.reload();
      });
    }
  };

  return (
    <BePlannedContainer id="">
      <div className={classes.pageTitle}>
        {/* desktop view */}
        <Grid container className={classes.smHide}>
          {authUser && <UserProfile user={authUser} />}
          <Grid item xs={12} md={7}></Grid>
          <Grid item xs={12} md={5} container>
            <Grid
              item
              xs={12}
              md={5}
              className={classes.contentPlannerRightAlign}
              style={{
                alignContent: "flex-end",
              }}
            >
              {" "}
              <MultiSelect
                items={channels}
                setItems={setChannels}
                options={channelOptions}
                label="Choose Channels"
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={7}
              className={classes.contentPlannerRightAlign}
              container
            >
              {" "}
              <DateRangePicker
                dateRange={dateRange}
                setDateRange={setDateRange}
                setParamsChange={setParamsChange}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* mobile view */}
        <Grid container className={classes.mdHide}>
          <Grid item xs={12} container>
            {authUser && <UserProfile user={authUser} />}

            <Grid item xs={12} container>
              {" "}
              <MultiSelect
                items={channels}
                setItems={setChannels}
                options={channelOptions}
                label="Choose Channels"
              />
            </Grid>
            <Grid item xs={12} container>
              {" "}
              <DateRangePickerV2
                dateRange={dateRange}
                setDateRange={setDateRange}
                setParamsChange={setParamsChange}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Grid>
        <SendButton
          onClick={(e) => {
            clearBrowserPageCache(true);
          }}
        >
          Clear Cache
        </SendButton>
      </Grid>

      <Grid container>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          pageSize={defaultPageSize}
          setPageSize={setDefaultPageSize}
          pageSizeOptions={pageSizeOptions}
        />
      </Grid>

      {!loading ? (
        <BePlanned
          posts={() => postsFilter(channels, visiblePosts)}
          classes={classes}
          user={selectedUsers}
          events={() => visibleEvents.documents}
        />
      ) : (
        <Loader />
      )}

      <Grid container>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
          pageSize={defaultPageSize}
          setPageSize={setDefaultPageSize}
          pageSizeOptions={pageSizeOptions}
        />
      </Grid>
    </BePlannedContainer>
  );
};
