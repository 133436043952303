import React, { FC, useState } from "react";
import { ReactComponent as Download } from "../../../statics/media/download.svg";

interface IDownloadActions {
  generateScreenshot: () => void;
  color?: string;
}

const DownloadActionsV2: FC<IDownloadActions> = ({
  generateScreenshot,
  color = "",
}) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen(!open);

  return (
    <div
      style={{
        padding: "10px 15px",
      }}
    >
      <Download
        height={19}
        width={16}
        style={{
          cursor: "pointer",
        }}
        onClick={() => {
          toggleOpen();

          setTimeout(() => {
            generateScreenshot();
          }, 500);
        }}
      />
      {/* <span> Screenshot</span> */}
    </div>
  );
};

export default DownloadActionsV2;
