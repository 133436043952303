import React, { FC } from "react";
import { PageType } from "types/PageType";
import { SocialMediaPlatform } from "../SelectCampaignPage";
import { Facebook } from "components/BeAmplified/Campaigns/Organic/Create/AutoDm/Facebook";
import { Twitter } from "components/BeAmplified/Campaigns/Organic/Create/AutoDm/Twitter";

interface Props extends PageType {
  uid?: string;
  platform?: string;
}

export const CreateAutoDm: FC<Props> = ({ uid, platform }) => {
  if (SocialMediaPlatform.Facebook === platform) {
    return <Facebook uid={uid} />;
  }
  if (SocialMediaPlatform.Twitter === platform) {
    return <Twitter uid={uid} />;
  }
  return <Twitter uid={uid} />;
};
