export const audienceGenderAge = (audience: any) => {
  const femaleKeys = [
    "F.13-17",
    "F.18-24",
    "F.25-34",
    "F.35-44",
    "F.45-54",
    "F.55-64",
    "F.65+",
  ];
  const maleKeys = [
    "M.13-17",
    "M.18-24",
    "M.25-34",
    "M.35-44",
    "M.45-54",
    "M.55-64",
    "M.65+",
  ];
  const otherKeys = [
    "U.13-17",
    "U.18-24",
    "U.25-34",
    "U.35-44",
    "U.45-54",
    "U.55-64",
    "U.65+",
  ];
  let genderSeries: any = [];

  let maleList: Array<number> = [];
  let femaleList: Array<number> = [];
  let otherList: Array<number> = [];
  maleKeys.map((key) => {
    if (!audience[key]) return 0;

    return maleList.push(audience[key]);
  });

  femaleKeys.map((key) => {
    if (!audience[key]) return 0;

    return femaleList.push(audience[key]);
  });

  otherKeys.map((key) => {
    if (!audience[key]) return 0;

    return otherList.push(audience[key]);
  });

  const totalFemale =
    femaleList.reduce((a: number, b: number) => a + b, 0) || 0;
  const totalMale =
    maleList.reduce((a: number, b: number): number => a + b, 0) || 0;
  const totalOther = otherList.reduce((a: number, b: number) => a + b, 0) || 0;
  const totalGender = totalFemale + totalMale + totalOther;
  genderSeries = [
    ((totalMale / totalGender) * 100).toFixed(2),
    ((totalFemale / totalGender) * 100).toFixed(2),
    ((totalOther / totalGender) * 100).toFixed(2),
  ];

  const ageSeries = [
    {
      data: maleList[0] ? maleList : [0, 0, 0, 0, 0, 0, 0],
      name: "Men",
    },
    {
      data: femaleList[0] ? femaleList : [0, 0, 0, 0, 0, 0, 0],
      name: "Women",
    },
    {
      data: otherList[0] ? otherList : [0, 0, 0, 0, 0, 0, 0],
      name: "Non-binary",
    },
  ];
  return { genderSeries, ageSeries: ageSeries };
};

const audienceCityCountry = (audience: any) => {
  const keys = Object.keys(audience);
  const series = keys.map((key) => {
    const id = key.length === 3 ? key.slice(0, 2) : key;

    return {
      id,
      value: audience[key],
    };
  });
  return series;
};

const topCitiesCountries = (audience: any, pastAudience: any) => {
  let allAreas: any[] = [];
  let allAreasPast: any[] = [];
  for (let item in audience) {
    const pastAudienceEntry = pastAudience[item] ? pastAudience[item] : 0;

    allAreas.push([item, pastAudienceEntry, audience[item]]);
    allAreasPast.push(pastAudienceEntry);
  }
  allAreas.sort((a, b) => {
    return b[2] - a[2];
  });

  const topAreas = allAreas.slice(0, 5);
  const others = allAreas.slice(5, allAreas.length);
  const totalPast: any = allAreasPast.reduce((a: any, b: any) => a + b, 0);
  const values = allAreas.map((item) => item[2]);
  const total: any = values.reduce((a: any, b: any) => a + b, 0);
  return { topAreas, total, others, totalPast };
};

export const getFbData = (fbMetrics: any) => {
  const fbFollowers =
    fbMetrics &&
    fbMetrics.map((metric: any) => {
      return metric.followers?.followers_count || 0;
    });
  const fbImpressions =
    fbMetrics &&
    fbMetrics.map((metric: any) => {
      return metric.impressions?.total_impressions || 0;
    });
  const fbEngagements =
    fbMetrics &&
    fbMetrics.map((metric: any) => {
      return metric.engagements?.total_engagements || 0;
    });
  const fbEngagementRate =
    fbMetrics &&
    fbMetrics.map((metric: any) => {
      return metric.engagements?.engagement_rate * 100;
    });

  const interactions =
    fbMetrics &&
    fbMetrics.map((metric: any) => {
      return metric?.total_interactions || 0;
    });
  const interactionsRate =
    fbMetrics &&
    fbMetrics.map((metric: any) => {
      return metric?.interaction_rate || 0;
    });

  const allPostsData = fbMetrics
    ? fbMetrics.flatMap((metric) => metric.posts?.data)
    : [];
  const uniquePostsMap = {};
  allPostsData.forEach((post) => {
    if (!uniquePostsMap[post?.id]) {
      uniquePostsMap[post?.id] = post;
    } else {
      const postDate1 = new Date(post.timestamp);
      const postDate2 = new Date(uniquePostsMap[post?.id].timestamp);
      if (postDate1 > postDate2) {
        uniquePostsMap[post?.id] = post;
      }
    }
  });

  const uniquePosts = Object.values(uniquePostsMap);

  const fbPostsCount = fbMetrics?.length ? [uniquePosts.length] : [];

  const pastFbMetric = fbMetrics.length > 0 && fbMetrics[0];
  const currentFbMetric =
    fbMetrics.length > 0 && fbMetrics[fbMetrics.length - 1];

  const fbAudienceGenderAge =
    currentFbMetric?.followers?.audience_gender_age || {};
  const genderAgeAudience = audienceGenderAge(fbAudienceGenderAge);

  const fbAudienceCity = currentFbMetric?.followers?.audience_city || {};
  const fbAudienceCityPast = pastFbMetric?.followers?.audience_city || {};
  const fbAudienceCountry = currentFbMetric.followers?.audience_country || {};
  const fbAudienceCountryPast = pastFbMetric?.followers?.audience_country || {};
  const audienceCity = audienceCityCountry(fbAudienceCity);
  const audienceCountry = audienceCityCountry(fbAudienceCountry);
  let cities = topCitiesCountries(fbAudienceCity, fbAudienceCityPast);
  let countries = topCitiesCountries(fbAudienceCountry, fbAudienceCountryPast);

  const topCities = cities.topAreas;
  const otherCities = cities.others;
  const topCountries = countries.topAreas;
  const otherCountries = countries.others;
  const citiesTotal = cities.total;
  const pastCitiesTotal = cities.totalPast;
  const countriesTotal = countries.total;
  const pastCountriesTotal = countries.totalPast;

  const totalFollowers =
    fbFollowers.length > 1
      ? fbFollowers[fbFollowers.length - 1]
      : fbFollowers[0];
  const pastTotalFollowers = !!fbFollowers.length ? fbFollowers[0] : 0;
  const countriesRemTotal = totalFollowers - countriesTotal;
  const pastCountriesRemTotal = pastTotalFollowers - pastCountriesTotal;
  otherCountries.push(["Others", pastCountriesRemTotal, countriesRemTotal]);
  const citiesRemTotal = totalFollowers - citiesTotal;
  const pastCitiesRemTotal = pastTotalFollowers - pastCitiesTotal;
  otherCities.push(["Others", pastCitiesRemTotal, citiesRemTotal]);

  const fbDates =
    fbMetrics &&
    fbMetrics.map((metric: any) => {
      let createdAt = new Date(metric.created_at._seconds * 1000);
      return new Date(createdAt).getTime();
    });

  const data = {
    followers: fbFollowers,
    impressions: fbImpressions,
    engagements: fbEngagements,
    engagementRate: fbEngagementRate,
    interactions,
    interactionsRate,
    audienceGender: genderAgeAudience.genderSeries,
    audienceAge: genderAgeAudience.ageSeries,
    audienceCity,
    audienceCountry,
    cities: { topCities, citiesTotal, otherCities },
    countries: { topCountries, countriesTotal, otherCountries },
    dates: fbDates,
    postsCount: fbPostsCount,
  };

  return data;
};

export const getIgData = (igMetrics: any, igType: string) => {
  const igFollowers =
    igMetrics &&
    igMetrics.map((metric: any) => {
      return metric.metadata?.followers_count || 0;
    });
  const profileViews =
    igMetrics &&
    igMetrics.map((metric: any) => {
      return metric.user_metrics?.profile_views || 0;
    });

  const igDates =
    igMetrics &&
    igMetrics.map((metric: any) => {
      let createdAt = new Date(metric.created_at._seconds * 1000);
      return new Date(createdAt).getTime();
    });

  let data: any = {};
  if (igType === "timeline") {
    const igImpressions =
      igMetrics &&
      igMetrics.map((metric: any) => {
        return metric.user_metrics?.impressions;
      });
    //renamed from engagements
    const igInteractions =
      igMetrics &&
      igMetrics.map((metric: any) => {
        return metric.user_metrics?.engagement || 0;
      });
    const igInteractionRate =
      igMetrics &&
      igMetrics.map((metric: any) => {
        return Number(metric.user_metrics?.engagement_rate || 0) * 100;
      });

    const allPostsData = igMetrics
      ? igMetrics.flatMap((metric) => metric.posts?.data)
      : [];

    const uniquePostsMap = {};
    allPostsData.forEach((post) => {
      if (!uniquePostsMap[post?.id]) {
        uniquePostsMap[post?.id] = post;
      } else {
        const postDate1 = new Date(post.timestamp);
        const postDate2 = new Date(uniquePostsMap[post?.id].timestamp);
        if (postDate1 > postDate2) {
          uniquePostsMap[post?.id] = post;
        }
      }
    });

    const uniquePosts = Object.values(uniquePostsMap);

    const igPostsCount = [uniquePosts.length];

    const igReelsCount = [
      uniquePosts.filter((post: any) => {
        return post?.media_type === "VIDEO";
      }).length,
    ];

    const pastIgMetric = igMetrics.length > 0 && igMetrics[0];
    const currentIgMetric =
      igMetrics.length > 0 && igMetrics[igMetrics.length - 1];

    const igAudienceGenderAge =
      currentIgMetric?.demographic_data?.audience_gender_age || {};
    const genderAgeAudience = audienceGenderAge(igAudienceGenderAge);
    const igAudienceCity =
      currentIgMetric?.demographic_data?.audience_city || {};
    const igAudienceCountry =
      currentIgMetric?.demographic_data?.audience_country || {};
    const igAudienceCityPast =
      pastIgMetric?.demographic_data?.audience_city || {};
    const igAudienceCountryPast =
      pastIgMetric?.demographic_data?.audience_country || {};
    const audienceCity = audienceCityCountry(igAudienceCity);
    const audienceCountry = audienceCityCountry(igAudienceCountry);
    const cities = topCitiesCountries(igAudienceCity, igAudienceCityPast);
    const countries = topCitiesCountries(
      igAudienceCountry,
      igAudienceCountryPast
    );

    const topCities = cities.topAreas;
    const otherCities = cities.others;
    const topCountries = countries.topAreas;
    const otherCountries = countries.others;
    const citiesTotal = cities.total;
    const pastCitiesTotal = cities.totalPast;
    const countriesTotal = countries.total;
    const pastCountriesTotal = countries.totalPast;

    const totalFollowers = !!igFollowers.length ? igFollowers[0] : 0;
    const pastTotalFollowers =
      igFollowers.length > 1
        ? igFollowers[igFollowers.length - 1]
        : igFollowers[0];
    const countriesRemTotal = totalFollowers - countriesTotal;
    const pastCountriesRemTotal = pastTotalFollowers - pastCountriesTotal;
    otherCountries.push(["Others", pastCountriesRemTotal, countriesRemTotal]);
    const citiesRemTotal = totalFollowers - citiesTotal;
    const pastCitiesRemTotal = pastTotalFollowers - pastCitiesTotal;
    otherCities.push(["Others", pastCitiesRemTotal, citiesRemTotal]);

    data = {
      followers: igFollowers,
      impressions: igImpressions,
      interactions: igInteractions,
      interactionsRate: igInteractionRate,
      audienceGender: genderAgeAudience.genderSeries,
      audienceAge: genderAgeAudience.ageSeries,
      audienceCity,
      audienceCountry,
      cities: { topCities, citiesTotal, otherCities },
      countries: { topCountries, countriesTotal, otherCountries },
      dates: igDates,
      postsCount: igPostsCount,
      reelsCount: igReelsCount,
      profileViews,
    };
  } else {
    const impressionsList =
      igMetrics &&
      igMetrics.map((metric: any) => {
        return metric.story_metrics.map((story: any) => {
          return story?.impressions;
        });
      });
    const StoriesImpressions = impressionsList.map((item: any[]) =>
      item.reduce((a: number, b: number) => a + b, 0)
    );
    const storiesCount =
      igMetrics &&
      igMetrics.map((metric) => {
        return metric.story_metrics.length || 0;
      });
    data = {
      impressions: StoriesImpressions,
      dates: igDates,
      storiesCount: storiesCount,
    };
  }
  return data;
};

export const getTwitterData = (twitterMetrics: any) => {
  const twitterFollowers =
    twitterMetrics &&
    twitterMetrics.map((metric: any) => {
      return metric.total_followers;
    });
  const twitterImpressions =
    twitterMetrics &&
    twitterMetrics.map((metric: any) => {
      return metric.total_impressions || 0;
    });
  const twitterEngagements =
    twitterMetrics &&
    twitterMetrics.map((metric: any) => {
      return metric.total_engagements || 0;
    });
  const twitterEngagementRate =
    twitterMetrics &&
    twitterMetrics.map((metric: any) => {
      return metric.engagement_rate;
    });
  const twitterPostsCount =
    twitterMetrics &&
    twitterMetrics.map((metric: any) => {
      return metric.posts?.count || 0;
    });
  const twitterDates =
    twitterMetrics &&
    twitterMetrics.map((metric: any) => {
      let createdAt = new Date(metric.created_at._seconds * 1000);
      return new Date(createdAt).getTime();
    });

  const data = {
    followers: twitterFollowers,
    impressions: twitterImpressions,
    engagements: twitterEngagements,
    engagementRate: twitterEngagementRate,
    postsCount: twitterPostsCount,
    dates: twitterDates,
  };
  return data;
};

export const getTiktokData = (tiktokMetrics: any, name = "none") => {
  const tiktokFollowers =
    tiktokMetrics &&
    tiktokMetrics.map((metric: any) => {
      return metric.total_followers || 0;
    });
  const tiktokViews =
    tiktokMetrics &&
    tiktokMetrics.map((metric: any) => {
      return metric.video_views || 0;
    });
  const tiktokVideoCount =
    tiktokMetrics &&
    tiktokMetrics.map((metric: any) => {
      return metric.video_count || 0;
    });
  const tiktokEngagements =
    tiktokMetrics &&
    tiktokMetrics.map((metric: any) => {
      return metric.engagements || 0;
    });
  const tiktokEngagementRate =
    tiktokMetrics &&
    tiktokMetrics.map((metric: any) => {
      return metric.engagement_rate || 0;
    });
  const tiktokLikes =
    tiktokMetrics &&
    tiktokMetrics.map((metric: any) => {
      return metric?.likes_count || 0;
    });

  const tiktokDates =
    tiktokMetrics &&
    tiktokMetrics.map((metric: any) => {
      let createdAt = new Date(metric.created_at._seconds * 1000);
      return new Date(createdAt).getTime();
    });

  const data = {
    followers: tiktokFollowers,
    impressions: tiktokViews,
    engagements: tiktokEngagements,
    engagementRate: tiktokEngagementRate,
    videoCount: tiktokVideoCount,
    likesCount: tiktokLikes,
    postsCount: tiktokVideoCount,
    dates: tiktokDates,
  };

  return data;
};

export const getYouTubeData = (youtubeMetrics: any) => {
  const youtubeSubscribers =
    youtubeMetrics &&
    youtubeMetrics.map((metric: any) => {
      return metric.total_subscribers || 0;
    });
  const youtubeViews =
    youtubeMetrics &&
    youtubeMetrics.map((metric: any) => {
      return metric.video_views || 0;
    });
  const youtubePostsCount =
    youtubeMetrics &&
    youtubeMetrics.map((metric: any) => {
      return metric.posts?.count || 0;
    });

  const youtubeDates =
    youtubeMetrics &&
    youtubeMetrics.map((metric: any) => {
      let createdAt = new Date(metric.created_at._seconds * 1000);
      return new Date(createdAt).getTime();
    });

  const data = {
    followers: youtubeSubscribers,
    videoViews: youtubeViews,
    postsCount: youtubePostsCount,
    dates: youtubeDates,
  };

  return data;
};

export const getAppleMusicData = (appleMusicMetrics: any) => {
  const appleMusicListeners =
    appleMusicMetrics &&
    appleMusicMetrics.map((metric: any) => {
      return metric.listeners || 0;
    });
  const appleMusicPlays =
    appleMusicMetrics &&
    appleMusicMetrics.map((metric: any) => {
      return metric.plays || 0;
    });
  const appleMusicShazams =
    appleMusicMetrics &&
    appleMusicMetrics.map((metric: any) => {
      return metric.shazams || 0;
    });
  const appleMusicSongPurchases =
    appleMusicMetrics &&
    appleMusicMetrics.map((metric: any) => {
      return metric.song_purchases || 0;
    });
  const appleMusicDates =
    appleMusicMetrics &&
    appleMusicMetrics.map((metric: any) => {
      return new Date(metric.created_at.toDate()).getTime();
    });

  const pastAppleMusicMetric =
    appleMusicMetrics.length > 0 && appleMusicMetrics[0];

  const currentAppleMusicMetric =
    appleMusicMetrics.length > 0 &&
    appleMusicMetrics[appleMusicMetrics.length - 1];

  const appleMusicAudienceGenderAge =
    currentAppleMusicMetric?.demographic_data?.audience_gender_age || {};
  const genderAgeAudience = audienceGenderAge(appleMusicAudienceGenderAge);

  const appleMusicAudienceCity =
    currentAppleMusicMetric?.demographic_data?.audience_city || {};
  const appleMusicAudienceCityPast =
    pastAppleMusicMetric?.demographic_data?.audience_city || {};
  const appleMusicAudienceCountry =
    currentAppleMusicMetric.demographic_data?.audience_country || {};
  const appleMusicAudienceCountryPast =
    pastAppleMusicMetric?.demographic_data?.audience_country || {};
  const audienceCity = audienceCityCountry(appleMusicAudienceCity);
  const audienceCountry = audienceCityCountry(appleMusicAudienceCountry);

  let cities = topCitiesCountries(
    appleMusicAudienceCity,
    appleMusicAudienceCityPast
  );
  let countries = topCitiesCountries(
    appleMusicAudienceCountry,
    appleMusicAudienceCountryPast
  );

  const topCities = cities.topAreas;
  const otherCities = cities.others;
  const topCountries = countries.topAreas;
  const otherCountries = countries.others;
  const citiesTotal = cities.total;
  const countriesTotal = countries.total;

  const audienceGender = genderAgeAudience.genderSeries.slice(0, -1);
  const audienceAge = genderAgeAudience.ageSeries.slice(0, -1);

  const data = {
    followers: appleMusicListeners,
    impressions: appleMusicPlays,
    engagements: appleMusicShazams,
    engagementRate: appleMusicSongPurchases,
    dates: appleMusicDates,
    audienceGender,
    audienceAge,
    audienceCountry,
    audienceCity,
    cities: { topCities, citiesTotal, otherCities },
    countries: { topCountries, countriesTotal, otherCountries },
  };

  return data;
};

export const getSpotifyData = (spotifyMetrics: any) => {
  const followers =
    spotifyMetrics &&
    spotifyMetrics?.map((metric) => {
      return parseInt(metric.followers, 10);
    });
  const streams =
    spotifyMetrics &&
    spotifyMetrics?.map((metric) => {
      return parseInt(metric.streams, 10);
    });
  const listeners =
    spotifyMetrics &&
    spotifyMetrics?.map((metric) => {
      return parseInt(metric.listeners, 10);
    });
  const engagementRate =
    spotifyMetrics &&
    spotifyMetrics.map((metric: any) => {
      return metric.streams / metric.followers;
    });
  const pastSpotifyMetric = spotifyMetrics.length > 0 && spotifyMetrics[0];
  const currentSpotifyMetric =
    spotifyMetrics.length > 0 && spotifyMetrics[spotifyMetrics.length - 1];

  const spotifyAudienceCity =
    currentSpotifyMetric?.demographic_data?.audience_city || {};
  const spotifyAudienceCityPast =
    pastSpotifyMetric?.demographic_data?.audience_city || {};
  const spotifyAudienceCountry =
    currentSpotifyMetric.demographic_data?.audience_country || {};
  const spotifyAudienceCountryPast =
    pastSpotifyMetric?.demographic_data?.audience_country || {};
  const audienceCity = audienceCityCountry(spotifyAudienceCity);
  const audienceCountry = audienceCityCountry(spotifyAudienceCountry);
  let cities = topCitiesCountries(spotifyAudienceCity, spotifyAudienceCityPast);
  let countries = topCitiesCountries(
    spotifyAudienceCountry,
    spotifyAudienceCountryPast
  );

  const topCities = cities.topAreas;
  const otherCities = cities.others;
  const topCountries = countries.topAreas;
  const otherCountries = countries.others;
  const citiesTotal = cities.total;
  const countriesTotal = countries.total;
  const dates =
    spotifyMetrics &&
    spotifyMetrics.map((metric: any) => {
      let createdAt = new Date(metric.created_at._seconds * 1000);
      return new Date(createdAt).getTime();
    });

  const gendersTotal =
    spotifyMetrics[spotifyMetrics?.length - 1]?.demographic_data?.gender;
  let audienceGender = ["0", "0", "0", "0"];

  if (gendersTotal) {
    const totalGender =
      parseInt(gendersTotal.male, 10) +
      parseInt(gendersTotal.female, 10) +
      parseInt(gendersTotal.nonbinary, 10) +
      parseInt(gendersTotal.unknown, 10);

    audienceGender = [
      ((parseInt(gendersTotal.male, 10) / totalGender) * 100).toFixed(2),
      ((parseInt(gendersTotal.female, 10) / totalGender) * 100).toFixed(2),
      ((parseInt(gendersTotal.nonbinary, 10) / totalGender) * 100).toFixed(2),
      ((parseInt(gendersTotal.unknown, 10) / totalGender) * 100).toFixed(2),
    ];
  }

  const maleList: number[] = [];
  const femaleList: number[] = [];
  const nonbinaryList: number[] = [];
  const unknown: number[] = [];

  if (
    spotifyMetrics[spotifyMetrics?.length - 1]?.demographic_data?.genderByAge
  ) {
    Object.entries(
      spotifyMetrics[spotifyMetrics?.length - 1].demographic_data.genderByAge
    ).forEach(([key, value]: [string, any]) => {
      if (key.includes("gender")) {
        maleList.push(parseInt(value.male, 10));
        femaleList.push(parseInt(value.female, 10));
        nonbinaryList.push(parseInt(value.nonbinary, 10));
        unknown.push(parseInt(value.unknown, 10));
      }
    });
  }

  const audienceAge = [
    { data: maleList.length ? maleList : new Array(7).fill(0), name: "Male" },
    {
      data: femaleList.length ? femaleList : new Array(7).fill(0),
      name: "Female",
    },
    {
      data: nonbinaryList.length ? nonbinaryList : new Array(7).fill(0),
      name: "Non-binary",
    },
    { data: unknown.length ? unknown : new Array(7).fill(0), name: "Unknown" },
  ];

  const data = {
    dates,
    followers,
    listeners,
    audienceAge,
    audienceCity,
    audienceGender,
    audienceCountry,
    engagementRate,
    engagements: streams,
    cities: { topCities, citiesTotal, otherCities },
    countries: { topCountries, countriesTotal, otherCountries },
  };

  return data;
};
