import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  btn: {
    width: "159px",
    height: "45px",
    background: "#000",
    borderRadius: "10px",
    textTransform: "capitalize",
    fontFamily: "'Avenir Black', sans-serif",
    fontSize: "15px",
    fontWeight: 900,
    fontStyle: "normal",
    lineHeight: "22px",
    color: "#FFFFFF",
    marginBottom: "5px",
    justifyContent: "center",
    float: "right",
    alignItems: "center",

    "&:hover": {
      opacity: 0.85,
      background: "#000",
    },

    "&:disabled": {
      color: "#fff !important",
      opacity: 0.5,
      cursor: "not-allowed !important",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    width: "533px",
    color: "#313131",
    backgroundColor: theme.palette.background.paper,
    borderRadius: "15px",
    boxShadow: "0 5px 10px 0 rgba(0, 0, 0, 0.1)",
    "&:focus": {
      outline: "none",
    },
  },
  paper: {
    padding: theme.spacing(0, 4, 0),
  },
  top: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "0px -32px",
    padding: "40px 20px",
    height: 68,
    background: "#ABADAD",
    borderTopLeftRadius: "15px",
    borderTopRightRadius: "15px",
  },
  print: {
    fontSize: "30px",
    lineHeight: "41px",
    color: "#000000",
    fontFamily: "'Avenir Black', sans-serif",
    fontWeight: 900,

    "@media screen and (max-width: 400px)": {
      fontSize: 26,
    },
  },
  icon: {
    color: "#000",
    cursor: "pointer",
    fontSize: "30px",
    fontWeight: "bold",
    transition: "all .3s ease",
  },
  postSection: {
    width: "100%",
    paddingBottom: "26px",
    "& div > span": {
      marginRight: 0,
    },
    "& div > div > div": {
      marginRight: 0,
    },
  },
  line: {
    opacity: " 0.3",
    height: "1px",
    background: "#979797",
  },

  bottom: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "74px",
    margin: "0 16px",

    "& > div > *": {
      marginBottom: "5px",
    },
    "& > div > :last-child": {
      marginRight: "32px",
      marginLeft: 10,
    },
  },

  bottomResp: {
    "@media screen and (max-width: 430px)": {
      marginRight: 5,
      marginLeft: -8,
    },
  },

  bottomRespCommon: {
    "@media screen and (max-width: 430px)": {
      margin: "0 10px",
    },
  },
  field: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "26px",
    transition: "all 0.3s ease",
  },
  datePicker: {
    boxShadow: "0px 5px 45px rgba(0, 0, 0, 0.07)",
    borderRadius: "5px",
    backgroundColor: "#fff",
    position: "absolute",
    right: "unset",
    zIndex: 1000,
    marginTop: 2,
    overflow: "auto",

    "& > :first-child": {
      minWidth: 110,
      width: "unset",
    },

    "& > :first-child > .rdrInputRanges": {
      display: "none",
    },

    "& > :last-child > .rdrDateDisplayWrapper": {
      display: "none",
    },
    "@media screen and (max-width: 580px)": {
      width: 340,
      overflow: "auto",
      display: "flex",
      justifyContent: "end",
    },
  },
  error: {
    color: "#f44336",
    fontSize: "15px",
    textAlign: "center",
    width: "75%",
  },
}));
