import styled from "styled-components";

const CreateCampaign = styled.div`
  padding-bottom: 50px;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  max-width: 760px;
  font-family: "Avenir Book", sans-serif;

  .title {
    &__main {
      font-weight: 900;
      color: #000000;
      font-size: 30px;
      line-height: 41px;
      margin-bottom: 50px;
      letter-spacing: 1px;
      font-family: "Avenir Black", sans-serif;
    }

    &__sub {
      font-weight: 900;
      font-size: 24px;
      line-height: 33px;
      letter-spacing: 1px;
      font-family: "Avenir Black", sans-serif;
    }

    &__basic {
      font-weight: 900;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 1px;
      font-family: "Avenir Black", sans-serif;
    }
  }

  .description {
    &__main {
      font-weight: 900;
      font-size: 16px;
      line-height: 22px;
      max-width: 760px;
    }
    &__sub {
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      color: #606060;
      padding-top: 4px;
      padding-bottom: 10px;
    }

    &__small {
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;

      color: #c4c4c4;
    }
  }

  .img {
    &__main {
      width: 35.79px;
      height: 40px;
      margin-right: 16px;
    }

    &__small {
      width: 28.63px;
      height: 32px;
      margin: 0 14px;
    }
  }

  .items-center {
    display: flex;
    align-items: center;
  }

  .connection-container {
    background: #ffffff;
    box-shadow: 0px 5px 45px rgba(0, 0, 0, 0.07);
    border-radius: 15px;
    padding: 40px;
  }

  .hidden-input {
    width: 100%;
    border: none;
    text-overflow: ellipsis;

    &:focus {
      outline: none;
    }
  }

  .campaign {
    &__container {
      display: flex;
      flex-direction: column;

      &:not(:first-child) {
        margin-top: 40px;
      }
    }

    &__input {
      padding: 14px;
      border: 1px solid #c4c4c4;
      border-radius: 10px;
      font-size: 16px;
      line-height: 22px;

      color: #000000;

      &--disabled {
        background: #ecf3f6;
      }
    }

    &__textarea {
      border: 1px solid #c4c4c4;
      border-radius: 10px;
      resize: vertical;
      min-height: 119px;
      max-height: 200px;
      padding: 14px;

      font-size: 16px;
      line-height: 22px;

      color: #000000;
    }

    &__connect {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #c4c4c4;
      border-radius: 10px;
      margin-top: 12px;

      @media screen and (max-width: 600px) {
        display: flex;
        flex-direction: column;
        padding: 14px 0;
      }
    }
  }

  .link {
    text-decoration: none;
    color: #000000;
    cursor: pointer;
  }

  .btn {
    width: fit-content;
    height: 53px;
    border-radius: 10px;
    font-weight: 900;
    font-size: 24px;
    line-height: 33px;
    color: #ffffff;
    margin: 5px;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    padding: 10px 40px;

    &:hover {
      opacity: 0.85;
    }

    &--blue {
      background: #257cc7;
    }
    &--black {
      background: #000000;
    }

    @media screen and (max-width: 460px) {
      font-size: 16px;
      line-height: 28px;
    }
  }

  .btn-progress {
    color: #ffffff;
    margin-left: 10px;
  }

  @media screen and (max-width: 1000px) {
    margin-top: 130px;
  }
`;

export default CreateCampaign;
