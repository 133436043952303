import styled from "styled-components";

const customFullCalendar = styled.div`
  padding-bottom: 50px;

  .fc-header-toolbar {
    justify-content: flex-start;
    margin-bottom: 2.8rem !important;
  }
  .fc-scroller,
  .fc-scroller-liquid-absolute {
    overflow: hidden;
  }

  .fc-scrollgrid,
  .fc-scrollgrid-liquid {
    border: none !important;
    filter: drop-shadow(0px 5px 45px rgba(0, 0, 0, 0.07));
  }
  .fc-today-button {
    display: none;
  }

  .fc .fc-button-primary:not(:disabled):active:focus,
  .fc .fc-button-primary:focus {
    box-shadow: unset;
    background-color: unset;
    color: #101010;
  }
  .fc-prev-button,
  .fc-next-button {
    background-color: unset;
    border: none;
    color: #000;
    width: auto;
    padding: 0;
    height: 100%;
    &:hover {
      background-color: rgba(0, 0, 0, 0.08);
      color: #101010;
    }
  }
  .fc-next-button{
    margin-left: 5px !important;
  }
  .fc-theme-standard td:last-child,
  .fc-theme-standard th:last-child {
    border-right: none;
  }

  .fc-daygrid-day {
    height: 238px;
  }

  .fc-event-main {
    width: 100%;
  }

  .fc-daygrid-day-number {
    font-family: "Futura Bold", sans-serif !important;
    font-weight: 900;
  }
  .fc-col-header {
    height: 60px !important;
    background: #e3e3e3;
    display: flex;
    align-items: center;
    border-bottom: solid 1px #f4f4f4;
    tbody {
      width: 100%;
    }

    tr {
      display: flex;
      justify-content: space-around;
    }

    th {
      border: none;
      text-transform: uppercase;
    }
  }

  .fc .fc-col-header-cell-cushion {
    display: inline-block;
    padding: 2px 4px;
    font-size: 1rem;
    font-family: "Avenir Black", sans-serif;
    color: #000;
    font-weight: 900;
    line-height: 20px;
    letter-spacing: 0.21px;
    text-transform: uppercase;
  }

  .fc-day-past,
  .fc-day-other {
    background: ${(props) =>
      props.weekly ? "#fffff !important" : "#E3E3E3 !important"};

    border: 1px solid #efefef;
    a.fc-daygrid-day-number {
      color: #abadad;
    }
  }

  .fc-day-future {
    background: #ffffff;
    border: 1px solid #efefef;
  }
  .fc-day-disabled {
    border: solid 1px #f4f4f4;
  }
  .fc-daygrid-day-top {
    a {
      border-radius: 50%;
      height: 30px;
      width: ${(props) => (props.weekly ? "inherit" : "30px")};
    }
  }

  .fc-day-today {
    background: #fff !important;
    border: 1px solid #efefef;

    a {
      color: ${(props) =>
        props.weekly ? "#3b5998 !important" : "#000000 !important"};
      background-color: ${(props) =>
        props.weekly ? "inherit" : "#fff !important"};

      display: flex;
      align-items: center;
      justify-content: center;
      &.fc-daygrid-day-number {
        background: #e3e3e3 !important;
      }
    }
  }

  .fc-timegrid-event-harness {
    a {
      border-radius: 4px;
    }
  }

  .fc-daygrid-event-dot {
    display: none;
  }

  .fc-daygrid-event {
    margin-top: 0;
    margin-bottom: 2px;
  }

  .fc-event-time {
    display: none;
  }
  .fc-event-title,
  .fc-sticky {
    max-height: unset;
    font-family: "Avenir Book", sans-serif !important;
  }

  .fc-h-event {
    border: none;
  }
  .fc-daygrid-event-harness {

    &:first-of-type {
      margin-top: 0px;
    }
  }
  }
  .fc-direction-ltr .fc-daygrid-event.fc-event-start,
  .fc-direction-rtl .fc-daygrid-event.fc-event-end {
    margin-left: 6px;
  }
  .fc .fc-daygrid-day-bottom {
    font-size: 0.625em;
    margin: 2px 12px 0;
    background: #abadad;
    height: 20px;
    text-align: center;
    a {
      padding: 3px;
    }
  }
  .fc-day-grid-event {
    font-family: "Avenir Book", sans-serif;
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    letter-spacing: -0.215833px;
    color: #000000;
  }
  .fc .fc-daygrid-more-link {
    background-color: #abadad !important;
    border-radius: 5px;
  }

  .fc-event-main-frame {
    margin-top: -6px;

    cursor: pointer;
    font-family: "Avenir Book", sans-serif;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.185px;
  }

  .fc-toolbar-title {
    font-family: "Avenir Black", sans-serif !important;
    font-weight: 900;
    font-size: 20px;
    line-height: 25px;
    color: #101010;
    width: 166px;
  }

  .personal-event {
    background: #2c3e50;
    transition: all 0.1s linear;

    &:hover {
      background: #2c3e50;
      transform: scale(1.05);
    }
  }
  .fc .fc-popover {
    position: absolute;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
    top: 30% !important;
    left: 45% !important;
    background: #abadad !important;
    max-width: 430px;
  }

  .fc .fc-more-popover .fc-popover-body {
    max-height: 260px;
    overflow: auto;
  }
  .fc-popover-title {
    font-family: "Avenir Book", sans-serif;
  }
  .fc .fc-timegrid .fc-daygrid-body {
    display: none;
  }

  .fc-v-event {
    border: none !important;
  }

  .fc-daygrid-day-bottom {
    display: flex;
    align-items: center;
    height: 25px;
    width: 96%;
    margin: 2px 6px 0 !important;
    background: #d3d3d3;
    border-radius: 5px;
    cursor: pointer;

    font-family: "Avenir Book", sans-serif;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.185px;

    color: #5c595c;

    a {
      width: 100%;
      height: 100%;
      padding: 6px;
    }
  }

  .event {
    background: none;
  }

  .fc-daygrid-dot-event {
    padding: 0 !important;
    margin-top: ${(props) => props.event && "-2px"};
  };

  .fc-daygrid-dot-event:hover, .fc-daygrid-dot-event.fc-event-mirror {
    background: none;
  }

  .calendar-container {
    min-width: 1210px;
  }

  @media only screen and (max-width: 1340px) {
    width: 100%;
    overflow: auto;
  }

  @media only screen and (max-width: 900px) {
    .event {
      width: unset;
      max-width: 96%;
      min-width: 10%;
      overflow: hidden;
      }
    }

  @media only screen and (max-width: 614px) {
    .fc .fc-col-header-cell-cushion {
      font-size: 12px;
    }
  }

  @media only screen and (max-width: 540px) {
    .fc .fc-col-header-cell-cushion {
      font-size: 10px;
    }
  }

  @media only screen and (max-width: 460px) {
    .fc .fc-col-header-cell-cushion {
      font-size: 12px;
    }
  }

  @media only screen and (max-width: 400px) {
    .fc .fc-col-header-cell-cushion {
      font-size: 6px;
    }

    .fc .fc-toolbar {
      flex-direction: row;
      align-items: flex-start;

      & > :first-child {
        margin-bottom: 8px;
      }
    }
  }

  @media only screen and (max-width: 350px) {
    .fc .fc-col-header-cell-cushion {
      font-size: 5px;
    }
  }
`;

export const EventTitle = styled.span`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-around;

  height: 19.76px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Avenir Book", sans-serif;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  letter-spacing: -0.215833px;
  color: #000000;
  font-weight: normal;

  margin: 2px 0 !important;

  &:hover {
    transform: ${(props) =>
      props.multipleDate ? "scale(1.005)" : "scale(1.05)"};
  }
`;

export default customFullCalendar;
