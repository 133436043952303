/**
 * @prettier
 */
import React, { FunctionComponent, useEffect, useState } from "react";
import { Container, CssBaseline, Grid, Box } from "@material-ui/core";
import { Link } from "@reach/router";
import { fromEvent, Observable } from "rxjs";

import { ReactComponent as Logo } from "statics/logo.svg";
import { ReactComponent as TR } from "statics/media/tr.svg";
import { ReactComponent as BL } from "statics/media/bl.svg";
import { ReactComponent as BackArrow } from "statics/media/backArrow.svg";
import { PageType } from "types/PageType";
import Login from "components/Login";
import ForgotPass from "components/ForgotPass";
import SignUp from "components/SignUp";
import { useStyles } from "./styles";
import FormFooter from "./FormFooter";

export const AuthPage: FunctionComponent<PageType> = ({ path }) => {
  const classes = useStyles({});
  const [mobileMenu, switchMobileMenu] = useState(
    !(document.documentElement.offsetWidth > 1000)
  );

  const resize$: Observable<Event> = fromEvent(window, "resize");

  resize$.subscribe((res: any) => {
    if (res && res.currentTarget) {
      switchMobileMenu(!(res.currentTarget.innerWidth > 1000));
    }
  });

  useEffect(() => {
    document.body.classList.add("body-signin");
    document.body.style.overflowY = "scroll";
  }, []);
  return (
    <Container component="main" maxWidth="xs" className={classes.root}>
      <CssBaseline />
      {!mobileMenu && (
        <>
          <Box position="absolute" top={0} right={0}>
            <TR className={classes.tr} />
          </Box>
          <Box position="absolute" bottom={0} left={0}>
            <BL className={classes.br} />
          </Box>
        </>
      )}
      <div className={classes.paper}>
        <Logo className={classes.logo} />
        <span className={classes.pageHeader}>
          {path === "sign-in" ? (
            <div>
              <span style={{ lineHeight: 1.25 }}>Welcome </span> <br />{" "}
              <span style={{ lineHeight: 1.25 }}>to your Be-Hookd Kit!</span>
            </div>
          ) : (
            ""
          )}
          {path === "sign-up" ? "Create Your Account" : ""}
          {path === "forgot-password" ? "Forgot Password?" : ""}
        </span>
        {path === "sign-in" && <Login />}
        {path === "forgot-password" && <ForgotPass />}
        {path === "sign-up" && <SignUp />}

        <Grid container className={classes.linkArea}>
          <Grid item xs={12}>
            <Link
              to={path === "sign-in" ? "/forgot-password" : "/sign-in"}
              className={classes.link}
              style={{ opacity: 0.7 }}
            >
              {path === "sign-in"
                ? "Forgot your password?"
                : path === "forgot-password" && (
                    <>
                      <BackArrow className={classes.backToLogin} />
                      Back to Login
                    </>
                  )}
            </Link>
          </Grid>
          <Grid item xs={12}>
            <FormFooter path={path} />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};
