import { Box } from "@material-ui/core";
import React from "react";

import twitter from "statics/media/twitter-vector.png";
import instagram from "statics/media/instagram-vector.png";
import facebook from "statics/media/fb-vector.png";
import defaultIcon from "statics/media/universal-2.png";
import Image from "components/Common/Image/Image";
import { SocialMediaPlatform } from "containers/BeAmplified/Campaigns/Organic/SelectCampaignPage";

type SelectCampaignBoxProps = {
  action: Function;
  title: string;
  actionText: string;
  description: string;
  platform: SocialMediaPlatform;
};

export const getPlatformImage = (platform?: SocialMediaPlatform) => {
  switch (platform?.toLocaleLowerCase()) {
    case SocialMediaPlatform.Twitter.toLocaleLowerCase():
      return twitter;
    case SocialMediaPlatform.Instagram.toLocaleLowerCase():
      return instagram;
    case SocialMediaPlatform.Facebook.toLocaleLowerCase():
      return facebook;
    default:
      return defaultIcon;
  }
};

const SelectCampaignBox: React.FC<SelectCampaignBoxProps> = ({
  title,
  actionText,
  description,
  action,
  platform,
}) => {
  return (
    <Box>
      <div
        className="campaign-element link"
        onClick={() => action(platform, title)}
        style={{
          minHeight: 360,
          maxHeight: 360,
        }}
      >
        <p className="title__element" style={{ margin: 0 }}>
          <span>{title}</span>
          <br />
          <span>{actionText}</span>
        </p>

        {/* Icon */}
        <Box display="flex" alignItems="center" padding="20px 0">
          <Image className="img" src={getPlatformImage(platform)} alt="" />
          <div className="right-arrow"></div>
          <div className="right-arrow"></div>
        </Box>

        {/* info */}
        <span className="context">{description}</span>
      </div>
    </Box>
  );
};

export default SelectCampaignBox;
