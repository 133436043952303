export const getQueryParameter = (parameter: string) => {
  const url = window.location.href;
  const parameterIndex = url.indexOf(`${parameter}=`);
  if (parameterIndex === -1) {
    return "";
  }

  const startOfParameterValue = parameterIndex + parameter.length + 1;
  const endOfParameterValue = url.indexOf("&", startOfParameterValue);
  const parameterValue = url.substring(
    startOfParameterValue,
    endOfParameterValue !== -1 ? endOfParameterValue : undefined
  );

  return parameterValue;
};
