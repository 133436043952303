import { DateTime } from "luxon";

export const formatDate = (arr: Array<number>) => {
  return arr.map((dt) => {
    const date = new Date(dt);
    /* maybe needed in future 
    const dateTimeFormat = new Intl.DateTimeFormat("en", {
      year: "numeric",
      month: "numeric",
      day: "2-digit",
    });
    const [
      { value: month },
      ,
      { value: day },
      ,
      { value: year },
    ] = dateTimeFormat.formatToParts(date);
    */
    return DateTime.fromJSDate(date).toFormat("dd LLL");
  });
};

export const formatDateNew = (arr: Array<number>) => {
  return arr.map((dt) => {
    const date = new Date(dt);
    return DateTime.fromJSDate(date).toFormat("yyyy-LL-dd");
  });
};

export const todayString = () =>
  `${new Date().getDate()}/${
    new Date().getMonth() + 1
  }/${new Date().getFullYear()}`;

export const longDate = (date: any) => {
  return DateTime.fromJSDate(date).toFormat("dd LLL yyyy");
};
export const dateObjectFromStr = (str: string) => {
  //string must be in this format: day/month/year or day month year or day-month-year
  const dateStrArr = str.split(" ");
  const validDateStr = [dateStrArr[1], dateStrArr[0], dateStrArr[2]].join("-");
  const date = new Date(validDateStr);
  return date;
};

export const shortDate = (date) => {
  let startDate;
  let endDate;

  if (date.monthly) {
    startDate = DateTime.fromJSDate(new Date(date.startDate)).toFormat(
      "dd-LL-yyyy"
    );

    endDate = DateTime.fromJSDate(new Date(date.endDate)).toFormat(
      "dd-LL-yyyy"
    );
  } else {
    startDate = DateTime.fromJSDate(date.startDate).toFormat("dd-LL-yyyy");
    endDate = DateTime.fromJSDate(date.endDate).toFormat("dd-LL-yyyy");
  }

  return {
    startDate,
    endDate,
  };
};

const toDate = (dateStr) => {
  try {
    if (!dateStr.includes("-")) return false;

    let [day, month, year] = dateStr.split("-");
    day = day.length < 2 ? `0${day}` : day;
    month = month.length < 2 ? `0${month}` : month;

    const date = `${day}-${month}-${year}`.replace(
      /(\d{2})-(\d{2})-(\d{4})/,
      "$2/$1/$3"
    );

    return new Date(date);
  } catch (error) {
    return false;
  }
};

export const urlDates = (
  dateRange,
  history,
  paramsChange,
  setDateRange,
  location
) => {
  const { startDate, endDate } = shortDate(dateRange);

  const start = new URLSearchParams(location.search).get("start");
  const end = new URLSearchParams(location.search).get("end");

  if (location?.pathname.includes("calendar")) {
    const monthly = new URLSearchParams(location.search).get("monthly");

    const { startDate, endDate } = shortDate(dateRange);

    if ((!start || !end) && monthly) {
      history.push({
        pathname: location.pathname,
        search: `${location.search}&start=${startDate}&end=${endDate}`,
      });
    }

    if (start && end && (start !== startDate || end !== endDate) && monthly) {
      if (paramsChange === "date-change") {
        history.push({
          pathname: location.pathname,
          search: `?monthly=${monthly}&start=${startDate}&end=${endDate}`,
        });
        return;
      } else {
        history.push({
          pathname: location.pathname,
          search: `?monthly=${monthly}&start=${start}&end=${end}`,
        });

        const dateUpdate = toDate(start);
        if (dateUpdate) setDateRange(dateUpdate);
      }
    }

    return;
  }

  const startDt = toDate(start);
  const endDt = toDate(end);

  if (paramsChange === "date-range" || !location.search) {
    history.push({
      pathname: location.pathname,
      search: `?start=${startDate}&end=${endDate}`,
    });
  } else if (start && end && startDt && endDt) {
    if (
      location.search &&
      (start !== startDate || end !== endDate) &&
      startDt.getTime() < endDt.getTime()
    ) {
      history.push({
        pathname: location.pathname,
        search: `?start=${start}&end=${end}`,
      });
      setDateRange({
        startDate: startDt,
        endDate: endDt,
      });
    }
  }
};
