import React, { useState, useEffect, FC } from "react";
import { Grid, Box } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { useStyles, SelectItemsContainer, SelectedText } from "./styles";
import CampaignType from "./CampaignType";
// import InstagramIcon from "statics/media/instagram-vector.png";
// import FacebookIcon from "statics/media/fb-vector.png";
import TwitterIcon from "statics/media/twitter-vector.png";
import { SocialMediaPlatform } from "containers/BeAmplified/Campaigns/Organic/SelectCampaignPage";
import { getPlatformImage } from "../SelectCampaignBox";
import FacebookIcon from "statics/media/fb-vector.png";

type CampaignTypesProps = {
  selected: any;
  setSelected: (selected: any) => void;
  selectedArr: string[];
  setSelectedArr: (arr: string[]) => void;
};

const CampaignTypes: FC<CampaignTypesProps> = ({
  selected,
  setSelected,
  selectedArr,
  setSelectedArr,
}) => {
  const classes = useStyles({});
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const items: string[] = [];
    for (const key of Object.keys(selected)) {
      for (const value of selected[key]) {
        items.push(value);
      }
    }
    setSelectedArr(items);
    // eslint-disable-next-line
  }, []);

  return (
    <Grid className={classes.container}>
      <Box component="span" className={classes.label}>
        Campaign types
      </Box>
      <SelectedText>
        <div
          onClick={handleToggle}
          className={
            open ? "selected-text selected-text-active" : "selected-text"
          }
          style={{ width: !!selectedArr.length ? "287px" : "auto" }}
        >
          <p style={{ color: "#000" }}>
            {!!selectedArr.length
              ? selectedArr.toString().replace(/,/g, ", ")
              : "All"}
          </p>
        </div>
      </SelectedText>
      {open && (
        <ClickAwayListener onClickAway={handleToggle}>
          <SelectItemsContainer>
            <div className="content">
              <div className={classes.platformHeader} style={{ paddingTop: 0 }}>
                <img
                  className={classes.icon}
                  src={FacebookIcon}
                  alt={"facebook"}
                />
                <span style={{ marginLeft: "14.9px" }}>Facebook Campaigns</span>
              </div>
              <CampaignType
                selected={selected}
                setSelected={setSelected}
                platform={SocialMediaPlatform.Facebook}
                selectedArr={selectedArr}
                setSelectedArr={setSelectedArr}
              />

              {/* <div className={classes.platformHeader}>
                <img
                  className={classes.icon}
                  src={InstagramIcon}
                  alt={"instagram"}
                />
                <span style={{ marginLeft: "14.9px" }}>
                  Instagram Campaigns
                </span>
              </div>
              <CampaignType
                selected={selected}
                setSelected={setSelected}
                platform="instagram"
                selectedArr={selectedArr}
                setSelectedArr={setSelectedArr}
              /> */}

              <div className={classes.platformHeader}>
                <img
                  className={classes.icon}
                  src={TwitterIcon}
                  alt={"twitter"}
                />
                <span style={{ marginLeft: "14.9px" }}>Twitter Campaigns</span>
              </div>
              <CampaignType
                selected={selected}
                setSelected={setSelected}
                platform={SocialMediaPlatform.Twitter}
                selectedArr={selectedArr}
                setSelectedArr={setSelectedArr}
              />
              <div className={classes.platformHeader}>
                <img
                  className={classes.icon}
                  src={getPlatformImage(SocialMediaPlatform.Instagram)}
                  alt={"instagram"}
                />
                <span style={{ marginLeft: "14.9px" }}>
                  Instagram Campaigns
                </span>
              </div>
              <CampaignType
                selected={selected}
                setSelected={setSelected}
                platform={SocialMediaPlatform.Instagram}
                selectedArr={selectedArr}
                setSelectedArr={setSelectedArr}
              />
            </div>
          </SelectItemsContainer>
        </ClickAwayListener>
      )}
    </Grid>
  );
};

export default CampaignTypes;
