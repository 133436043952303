/**
 * @prettier
 */
import { useEffect, useState } from "react";
import { navigate } from "@reach/router";
import { auth } from "firebase-tools/firebase";
import { useUser } from "UserContext";
import { LocationType } from "types/PageType";
import { UserService } from "../services/UserService";
import { getAgent, AllClientsInactive } from "../utils/authHelpers";
import { updateUserPageVisit } from "../utils/userActivity";
import { decryptWithAES } from "../utils/encryption";

export type AuthHookType = {
  location?: LocationType;
};

enum fallbackUrl {
  APP = "/",
  SIGNIN = "/sign-in",
  BE_MEASURED = "/be-measured",
  DASHBOARD = "/",
  SIGNUP = "/sign-up",
  FORGOTPASS = "/forgot-password",
  PRIVACY_POLICY = "/privacy-policy",
  EXPIRED_LINK = "/public/expired",
  INVALID_LINK = "/public/invalid",
}

export const useAuthHook = ({ location }: AuthHookType) => {
  const { pathname } = location as LocationType;
  const [user, setUser] = useUser() as any;
  const [isAllowRender, setIsAllowRender] = useState(false);

  useEffect(() => {
    if (user?.role && user?.role !== "user") {
      signOut();
      setUser(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    auth.onAuthStateChanged(async (userAuth: any) => {
      if (userAuth) {
        const agent = await getAgent(userAuth.email);
        if (agent) {
          const { allInactive, clients } = await AllClientsInactive(agent);
          if (allInactive) {
            signOut();
            setUser(null);
            return;
          }
          localStorage.setItem("clients", JSON.stringify(clients));

          let authUser: any = localStorage.getItem("kit-authUser");

          if (clients.length > 0 && authUser === null) {
            setUser(clients[0][0]);
            localStorage.setItem("kit-authUser", JSON.stringify(clients[0][0]));
          }

          if (authUser !== null) {
            authUser = JSON.parse(authUser);
            setUser(authUser);
            if (authUser.id && agent.id) {
              updateUserPageVisit(authUser, agent);
            }
          } else {
            for (const client of clients) {
              // Bypass secondaryEmails check
              if (
                !client[0].secondaryEmails.some(
                  (item) => item.email === userAuth.email
                )
              ) {
                localStorage.setItem("kit-authUser", JSON.stringify(client[0]));
                if (client[0]?.id && agent.id) {
                  updateUserPageVisit(client[0], agent);
                }
              } else {
                for (const item of client[0].secondaryEmails) {
                  if (item.email === userAuth.email && item.isActivated) {
                    localStorage.setItem(
                      "kit-authUser",
                      JSON.stringify(client[0])
                    );
                    setUser(client[0]);
                    if (client[0]?.id && agent.id) {
                      updateUserPageVisit(client[0], agent);
                    }
                  }
                }
              }
            }
          }
        } else {
          const User = new UserService();
          const user: any = await User.getUserByField(
            "email",
            userAuth.email
          ).catch(setIsAllowRender);
          if (user?.length === 0) {
            console.log("User not found");
            signOut();
            console.log("signing out");
            navigate(fallbackUrl.SIGNIN);
          }
          setUser(user[0]);
          user[0]?.id && updateUserPageVisit(user[0]);
          setUser(user[0]);
        }
      } else {
        if (
          pathname.includes("/public") &&
          !pathname.includes("/invalid") &&
          !pathname.includes("/expired")
        ) {
          /// handle public urls
          const pathList = location?.pathname.split("/") || [];
          let cipher = pathList[pathList.length - 1];

          //decode twice since at times the url adds extra encoding
          cipher = decodeURIComponent(cipher);
          cipher = decodeURIComponent(cipher);

          let data;
          try {
            data = await decryptWithAES(cipher);
            data = JSON.parse(data);
          } catch (e) {
            console.log(e);
            navigate(fallbackUrl.INVALID_LINK);
            return;
          }

          const { primaryEmail, expiryDays } = data;
          const days = Number(expiryDays);
          if (primaryEmail && expiryDays) {
            if (isNaN(days)) {
              if (expiryDays !== "Never" || !primaryEmail) {
                navigate(fallbackUrl.INVALID_LINK);
              }
            } else {
              const date = new Date();
              const expiryDate = new Date(date.setDate(date.getDate() + days));
              const now = new Date();
              if (expiryDate < now) {
                navigate(fallbackUrl.EXPIRED_LINK);
              }
            }
          } else {
            navigate(fallbackUrl.INVALID_LINK);
          }

          const User = new UserService();
          const user: any = await User.getUserByField(
            "email",
            primaryEmail
          ).catch(setIsAllowRender);

          if (!user || !user.length) {
            await navigate(fallbackUrl.INVALID_LINK);
          } else {
            setUser(user[0]);
          }
        }
      }

      setIsAllowRender(true);

      if (
        !userAuth &&
        pathname !== fallbackUrl.SIGNUP &&
        pathname !== fallbackUrl.FORGOTPASS &&
        pathname !== fallbackUrl.PRIVACY_POLICY &&
        !pathname.includes("/public") &&
        !pathname.includes("/sign-in")
      ) {
        navigate(fallbackUrl.SIGNIN);
      } else if (
        userAuth &&
        userAuth.email &&
        (pathname === fallbackUrl.SIGNIN ||
          pathname === fallbackUrl.SIGNUP ||
          pathname === fallbackUrl.APP)
      ) {
        // document.body.classList.remove("body-signin");
        navigate(fallbackUrl.BE_MEASURED);
      }
    });
    // eslint-disable-next-line
  }, [pathname, setUser]);

  return { isAllowRender, user };
};

export const signOut = () => {
  localStorage.removeItem("clients");
  localStorage.removeItem("kit-authUser");
  localStorage.removeItem("account-chosen");
  localStorage.removeItem("kit-primaryUser");
  localStorage.removeItem("kit-authUserData");

  auth.signOut();
};
