import React, { FC } from "react";
import Avatar from "@material-ui/core/Avatar";
import { DateTime } from "luxon";
import { saveFeedback } from "../helpers/notification";
import { auth } from "firebase-tools/firebase";

import { useStyles } from "./styles";
import { useStyles as useStylesPlanned } from "../styles";
import { saveNote } from "../helpers/notes";
import { SendButton } from "components/styled/Common";

interface Props {
  user: any;
  note: string;
  setNote: (note: string) => void;
  post?: any;
  showAuthPrompt: any;
}

const NoteField: FC<Props> = ({
  user,
  note,
  setNote,
  post,
  showAuthPrompt,
}) => {
  // current logged in user
  let authUser;
  let firebaseUser: any = auth.currentUser;
  if (firebaseUser?.email !== user.email) {
    firebaseUser = {
      email: firebaseUser?.email,
      name: firebaseUser?.displayName,
    };
    authUser = firebaseUser;
  } else {
    authUser = user;
  }
  const authEmail = authUser.email;

  const classes = useStyles({});
  const classes2 = useStylesPlanned({});

  const submit = async (noteText) => {
    if (window.location.pathname.includes("/public")) {
      showAuthPrompt();
    } else {
      if (noteText.trim() === "") return;
      setNote("");
      const noteObject = {
        user: user,
        message: noteText,
        type: "external",
        created_at: new Date(),
      };
      await saveNote(post.id, noteObject);
      await saveFeedback(
        "comment",
        noteText,
        {
          id: post.id,
          clientName: user.firstName + " " + user.lastName,
          userEmail: authEmail,
          date: DateTime.fromSeconds(post.date.seconds),
          title: post.title,
        },
        user?.email
      );
    }
  };

  return (
    <div style={{ marginBottom: "-4px" }}>
      <div className={classes.noteField} style={{ alignItems: "flex-start" }}>
        <Avatar
          alt={user?.firstName + " " + user?.lastName}
          src={user?.avatarUrl}
          className={classes.notesAvatar}
          style={{ background: user?.avatarUrl ? "#fff" : "#000" }}
        >
          <p className={classes.avatarText}>
            {user?.firstName &&
              user?.lastName &&
              user.firstName[0] + user.lastName[0]}
          </p>
        </Avatar>
        <div
          className={classes.choose}
          style={{ border: "none", marginTop: "0px" }}
        >
          <textarea
            className={classes2.input}
            placeholder=""
            value={note}
            onKeyDown={async (e) => {
              const noteText = e.currentTarget.value;
              if (
                e.key === "Enter" &&
                !e.shiftKey &&
                noteText.trim() !== "" &&
                post
              ) {
                e.preventDefault();
                await submit(noteText);
              }
            }}
            onChange={async (e: any) => setNote(e.target.value)}
            style={{ width: "100%", resize: "vertical" }}
          />
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <SendButton
          onClick={() => submit(note)}
          disableRipple
          style={{ width: "unset", marginTop: 10 }}
        >
          Post
        </SendButton>
      </div>
    </div>
  );
};

export default NoteField;
