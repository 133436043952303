import React, { FC } from "react";

import { firestore } from "firebase-tools/firebase";
import useDocumentSubscriber from "hooks/useDocumentSubscriber";
import { SocialMediaPlatform } from "../SelectCampaignPage";
import GroupExtractor from "components/BeAmplified/Campaigns/Organic/View/GroupExtractor";

type CampaignType = {
  platform?: string;
  uid?: string;
  path: string;
};

const GroupExtractorPage: FC<CampaignType> = ({ platform, uid }) => {
  let campaignsRef: any;
  if (SocialMediaPlatform.Facebook.toUpperCase() === platform?.toUpperCase()) {
    campaignsRef = firestore.collection("facebook-group-scrapper").doc(uid);
  }

  const campaign = useDocumentSubscriber({ ref: campaignsRef }).document;

  const socialMediaPlatform = platform
    ? (platform as SocialMediaPlatform)
    : SocialMediaPlatform.Facebook;

  return (
    <div>
      {campaign?.status && (
        <GroupExtractor platform={socialMediaPlatform} campaign={campaign} />
      )}
    </div>
  );
};

export default GroupExtractorPage;
