import { firestore } from "firebase-tools/firebase";
import { navigate } from "@reach/router";
import { InstagramSearchAgentArgs } from "./launchSearchAgent";

export const editInstagramSearchAgent = async (
  id: string,
  data: Partial<InstagramSearchAgentArgs>
) => {
  await firestore
    .collection("instagram-autofollow-campaigns")
    .doc(id)
    .update({ ...data, status: "new" });
  navigate(`/campaigns/organic/instagram/${id}`);
};
